<template>
  <b-sidebar
    id="add-edit-sidebar"
    :visible="isEditSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("Edit") }} {{ $t("Customer") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(updatedata)"
          @reset.prevent="resetForm"
        >
          <!-- <validation-provider
            #default="validationContext"
            name="Code"
            rules="required"
          >
            <b-form-group
              label="Code"
              label-for="cusCode"
            >
              <b-form-input
                id="cusCode"
                v-model="form_Data.cusCode"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <validation-provider
            #default="validationContext"
            :name="$t('Name') +' (TH)'"
            rules="required"
          >
            <b-form-group :label="$t('Name') +' (TH)'" label-for="full-name">
              <b-form-input
                id="full-name"
                v-model="form_Data.cusName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="$t('Name') + ' (EN)'"
            rules="required"
          >
            <b-form-group :label="$t('Name') + ' (EN)'" label-for="name-eng">
              <b-form-input
                id="full-name-eng"
                v-model="form_Data.cusName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="$t('Tel')"
            rules="required"
          >
            <b-form-group :label="$t('Tel')" label-for="tel">
              <b-form-input
                id="tel"
                v-model="form_Data.cusTel"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            :name="$t('Email')"
            rules="required|email"
          >
            <b-form-group :label="$t('Email')" label-for="email">
              <b-form-input
                id="email"
                v-model="form_Data.cusEmail"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="$t('Address')"
            rules="required"
          >
            <b-form-group :label="$t('Address')" label-for="address">
              <b-form-textarea
                id="address"
                rows="3"
                v-model="form_Data.cusAddress"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" :name="$t('Note')">
            <b-form-group :label="$t('Note')" label-for="note">
              <b-form-textarea
                id="note"
                rows="3"
                v-model="form_Data.cusNote"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- <validation-provider #default="validationContext" name="cusCredit">
            <b-form-group label="Addcredit" label-for="cusCredit">
              <b-form-input
                id="cusCredit"
                v-model="form_Data.cusCredit"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <validation-provider
            #default="validationContext"
            name="Line Token Alert"
          >
            <b-form-group label="Line Token Alert" label-for="Line Token Alert">
              <b-form-input
                id="line-token-alert"
                v-model="form_Data.lineTokenAlert"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Line Token Summary"
          >
            <b-form-group label="Line Token Summary" label-for="Line Token Summary">
              <b-form-input
                id="line-token-summary"
                v-model="form_Data.lineTokenSummary"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("Update") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
            {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import customerService from "@/services/customerService.js";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditSidebarActive",
    event: "update:is-edit-sidebar-active",
  },
  props: {
    isEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    form_Data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },
  setup() {
    const { refFormObserver, getValidationState } = formValidation();

    return {
      refFormObserver,
      getValidationState,
    };
  },
  computed: {},
  methods: {
    updatedata() {
      try {
        customerService
          .updatedataCustomer(this.form_Data.cusId, this.form_Data)

          .then((response) => {
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "อัพเดทข้อมูลไม่สำเร็จ",
                text: "Error!",
                timer: 3000,
              });
            } else {
              this.$swal({
                type: "success",
                title: "อัพเดทข้อมูลสำเร็จ",
                text: "Save data successfully!",
                timer: 3000,
              });
              this.$emit("refetch-data");
              this.$emit("update:is-edit-sidebar-active", false);
            }
          })
          .catch(() => {});
      } catch (e) {
        //console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-edit-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
