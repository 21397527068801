<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("Add New") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(insertdata)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="Username"
            rules="required"
          >
            <!-- <b-form-input
                id="username"
                :append="'@' + cusCode"
                v-model="formData.username"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              /> -->
            <label for="basic-password1">{{ $t("Username") }}</label>
            <b-input-group
              id="username"
              :append="'@' + cusCode"
              class="input-group-merge"
            >
              <b-form-input
                :label="$t('Username')"
                label-for="username"
                v-model="formData.username"
                :state="getValidationState(validationContext)"
              />
            </b-input-group>

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Password"
            rules="required"
          >
            <b-form-group :label="$t('Password')" label-for="password">
              <b-form-input
                id="password"
                v-model="formData.password"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Role"
            rules="required"
          >
            <b-form-group
              :label="$t('User Role')"
              label-for="user-role"
              v-if="roleAccess != 'manager' || roleAccess != 'user'"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="formData.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
            :label="$t('Branch')"
            label-for="Branch"
            v-if="formData.role == 'manager' || formData.role == 'user'"
          >
            <v-select
              v-model="formData.b_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="branchSel"
              :reduce="(option) => option.id"
              label="b_name"
              input-id="id"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("Add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
            {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import usersService from "@/services/usersService.js";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BInputGroup,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewSidebarActive",
    event: "update:is-add-new-sidebar-active",
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    cusCode: {},
    roleAccess: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    branchSel: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    };
  },
  setup() {
    const blankformData = {
      username: "",
      password: "",
      userStatus: "",
      role: "manager",
      cusCode: "",
      b_id: "",
    };

    const formData = ref(JSON.parse(JSON.stringify(blankformData)));
    const resetformData = () => {
      formData.value = JSON.parse(JSON.stringify(blankformData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetformData);

    return {
      formData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    insertdata() {
      this.formData.username = this.formData.username + "@" + this.cusCode;
      try {
        usersService
          .insertWithCus(this.cusCode, this.formData)
          .then((response) => {
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "บันทึกข้อมูลไม่สำเร็จ",
                text: "Error! " + response.data.message,
                timer: 3000,
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "บันทึกข้อมูลสำเร็จ",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit("update:is-add-new-sidebar-active", false);
              this.$emit("refetch-data");
            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
