<template>
  <div>
    <CreditsAdd
      v-if="role"
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :cusCode="cusCode" 
      @refetch-data="refetchData"
    />

    <CreditsDelete
      v-if="role"
      :is-delete-new-sidebar-active.sync="isDeleteNewSidebarActive"
      :cusCode="cusCode" 
      @refetch-data="refetchData"
    />
    <b-card>
     <b-input-group v-if="role" class="mb-1">
        <b-button style="margin-left: 5px;border-radius: 0.357rem;"
            variant="primary"
            size="sm"
            @click="addOpen"
          >
            <span class="text-nowrap">+ Credit</span>
          </b-button>
          <b-button style="margin-left: 5px;border-radius: 0.357rem;"
            variant="danger"
            size="sm"
            @click="DeleteOpen"
          >
            <span class="text-nowrap">- Credit</span>
          </b-button>
      </b-input-group>
      
      <b-row>
    
        <b-col cols="12">
          <b-table
            striped
            responsive
            :per-page="perPage"
            :current-page="currentPagetable"
            :items="bigdata"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >

            <template #cell(cusId)="row">
                <span v-if="row.item.credit > 0"><b-badge variant="success">เพิ่ม CREDIT</b-badge></span>
                <span v-else><b-badge variant="danger">หัก CREDIT</b-badge></span>
                <span style="font-size: 12px;"> {{row.item.created_at}}</span><br>
               <span style="font-size: 12px;">รายละเอียด</span><br>
               <span v-if="row.item.refId == null">By Admin</span>
               <span v-else>By Member</span>
            </template>

            <template #cell(credit)="row">
              <div style="font-size: 18px;">
                <div v-if="row.item.credit > 0"><span class="text-success">+{{row.item.credit}}</span></div>
                <div v-else><span class="text-danger">{{row.item.credit}}</span></div>
              </div>
            </template>

            <template #cell(total)="row">
              <div style="font-size: 18px;">
                {{row.item.total}}
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col
          md="2"
          sm="4"
          class="my-1"
        >
          <b-form-group
            class="mb-0"
          >
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Per page") }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @change="perPageChanged()"
              class="w-50"
            />

            <div class="show-entries" v-if="totalRows == 0">
              <label class="text-muted">{{ $t("Showing") }} 0 {{ $t("To") }}  0 {{ $t("Of") }}  0 {{ $t("Entries") }} </label>
            </div>
            <div class="show-entries" v-else>
              <div v-if="currentPage * perPage > totalRows">
                <label class="text-muted">{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                  {{ currentPage * perPage }} of {{ totalRows }} {{ $t("Entries") }} </label>
              </div>
              <div v-else>
                <label class="text-muted">{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                  {{ currentPage * perPage }} of {{ totalRows }} {{ $t("Entries") }} </label>
              </div>
            </div>
          </b-form-group>
        </b-col>

        <b-col
          md="10"
          sm="8"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @click.native="handlePageChange()"
            align="right"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard, BCardHeader, BCardText, BLink, BTable, BFormCheckbox, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BImg, BDropdown, BDropdownItem
} from 'bootstrap-vue'
import jwt_decode from "jwt-decode";
import customerService from "@/services/customerService.js";
import { ref } from '@vue/composition-api'
import CreditsAdd from './CreditsAdd.vue'
import CreditsDelete from './CreditsDelete.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BLink,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BImg,
    BDropdown,
    BDropdownItem,
   CreditsAdd,
   CreditsDelete,
  },
  directives: {
    Ripple,
  },
  setup() {
   
    const isAddNewSidebarActive = ref(false)
    const isDeleteNewSidebarActive = ref(false) 

    return {
      // Sidebar
      isAddNewSidebarActive,
      isDeleteNewSidebarActive,
    }
  },
  data() {
    return {
      cusCode: "",
      perPage: 20,
      pageOptions: [20, 30, 50, 100],
      totalRows: 1,
      currentPage: 1,
      currentPagetable: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      role: true,
      fields: [
        {
          key: "cusId",
          label: "รายการ",
          class: "text-left col-list",
        },
        {
          key: "credit",
          label: "จำนวน",
          class: "text-center",
        }, 
        {
          key: "total",
          label: "คงเหลือ",
          class: "text-center",
        },
      ],
      filterAll: "",
      filterSearch: "",
      filterDate: "",
      filterperPage: "",
      filtersortBy: "",
      filtersortDesc: "",
      bigdata: [],
      productOptions: [],
      formData: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    perPageChanged() {
      this.filterperPage = "&per_page="+this.perPage
      this.getbigdata()
    },
    handlePageChange() {
      this.getbigdata()
    },
    getbigdata() {
      var page = "page=" + this.currentPage
      this.filterAll = "?" + page + this.filterSearch + this.filterperPage + this.filtersortBy + this.filtersortDesc

      try {
        customerService
          .getLogCredit(this.cusCode, this.filterAll)
          .then((response) => {
            this.bigdata = response.data.data.data;
            this.totalRows = response.data.data.total;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
      addOpen() {
     this.isAddNewSidebarActive = true; 
    },
       DeleteOpen() {
     this.isDeleteNewSidebarActive = true; 
    },
    
    showtoken() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      
      if (decoded.role == "superadmin" || decoded.role == "admin") {
        this.cusCode = this.$route.params.id
        this.role = true;
      } else {
        this.cusCode = decoded.cusCode
        this.role = false;
      }
    },
    refetchData(){
      this.getbigdata()
    }
  },
  async created() { 
    await this.showtoken();
    await this.getbigdata();
  },

}
</script>

<style>
.col-list {
  min-width: 110px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
</style>
