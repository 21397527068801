<template>
  <div>
    <MemberAddNew
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :cusCode="cusCode"
      :branchSel="branchSel"
      :DepOptions="DepOptions"
      :user_role = "user_role"
      @refetch-data="refetchData"
    />
    <MemberEdit
      :is-edit-sidebar-active.sync="isEditSidebarActive"
      :formData="form_Data"
      :branchSel="branchSel"
      :DepOptions="DepOptions"
      :user_role = "user_role"
      @refetch-data="refetchData"
    />
    <MemberUpdateDep
      :is-add-update-dep-sidebar-active.sync="isAddUpdateDepSidebarActive"
      :DepOptions="DepOptions"
      :formDataDep="formDataDep"
      @refetch-data="refetchData"
    />

    <!-- modal -->
    <PermisModel :cusCode="cusCode" :permissionsForm="permissionsData" @refetchPmsData="refetchPmsData"/>

    <!--MemberPermision Modal -->
    <MemberPermisModel :memberUid="memberUid" :cusPermisData="permissionsData" :memPermisData="memPermissData"    :firstname="firstname" :lastname="lastname" @refetchPmData="refetchPmData"/>
    <b-card>



      <b-row>
        <b-col md="5" class="my-1 d-none d-md-block">
          
          <div class="demo-inline-spacing justify-content-start">
            <span>{{ $t("Filter") }} :</span>
            <b-form-radio
              v-model="filterRole"
              name="fill-role"
              value="member"
              @change="selectedRole()"
            >
              {{ $t("Employeers") }}
            </b-form-radio>
            <b-form-radio
              v-model="filterRole"
              name="fill-role"
              value="unsigned"
              @change="selectedRole()"
            >
              {{ $t("Visitors") }}
            </b-form-radio>
          </div>
        </b-col>

        <b-col md="7" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Filter") }}</label>
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('Search')"
                @change="searchEnter()"
              />
              <b-input-group-append>
                <b-button :disabled="!filter"
                  @click="searchClear()">
                  {{ $t("Clear") }}
                </b-button>

                <b-button style="margin-left: 5px;border-radius: 0.357rem;"
                  variant="primary"
                  @click="addOpen"
                >
                  <span class="text-nowrap">+ {{ $t("Add") }} {{ $t("Employeer") }}</span>
                </b-button>

                <b-button id = "btn-import-mem" style="margin-left: 5px;border-radius: 0.357rem;"
                  variant="outline-success"
                  v-b-modal.modal-import-mem
                  @click="openModalImport">
                  <feather-icon icon="UploadIcon" /> {{ $t("Import") }}
                </b-button>
                <b-tooltip target="btn-import-mem">{{ $t("Import") }} {{ $t("Employeers") }}</b-tooltip>
              </b-input-group-append>

            </b-input-group>
          </b-form-group>
        </b-col>

        <MemberImportModel 
          :cusCode="cusCode" 
          :branchSel="branchSel" 
          :DepOptions="DepOptions" 
          @refetch-data="refetchData"/>

        <b-col cols="12">
          <b-table
            striped
            responsive
            :per-page="perPage"
            :current-page="currentPagetable"
            :items="bigdata"
            :fields="fields"
            @sort-changed="sortingChanged"
          >
            <template #cell(show_details)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
                <span class="vs-label">{{
                  row.detailsShowing ? "Hide" : "Show"
                }}</span>
              </b-form-checkbox>
            </template>

            <template #cell(firstname)="row">
              <b-link
                :to="{ name: 'chart-report', params: { id: row.item.uid } }"
                class="font-weight-bold d-block text-nowrap"
              >
                <div v-if="row.item.firstname == ''"></div>
                <div v-else>
                  {{ row.item.title }} {{ row.item.firstname }} &nbsp;&nbsp;
                  {{ row.item.lastname }}
                </div>
                
              </b-link>
              
            </template>

            <template #cell(dep_name)="row">
                <div>
                  {{ row.item.dep_name }}
                  <b-button style="margin-left: 5px;border-radius: 0.357rem;"
                    variant="primary"
                    size="sm"
                    @click="updateDepartment(row.item)"
                  >
                    <span v-if="row.item.dep_name == ''" class="text-nowrap">{{ $t("Add") }}</span>
                    <span v-else class="text-nowrap">{{ $t("Edit") }}</span>
                  </b-button>
                </div>
            </template>

            <template #cell(position)="row">
                <span v-if="row.item.position">
                  {{ row.item.position }}
                </span>
                <span v-else>
                  -
                </span>
            </template>
            <template #cell(chkin_mem_kiosk_id)="row">
              <div>
                <b-badge variant="danger" v-if="row.item.del_flag == 1">
                  ปิดการทดสอบ
                </b-badge>
                <b-button
                v-b-modal.modal-lg-in-mem
                  v-if="row.item.chkin_mem_kiosk_id !== null && row.item.chkin_mem_kiosk_id !== ''"
                  style="margin-left: 5px; border-radius: 0.357rem;"
                  class="h-100 w-100 btn-md-cutom-in"
                
                  size="sm"
                  @click="getMemberKios(row.item.uid,1,row.item.firstname ,row.item.lastname )"
                >
                  {{$t('Check In')}}
                </b-button>
                <b-button
                v-b-modal.modal-lg-out-mem
                  v-if="row.item.chkin_mem_kiosk_id !== null && row.item.chkin_mem_kiosk_id !== ''"
                 
                  style="margin-left: 5px; border-radius: 0.357rem;"
                    class="h-100 w-100 btn-md-cutom-out"
                 
                  size="sm"
                  @click="getMemberKios(row.item.uid,2,row.item.firstname ,row.item.lastname)"
                >
                  {{$t('Check Out')}}
                </b-button>
                <!-- <span v-else class="text-nowrap">-</span> -->
              </div>
            </template>
            <template #cell(actions)="row">

              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="$router.push({ name: 'chart-report', params: { id: row.item.uid }})">
                  <feather-icon style="cursor: pointer;"
                    :id="`member-row-${row.item.uid}-preview-icon`"
                    icon="EyeIcon"
                    
                  />
                  <span class="align-middle ml-50">{{ $t("View statistics") }}</span>
                </b-dropdown-item>

                <b-dropdown-item @click="editOpen(row.item.id)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t("Edit") }}</span>
                </b-dropdown-item>
                
                <!-- <b-dropdown-item @click="updateStatus(row.item.uid, 0)" v-if="row.item.del_flag == 1">
                  <feather-icon icon="EyeOffIcon" class="text-warning"/>
                  <span class="align-middle ml-50">เปิดใช้งาน</span>
                </b-dropdown-item>
                <b-dropdown-item @click="updateStatus(row.item.uid, 1)" v-else>
                  <feather-icon icon="EyeOffIcon" class="text-warning"/>
                  <span class="align-middle ml-50">ปิดใช้งาน</span>
                </b-dropdown-item> -->
                <b-dropdown-item @click="DeletePermiss(row.item.uid)" v-if="row.item.chkin_mem_kiosk_id !== null && row.item.chkin_mem_kiosk_id !== ''" >
                  <feather-icon icon="TrashIcon" class="text-danger"/>
                  <span class="align-middle ml-50">{{ $t("Remove") }}{{ $t("Access Kiosk") }}</span>
                </b-dropdown-item>
                <b-dropdown-item 
                modal-lg
                v-b-modal.modal-lg
                  @click="editPermissOpen(row.item.uid,row.item.firstname ,row.item.lastname)" v-else
                >
                  <feather-icon icon="LockIcon" />
                  <span class="align-middle ml-50">{{ $t("Access Kiosk") }}</span>
                </b-dropdown-item>
               
                <b-dropdown-item @click="updateStatus(row.item.uid, 0)" v-if="row.item.del_flag == 1">
                  <feather-icon icon="EyeOffIcon" class="text-warning"/>
                  <span class="align-middle ml-50">{{ $t("Enable") }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="updateStatus(row.item.uid, 1)" v-else>
                  <feather-icon icon="EyeOffIcon" class="text-warning"/>
                  <span class="align-middle ml-50">{{ $t("Disable") }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  @click="removeCompany(row.item.c_id)"
                  
                >
                  <feather-icon icon="XIcon" class="text-danger"/>
                  <span class="align-middle ml-50">{{ $t("Remove Company") }}</span>
                </b-dropdown-item>
              </b-dropdown>
               
            </template>

          </b-table>
        </b-col>

        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Per page") }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @change="perPageChanged()"
              class="w-50"
            />

            <div class="show-entries" v-if="totalRows == 0">
              <label class="text-muted">{{ $t("Showing") }} 0 {{ $t("To") }}  0 {{ $t("Of") }}  0 {{ $t("Entries") }} </label>
            </div>
            <div class="show-entries" v-else>
              <div v-if="currentPage * perPage > totalRows">
                <label class="text-muted"
                  >{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                  {{ currentPage * perPage }} {{ $t("Of") }} {{ totalRows }} {{ $t("Entries") }}</label
                >
              </div>
              <div v-else>
                <label class="text-muted"
                  >{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                  {{ currentPage * perPage }} {{ $t("Of") }} {{ totalRows }} {{ $t("Entries") }}</label
                >
              </div>
            </div>
          </b-form-group>
        </b-col>

        <b-col md="10" sm="8">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @click.native="handlePageChange()"
            align="right"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-modal
    id="modal-lg-in-mem"
    ref="my-modal-in-mem"
    size="lg"
    :ok-title="$t('Save')"
    :cancel-title="$t('Cancel')"
    cancel-variant="outline-secondary"
    @hidden="resetModalIn"
    @ok="handleUpdateFieldIn"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <!-- <b-card-text class="custom-card-text"> -->
          <b-row class="mb-1">
            <b-col md="2">
             
            
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="lg"
                  style="margin-left: 5px; border-radius: 0.357rem;"
                class="h-100 w-100 btn-md-cutom-in"
                >
                  {{$t('Check In')}}
                </b-button> 
            
             
            </b-col>
            <b-col md="10" class="pl-0">
             
                <h6 class="button-style-trans mb-0">
                  {{ $t("Emp screening test") }}: {{firstname}} {{lastname}}
                </h6>
              
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <b-card-text class="custom-card-text">
                {{ $t("Choose least one") }}
                <b-form-checkbox-group
                  v-model="selected"
                  :options="options"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                  class="demo-inline-spacing custom-control-chkin"
                />
              </b-card-text>
            </b-col>
            <b-col md="4">
              <b-form-checkbox-group
                v-model="selected2"
                :options="options2"
                value-field="item2"
                text-field="name"
                disabled-field="notEnabled"
                class="select-item custom-control-chkin"
              />
            </b-col>
          </b-row>
          
          <table class="responsive-table-input-matrix chk-permis-dep">
            <thead>
              <tr>
                <th></th>
                <th>{{ $t("Once a day") }}</th>
                <th>{{ $t("Once a week") }}</th>
                <th>{{ $t("Once a month") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-left">{{ $t("Hand response test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-chkin" v-model="chk_hand" value="d" name="chk_hand" @change="deselectEverything('chk_hand')"/>
                </td>
                <td>
                  <b-form-radio v-model="chk_hand" value="w" name="chk_hand" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="dayOptions"
                    v-model="chk_hand_w_d"
                    name="chk_hand_w_d"
                    v-show="showSelect"
                    @change="deselectEverything('chk_hand_w_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <b-form-radio class="custom-control-chkin" v-model="chk_hand" value="m" name="chk_hand" v-show="false"  />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="monthOptions"
                    v-model="chk_hand_m_d"

                    name="chk_hand_m_d"
                    v-show="showSelect"
                    @change="deselectEverything('chk_hand_m_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <feather-icon icon="XIcon" class="text-danger" v-if="chk_hand === 'd' || chk_hand === 'w' || chk_hand === 'm'"  type="button" @click="remove('chk_hand')"></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Foot response test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-chkin" v-model="chk_foot" value="d" name="chk_foot" @change="deselectEverything('chk_foot')"/>
                </td>
                <td>
                  <b-form-radio v-model="chk_foot" value="w" name="chk_foot" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="dayOptions"
                    v-model="chk_foot_w_d"

                    name="chk_foot_w_d"
                    v-show="showSelect"
                    @change="deselectEverything('chk_foot_w_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <b-form-radio class="custom-control-chkin" v-model="chk_foot" value="m" name="chk_foot" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="monthOptions"
                    v-model="chk_foot_m_d"

                    name="chk_foot_m_d"
                    v-show="showSelect"
                    @change="deselectEverything('chk_foot_m_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <feather-icon icon="XIcon" class="text-danger" v-if="chk_foot === 'd' || chk_foot === 'w' || chk_foot === 'm'"  type="button" @click="remove('chk_foot')"></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Blind test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-chkin" v-model="chk_blind" value="d" name="chk_blind" @change="deselectEverything('chk_blind')"/>
                </td>
                <td>
                  <b-form-radio v-model="chk_blind" value="w" name="chk_blind" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="dayOptions"
                    v-model="chk_blind_w_d"

                    name="chk_blind_w_d"
                    v-show="showSelect"
                    @change="deselectEverything('chk_blind_w_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <b-form-radio v-model="chk_blind" value="m" name="chk_blind" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="monthOptions"
                    v-model="chk_blind_m_d"

                    name="chk_blind_m_d"
                    v-show="showSelect"
                    @change="deselectEverything('chk_blind_m_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <feather-icon icon="XIcon" class="text-danger" v-if="chk_blind === 'd' || chk_blind === 'w' || chk_blind === 'm'"  type="button" @click="remove('chk_blind')"></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Quizs") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-chkin" v-model="chk_quiz" value="d" name="chk_quiz" @change="deselectEverything('chk_quiz')"/>
                </td>
                <td>
                  <b-form-radio v-model="chk_quiz" value="w" name="chk_quiz" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="dayOptions"
                    v-model="chk_quiz_w_d"

                    name="chk_quiz_w_d"
                    v-show="showSelect"
                    @change="deselectEverything('chk_quiz_w_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <b-form-radio v-model="chk_quiz" value="m" name="chk_quiz" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="monthOptions"
                    v-model="chk_quiz_m_d"

                    name="chk_quiz_m_d"
                    v-show="showSelect"
                    @change="deselectEverything('chk_quiz_m_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <feather-icon icon="XIcon" class="text-danger" v-if="chk_quiz === 'd' || chk_quiz === 'w' || chk_quiz === 'm'"  type="button" @click="remove('chk_quiz')"></feather-icon>
                </td>
              </tr>
            </tbody>
          </table>

      
          
      </form>
    </b-modal>

    <b-modal
    id="modal-lg-out-mem"
  ref="my-modal-out-mem"
  size="lg"
  :ok-title="$t('Save')"
  :cancel-title="$t('Cancel')"
  cancel-variant="outline-secondary"
  @hidden="resetModalOut"
  @ok="handleUpdateFieldOut" 
    >
      <!-- ส่วนฟอร์มข้อมูลใน Modal ของ OUT -->
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <!-- <b-card-text class="custom-card-text"> -->
          <b-row class="mb-1">
            <b-col md="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                size="lg"  
                style="margin-left: 5px; border-radius: 0.357rem;"
                class="h-100 w-100 btn-md-cutom-out"
              >
              {{ $t('Check Out') }}
              </b-button> 
            </b-col>
            <b-col md="10" class="pl-0">
              <h6 class="button-style-trans mb-0">
                การตรวจวัดและทดสอบสำหรับออกงานของ: {{firstname}} {{lastname}}
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <b-card-text class="custom-card-text">
                {{ $t("Choose least one") }}
                <b-form-checkbox-group
                  v-model="selected"
                  :options="options"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                  class="demo-inline-spacing  custom-control-warning custom-control-chkout"
                />
              </b-card-text>
            </b-col>
            <b-col md="4">
              <b-form-checkbox-group
                v-model="selected2"
                :options="options2"
                value-field="item2"
                text-field="name"
                disabled-field="notEnabled"
                class="select-item custom-control-warning custom-control-chkout"
              />
            </b-col>
          </b-row>
          
       
          <table class="responsive-table-input-matrix chk-permis-dep">
            <thead>
              <tr>
                <th></th>
                <th>{{ $t("Once a day") }}</th>
                <th>{{ $t("Once a week") }}</th>
                <th>{{ $t("Once a month") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-left">{{ $t("Hand response test") }}</td>
                <td>
                  <b-form-checkbox  class="custom-control-warning custom-control-chkout" v-model="chk_hand" value="d" name="chk_hand" @change="deselectEverything('chk_hand')"/>
                </td>
                <td>
                  <b-form-radio v-model="chk_hand" value="w" name="chk_hand" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="dayOptions"
                    v-model="chk_hand_w_d"
                    name="chk_hand_w_d"
                    v-show="showSelect"
                    @change="deselectEverything('chk_hand_w_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <b-form-radio v-model="chk_hand" value="m" name="chk_hand" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="monthOptions"
                    v-model="chk_hand_m_d"

                    name="chk_hand_m_d"
                    v-show="showSelect"
                    @change="deselectEverything('chk_hand_m_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <feather-icon icon="XIcon" class="text-danger" v-if="chk_hand === 'd' || chk_hand === 'w' || chk_hand === 'm'"  type="button" @click="remove('chk_hand')"></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Foot response test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-warning custom-control-chkout" v-model="chk_foot" value="d" name="chk_foot" @change="deselectEverything('chk_foot')"/>
                </td>
                <td>
                  <b-form-radio v-model="chk_foot" value="w" name="chk_foot" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="dayOptions"
                    v-model="chk_foot_w_d"

                    name="chk_foot_w_d"
                    v-show="showSelect"
                    @change="deselectEverything('chk_foot_w_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <b-form-radio v-model="chk_foot" value="m" name="chk_foot" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="monthOptions"
                    v-model="chk_foot_m_d"

                    name="chk_foot_m_d"
                    v-show="showSelect"
                    @change="deselectEverything('chk_foot_m_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <feather-icon icon="XIcon" class="text-danger" v-if="chk_foot === 'd' || chk_foot === 'w' || chk_foot === 'm'"  type="button" @click="remove('chk_foot')"></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Blind test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-warning custom-control-chkout" v-model="chk_blind" value="d" name="chk_blind" @change="deselectEverything('chk_blind')"/>
                </td>
                <td>
                  <b-form-radio v-model="chk_blind" value="w" name="chk_blind" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="dayOptions"
                    v-model="chk_blind_w_d"
                    name="chk_blind_w_d"
                    v-show="showSelect"
                    @change="deselectEverything('chk_blind_w_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <b-form-radio v-model="chk_blind" value="m" name="chk_blind" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="monthOptions"
                    v-model="chk_blind_m_d"
                    name="chk_blind_m_d"
                    v-show="showSelect"
                    @change="deselectEverything('chk_blind_m_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <feather-icon icon="XIcon" class="text-danger" v-if="chk_blind === 'd' || chk_blind === 'w' || chk_blind === 'm'"  type="button" @click="remove('chk_blind')"></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Quizs") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-warning custom-control-chkout" v-model="chk_quiz" value="d" name="chk_quiz" @change="deselectEverything('chk_quiz')"/>
                </td>
                <td>
                  <b-form-radio v-model="chk_quiz" value="w" name="chk_quiz" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="dayOptions"
                    v-model="chk_quiz_w_d"

                    name="chk_quiz_w_d"
                    v-show="showSelect"
                    @change="deselectEverything('chk_quiz_w_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <b-form-radio v-model="chk_quiz" value="m" name="chk_quiz" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="monthOptions"
                    v-model="chk_quiz_m_d"

                    name="chk_quiz_m_d"
                    v-show="showSelect"
                    @change="deselectEverything('chk_quiz_m_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <feather-icon icon="XIcon" class="text-danger" v-if="chk_quiz === 'd' || chk_quiz === 'w' || chk_quiz === 'm'"  type="button" @click="remove('chk_quiz')"></feather-icon>
                </td>
              </tr>
            </tbody>
          </table>
          
      </form>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BLink,
  BTable,
  BFormRadio,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BImg,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,
  BTooltip,
} from "bootstrap-vue";
import jwt_decode from "jwt-decode";
import memberService from "@/services/memberService.js";
import memberKiosService from "@/services/memberKiosService.js";
import departmentService from "@/services/departmentService.js";
import companyService from "@/services/companyService.js";
import permissionsService from "@/services/permissionsService.js";
import branchService from "@/services/branchService.js";
import MemberAddNew from "./MemberAddNew.vue";
import MemberEdit from "./MemberEdit.vue";
import MemberUpdateDep from "./MemberUpdateDep.vue";
import MemberPermisModel from "./MemberPermis.vue";
import PermisModel from "./CustomerPermis.vue";
import MemberImportModel from "./MemberImport.vue";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BLink,
    BTable,
    BFormRadio,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BImg,
    BDropdown,
    BDropdownItem,
    BFormCheckboxGroup,
    BTooltip,
   
    MemberAddNew,
    MemberEdit,
    MemberUpdateDep,
    MemberPermisModel,
    PermisModel,
    MemberImportModel
  },
  directives: {
    Ripple,
  },
  setup() {
    const isAddNewSidebarActive = ref(false);
    const isEditSidebarActive = ref(false);
    const isAddUpdateDepSidebarActive = ref(false);

    return {
      // Sidebar
      isAddNewSidebarActive,
      isEditSidebarActive,
      isAddUpdateDepSidebarActive,
    };
  },
  data() {
    return {
      memberKiosId: null,
      firstname: "",
      lastname: "",
      clickedDepartmentName: '',
      selectedItems: [],
     
     // เพิ่มข้อมูลอื่น ๆ ตามความต้องการ
  
   options: [
     { item: "chk_alcohol", name: this.$t("Alcohol Breat") },
     { item: "chk_bp", name: this.$t("Measure blood pressure") },
   ],
   selected: [],
   // options: [
   //   { item: "เป่าวัดแอลกอฮอล์", name: "เป่าวัดแอลกอฮอล์" },
   //   { item: "วัดความดันโลหิต", name: "วัดความดันโลหิต" },
   // ],
   selected2: [],
   selectedOptionMonth: null, // ตั้งค่าเริ่มต้นของ selectedOption เป็น '1'
      selectedOptionWeek: null,
      showRadio: false, // แสดง <b-form-radio> เริ่มต้น
      showSelect: true, // ซ่อน <b-form-select> เริ่มต้น
      dayOptions: [
        { value: "0", text: '-- '+this.$t('Select day week')+' --' },
        { value: "1", text: this.$t('Monday') },
        { value: "2", text: this.$t('Tuesday') },
        { value: "3", text: this.$t('Wednesday') },
        { value: "4", text: this.$t('Thursday') },
        { value: "5", text: this.$t('Friday') },
        { value: "6", text: this.$t('Saturday') },
        { value: "7", text: this.$t('Sunday') },
      ],
      monthOptions: [
        { value: "0", text: '-- '+this.$t('Select day month')+' --' },
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4", text: "4" },
        { value: "5", text: "5" },
        { value: "6", text: "6" },
        { value: "7", text: "7" },
        { value: "8", text: "8" },
        { value: "9", text: "9" },
        { value: "10", text: "10" },
        { value: "11", text: "11" },
        { value: "12", text: "12" },
        { value: "13", text: "13" },
        { value: "14", text: "14" },
        { value: "15", text: "15" },
        { value: "16", text: "16" },
        { value: "17", text: "17" },
        { value: "18", text: "18" },
        { value: "19", text: "19" },
        { value: "20", text: "20" },
        { value: "21", text: "21" },
        { value: "22", text: "22" },
        { value: "23", text: "23" },
        { value: "24", text: "24" },
        { value: "25", text: "25" },
        { value: "26", text: "26" },
        { value: "27", text: "27" },
        { value: "28", text: "28" },
        { value: "29", text: "29" },
        { value: "30", text: "30" },
        { value: "31", text: "31" },
      ],
   defaultValues: {
      chk_hand: '',
      chk_foot: '',
      chk_blind: '',
      chk_quiz: ''
    },
      chk_hand:0,
      chk_quiz:0,
      chk_foot:0,
      chk_blind:0,
      chk_hand_m_d: 0,
      chk_hand_w_d: 0,
      chk_foot_w_d: 0,
      chk_foot_m_d: 0,
      chk_blind_w_d: 0,
      chk_blind_m_d: 0,
      chk_quiz_w_d: 0,
      chk_quiz_m_d: 0,
      form_chk: {
        // chk_hand: '',
        chk_hand_d:0,
        chk_hand_w: 0,
        chk_hand_m: 0,
        chk_foot_d: 0,
        chk_foot_w: 0,
        chk_foot_m: 0,
        chk_blind_d: 0,
        chk_blind_w: 0,
        chk_blind_m: 0,
        chk_quiz_d: 0,
        chk_quiz_w: 0,
        chk_quiz_m: 0,
        chk_hand_m_d:0,
        chk_hand_w_d:0,
        chk_foot_w_d:0,
        chk_foot_m_d:0,
        chk_blind_w_d:0,
        chk_blind_m_d:0,
        chk_quiz_w_d:0,
        chk_quiz_m_d:0,
      },
   options2: [{ item2: "chk_temp", name: this.$t("Temp measure1") }],
   formData: [],
      cusCode: "",
      memberUid: "",
      perPage: 20,
      pageOptions: [20, 30, 50, 100],
      totalRows: 1,
      currentPage: 1,
      currentPagetable: 1,
      sortBy: "created_at",
      sortDesc: true,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      role: true,
      user_role: "",
      fields: [
        // {
        //   key: "show_details",
        //   label: "Details",
        //   class: "d-sm-none",
        // },
        {
          key: "firstname",
          label: this.$t('Full-name'),
          sortable: true,
          class: "text-left col-fullname",
        },
        // {
        //   key: "uid",
        //   label: "ID",
        //   class: "text-left",
        // },
        {
          key: "tel",
          label: this.$t('Tel'),
          sortable: true,
          class: "text-left",
        },
        {
          key: "emp_id",
          label: this.$t('ID CODE'),
          sortable: true,
          class: "text-left",
        },
         {
          key: "dep_name",
          label: this.$t('Department'),
          sortable: true,
          class: "text-left",
        },
        {
          key: "position",
          label: this.$t('Position'),
          sortable: true,
          class: "text-left",
        },
        {
          key: "chkin_mem_kiosk_id",
          label: this.$t('Access Kiosk'),
          sortable: true,
          class: "text-center",
        },
        {
          key: "created_at",
          label: this.$t('Create Date'),
          sortable: true,
          class: "text-left",
        },
        {
          key: "actions",
          label: this.$t('Actions'),
          class: "text-center",
        }, 
      ],
      filterAll: "",
      filterRole: "member",
      filterSearch: "",
      filterperPage: "",
      filtersortBy: "",
      filtersortDesc: "",
      bigdata: [],
      form_Data: [],
      formDataDep: {},
      DepOptions: [],
      fieldsPermis: [
        {
          key: "name",
          label: "การทดสอบ",
        },
        {
          key: "in",
          label: "เข้างาน",
          class: "text-center",
        },
        {
          key: "out",
          label: "จบงาน",
          class: "text-center",
        },
      ],
      permissionsData: [
        {
          name: 'ALCOHOL',
          name_key: 'alcohol',
          in: true,
          out: true,
        },
        {
          name: 'SYS/DIA',
          name_key: 'sys_dia',
          in: true,
          out: true,
        },
        {
          name: 'TEMP',
          name_key: 'temp',
          in: true,
          out: true,
        },
        {
          name: 'ตอบสนองมือ',
          name_key: 'hand_sp',
          in: true,
          out: true,
        },
        {
          name: 'ตอบสนองเท้า',
          name_key: 'foot_sp',
          in: true,
          out: true,
        },
        {
          name: 'ตาบอดสี',
          name_key: 'blind',
          in: true,
          out: true,
        },
      ],
      branchSel: [],
      memPermissData: [
      ],
      disChk: true,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {},
  methods: {
    remove(fieldName) {
      if (fieldName === 'chk_hand') {
        this.chk_hand = 0;
        this.chk_hand_m_d = 0;
        this.chk_hand_w_d = 0;
      }else if (fieldName === 'chk_foot') {
        this.chk_foot = 0;
        this.chk_foot_w_d = 0;
        this.chk_foot_m_d = 0;
      }else if (fieldName === 'chk_blind') {
        this.chk_blind = 0;
        this.chk_blind_w_d = 0;
        this.chk_blind_m_d = 0;
      }else if (fieldName === 'chk_quiz') {
        this.chk_quiz = 0;
        this.chk_quiz_w_d = 0;
        this.chk_quiz_m_d = 0;
      }
    },
    deselectEverything(fieldName) {
      if (fieldName === 'chk_hand') {
        this.chk_hand = 'd';
        this.chk_hand_w_d = 0;
        this.chk_hand_m_d = 0;
      } else if (fieldName === 'chk_hand_w_d') {
        this.chk_hand_m_d = 0;
        this.chk_hand = 'w';
      } else if (fieldName === 'chk_hand_m_d') {
        this.chk_hand_w_d = 0;
        this.chk_hand = 'm';

      } else if (fieldName === 'chk_foot') {
        this.chk_foot = 'd';
        this.chk_foot_w_d = 0;
        this.chk_foot_m_d = 0;
      } else if (fieldName === 'chk_foot_w_d') {
        this.chk_foot_m_d = 0;
        this.chk_foot = 'w';
      } else if (fieldName === 'chk_foot_m_d') {
        this.chk_foot_w_d = 0;
        this.chk_foot = 'm';

      } else if (fieldName === 'chk_blind') {
        this.chk_blind = 'd';
        this.chk_blind_w_d = 0;
        this.chk_blind_m_d = 0;
      } else if (fieldName === 'chk_blind_w_d') {
        this.chk_blind_m_d = 0;
        this.chk_blind = 'w';
      } else if (fieldName === 'chk_blind_m_d') {
        this.chk_blind_w_d = 0;
        this.chk_blind = 'm';

      } else if (fieldName === 'chk_quiz') {
        this.chk_quiz = 'd';
        this.chk_quiz_w_d = 0;
        this.chk_quiz_m_d = 0;
      }  else if (fieldName === 'chk_quiz_w_d') {
        this.chk_quiz_m_d = 0;
        this.chk_quiz = 'w';
      } else if (fieldName === 'chk_quiz_m_d') {
        this.chk_quiz_w_d = 0;
        this.chk_quiz = 'm';
      }
    },
//     deselectEverything(fieldName) {
    
//     if (fieldName === 'chk_hand'  ) {
//     this.chk_hand = '';
//   } else if (fieldName === 'chk_foot'  ) {
//     this.chk_foot = '';
//   } else if (fieldName === 'chk_blind'  ) {
//     this.chk_blind = '';
//   } else if (fieldName === 'chk_quiz' ) {
//     this.chk_quiz = '';
//   }
// },
    getCusPmsData() {
      this.disChk = false;
      try {
        permissionsService
          .getdataBycusCode(this.cusCode)
          .then((response) => {
            if (response.data.total > 0){
              this.permissionsData = response.data.data;
            }
            this.disChk = true;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    refetchPmsData() {
      this.getCusPmsData();
    },
    refetchPmData() {
      this.getbigdata();
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    selectedRole() {
      this.getbigdata();
    },
    searchEnter() {
      if (this.filter) {
        this.filterSearch = "&search=" + this.filter;
      } else {
        this.filterSearch = "";
      }
      this.getbigdata();
    },
    searchClear() {
      this.filter = "";
      this.filterSearch = "";
      this.getbigdata();
    },
    perPageChanged() {
      this.filterperPage = "&per_page=" + this.perPage;
      this.getbigdata();
    },
    sortingChanged(e) {
      if (e.sortBy) {
        this.filtersortBy = "&sortBy=" + e.sortBy;
      }

      if (e.sortDesc) {
        this.filtersortDesc = "&sortDesc=DESC";
      } else {
        this.filtersortDesc = "&sortDesc=ASC";
      }
      this.getbigdata();
    },
    handlePageChange() {
      this.getbigdata();
    },
    getbigdata() {
      var page = "page=" + this.currentPage;
      this.filterAll =
        "?" +
        page +
        this.filterSearch +
        this.filterperPage +
        this.filtersortBy +
        this.filtersortDesc +
        "&filterRole="+this.filterRole;

      try {
        memberService
          .getDataBycusCode(this.cusCode, this.filterAll) 
          .then((response) => {
            this.bigdata = response.data.data.data;
            this.totalRows = response.data.data.total;
            console.log(this.bigdata);
            //this.getCusPmsData()
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    updateDepartment(memberData) {
      this.formDataDep.c_id = memberData.c_id;
      this.formDataDep.dep_id = memberData.dep_id;
      this.formDataDep.dep_name = memberData.dep_name;
      if (memberData.dep_name == '') {
        this.formDataDep.dep_id = '';
      }
      
      this.getDepOptions();
      this.isAddUpdateDepSidebarActive = true;
    },
    downloadTempImport() {
      const link = document.createElement("a");
      link.href = process.env.VUE_APP_UPLOAD_IMAGE + "tmp/import-members-template.xlsx";
      link.click();
    },
    openModalImport() {
      if (this.branchSel.length == 0) {
        this.getbranchSel()
      }

      if (this.DepOptions.length == 0) {
        this.getDepOptions();
      }
    },
    addOpen() {
      if (this.branchSel.length == 0) {
        this.getbranchSel()
      }

      if (this.DepOptions.length == 0) {
        this.getDepOptions();
      }

      this.isAddNewSidebarActive = true;
    },
    resetModalIn() {
      this.nameState = null;
      this.selected = [];
      this.selected2 = [];
     
      this.chk_hand = 0;
      this.chk_hand_w_d = 0;
      this.chk_hand_m_d = 0;
      this.chk_foot = 0;
      this.chk_foot_w_d = 0;
      this.chk_foot_m_d = 0;
      this.chk_blind = 0;
      this.chk_blind_w_d = 0;
      this.chk_blind_m_d = 0;
      this.chk_quiz = 0;
      this.chk_quiz_w_d = 0;
      this.chk_quiz_m_d = 0;

      this.form_chk = {
        chk_hand_w: 0,
        chk_hand_m: 0,
        chk_foot_d: 0,
        chk_foot_w: 0,
        chk_foot_m: 0,
        chk_blind_d: 0,
        chk_blind_w: 0,
        chk_blind_m: 0,
        chk_quiz_d: 0,
        chk_quiz_w: 0,
        chk_quiz_m: 0,
        chk_hand_m_d:0,
        chk_hand_w_d:0,
        chk_foot_w_d:0,
        chk_foot_m_d:0,
        chk_blind_w_d:0,
        chk_blind_m_d:0,
        chk_quiz_w_d:0,
        chk_quiz_m_d:0,
      }
    },
    resetModalOut() {
      this.nameState = null;
      this.selected = [];
      this.selected2 = [];

      this.chk_hand = 0;
      this.chk_hand_w_d = 0;
      this.chk_hand_m_d = 0;
      this.chk_foot = 0;
      this.chk_foot_w_d = 0;
      this.chk_foot_m_d = 0;
      this.chk_blind = 0;
      this.chk_blind_w_d = 0;
      this.chk_blind_m_d = 0;
      this.chk_quiz = 0;
      this.chk_quiz_w_d = 0;
      this.chk_quiz_m_d = 0;

      this.form_chk = {
        chk_hand_w: 0,
        chk_hand_m: 0,
        chk_foot_d: 0,
        chk_foot_w: 0,
        chk_foot_m: 0,
        chk_blind_d: 0,
        chk_blind_w: 0,
        chk_blind_m: 0,
        chk_quiz_d: 0,
        chk_quiz_w: 0,
        chk_quiz_m: 0,
        chk_hand_m_d:0,
        chk_hand_w_d:0,
        chk_foot_w_d:0,
        chk_foot_m_d:0,
        chk_blind_w_d:0,
        chk_blind_m_d:0,
        chk_quiz_w_d:0,
        chk_quiz_m_d:0,
      }
    },
    handleUpdateFieldIn(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleUpdateFieldOut(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },

    async handleSubmit() {
      this.form_chk.chk_hand_d = 0;
      this.form_chk.chk_hand_w = 0;
      this.form_chk.chk_hand_m = 0;
      this.formData.chk_hand_opt = 0;
      if (this.chk_hand != 0) {
        if (this.chk_hand == 'd') {
          this.form_chk.chk_hand_d = 1;
        } else if (this.chk_hand == 'w') {
          this.form_chk.chk_hand_w = 1;
          this.formData.chk_hand_opt = this.chk_hand_w_d;
        } else if (this.chk_hand == 'm') {
          this.form_chk.chk_hand_m = 1;
          this.formData.chk_hand_opt = this.chk_hand_m_d;
        }
      }

      this.form_chk.chk_foot_d = 0;
      this.form_chk.chk_foot_w = 0;
      this.form_chk.chk_foot_m = 0;
      this.formData.chk_foot_opt = 0;
      if (this.chk_foot != 0) {
        if (this.chk_foot == 'd') {
          this.form_chk.chk_foot_d = 1;
        } else if (this.chk_foot == 'w') {
          this.form_chk.chk_foot_w = 1;
          this.formData.chk_foot_opt = this.chk_foot_w_d;
        } else if (this.chk_foot == 'm') {
          this.form_chk.chk_foot_m = 1;
          this.formData.chk_foot_opt = this.chk_foot_m_d;
        }
      }

      this.form_chk.chk_blind_d = 0;
      this.form_chk.chk_blind_w = 0;
      this.form_chk.chk_blind_m = 0;
      this.formData.chk_blind_opt = 0;
      if (this.chk_blind != 0) {
        if (this.chk_blind == 'd') {
          this.form_chk.chk_blind_d = 1;
        } else if (this.chk_blind == 'w') {
          this.form_chk.chk_blind_w = 1;
          this.formData.chk_blind_opt = this.chk_blind_w_d;
        } else if (this.chk_blind == 'm') {
          this.form_chk.chk_blind_m = 1;
          this.formData.chk_blind_opt = this.chk_blind_m_d;
        }
      }

      this.form_chk.chk_quiz_d = 0;
      this.form_chk.chk_quiz_w = 0;
      this.form_chk.chk_quiz_m = 0;
      this.formData.chk_quiz_opt = 0;
      if (this.chk_quiz != 0) {
        if (this.chk_quiz == 'd') {
          this.form_chk.chk_quiz_d = 1;
        } else if (this.chk_quiz == 'w') {
          this.form_chk.chk_quiz_w = 1;
          this.formData.chk_quiz_opt = this.chk_quiz_w_d;
        } else if (this.chk_quiz == 'm') {
          this.form_chk.chk_quiz_m = 1;
          this.formData.chk_quiz_opt = this.chk_quiz_m_d;
        }
      }

   // Update the form_chk object based on selected checkboxes
  //  
//   if (this.chk_hand ===  'd'){
// this.form_chk.chk_hand_d = 1
// }else if (this.chk_hand ===  'w'){
//   this.form_chk.chk_hand_w = 1
// }else if (this.chk_hand ===  'm'){
//   this.form_chk.chk_hand_m = 1
// }
//  if (this.chk_foot ===  'd'){
//   this.form_chk.chk_foot_d = '1'
// }else if (this.chk_foot ===  'w'){
//   this.form_chk.chk_foot_w = '1'
// }else if (this.chk_foot ===  'm'){
//   this.form_chk.chk_foot_m = '1'
// }
//  if (this.chk_blind ===  'd'){
//   this.form_chk.chk_blind_d = '1'
// }else if (this.chk_blind ===  'w'){
//   this.form_chk.chk_blind_w = '1'
// }else if (this.chk_blind ===  'm'){
//   this.form_chk.chk_blind_m = '1'
// }
//  if (this.chk_quiz ===  'd'){
//   this.form_chk.chk_quiz_d = 1
// }else if (this.chk_quiz ===  'w'){
//   this.form_chk.chk_quiz_w = 1
// }else if (this.chk_quiz ===  'm'){
//   this.form_chk.chk_quiz_m = 1
// }


  // ฟังก์ชันอื่น ๆ ที่คุณมีอาจจะต้องปรับแก้เพิ่มเติมตามความต้องการ
this.formData.chk_hand_d = this.form_chk.chk_hand_d
this.formData.chk_hand_w = this.form_chk.chk_hand_w
this.formData.chk_hand_m = this.form_chk.chk_hand_m
this.formData.chk_foot_d = this.form_chk.chk_foot_d
this.formData.chk_foot_w = this.form_chk.chk_foot_w
this.formData.chk_foot_m = this.form_chk.chk_foot_m
this.formData.chk_blind_d = this.form_chk.chk_blind_d
this.formData.chk_blind_w = this.form_chk.chk_blind_w
this.formData.chk_blind_m = this.form_chk.chk_blind_m
this.formData.chk_quiz_d = this.form_chk.chk_quiz_d
this.formData.chk_quiz_w = this.form_chk.chk_quiz_w
this.formData.chk_quiz_m = this.form_chk.chk_quiz_m

  // Build selectedValues array from checkboxes and options
  const selectedValues = [];
  this.options.forEach((option) => {
    const value = this.selected.includes(option.item) ? "1" : "0";
    selectedValues.push({ [option.item]: value });
  });

  this.options2.forEach((option) => {
    const value = this.selected2.includes(option.item2) ? "1" : "0";
    selectedValues.push({ [option.item2]: value });
  });

  // Combine selectedValues with form_chk using a loop
  const combinedData = { ...this.form_chk };
  
  selectedValues.forEach((valueObj) => {
    const key = Object.keys(valueObj)[0];
    combinedData[key] = valueObj[key];
  });
  

      if (this.selected == '') {
        this.$swal({
          type: "error",
          title: "เลือกการทดสอบ !!!",
          text: "กรุณาเลือกการทดสอบอย่างน้อย 1 อย่าง",
        });
        return;
      }


  // Convert the combinedData object to a simplified JSON string
  const jsonString = JSON.stringify(combinedData, null, 2);

  // Log the JSON string to the console
  const parsedData = JSON.parse(jsonString);
  this.formData = {
  ...this.formData,
  
  ...parsedData
};




      try {
        memberKiosService
        
          .updateData(this.memberKiosId,this.formData)
         
          .then((response) => {
            console.log(this.memberKiosId, this.formData); 
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "บันทึกข้อมูลไม่สำเร็จ",
                text: "Error! " + response.data.message,
                timer: 3000,
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "บันทึกข้อมูลสำเร็จ!",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.refetchData();
              if (this.chk_type === 1) {  // เมื่อเป็น in
          this.$nextTick(() => {
            this.$refs["my-modal-in-mem"].toggle("#toggle-btn");
            this.resetModalIn();
          });
        } else if (this.chk_type === 2) {  // เมื่อเป็น out
          this.$refs["my-modal-out-mem"].toggle("#toggle-btn");
          this.resetModalOut();
        }
             
          // this.$nextTick(() => {
          //   this.$refs["my-modal-in-mem"].toggle("#toggle-btn");
          //   this.resetModalIn();
          // });
     
          // this.$refs["my-modal-out-mem"].toggle("#toggle-btn");
          // this.resetModalOut();
      
      }
    })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },


    async getMemberKios(id,chk_type,firstname,lastname) {
      this.memberUid = id;
    ////console.log(this.memberUid);
     // กำหนดค่า dep_id ในตัวแปร
  this.chk_type = chk_type; 
  this.firstname = firstname;
  this.lastname = lastname;
  
//console.log(chk_type);
  try {
    memberKiosService
      .getdataById(id,chk_type) 
      .then((response) => {
        //console.log(response);
        this.DataChk = response.data.data;
       //this.clickedDepartmentName = name;
        console.log(this.DataChk.id);  
        this.memberKiosId = this.DataChk.id;
       
        this.selected = [];
if (this.DataChk.chk_alcohol) {
  this.selected.push('chk_alcohol');
}
if (this.DataChk.chk_bp) {
  this.selected.push('chk_bp');
}

this.selected2 = [];
if (this.DataChk.chk_temp) {
  this.selected2.push('chk_temp');
} 
if (this.DataChk.chk_hand_w === 1) {
              this.chk_hand_opt = this.DataChk.chk_hand_opt; // กำหนดค่า chk_hand_opt จากข้อมูล
              if (this.chk_hand_opt == 0) {
                this.chk_hand_opt = 1
              }
              this.chk_hand_w_d = this.chk_hand_opt; // นำค่า chk_hand_opt ไปใส่ใน chk_hand_w_d
            }
            if (this.DataChk.chk_hand_m === 1) {
              this.chk_hand_opt = this.DataChk.chk_hand_opt; 
              if (this.chk_hand_opt == 0) {
                this.chk_hand_opt = 1
              }
              this.chk_hand_m_d = this.chk_hand_opt; 
            }

            if (this.DataChk.chk_foot_w === 1) {
              this.chk_foot_opt = this.DataChk.chk_foot_opt; 
              if (this.chk_foot_opt == 0) {
                this.chk_foot_opt = 1
              }
              this.chk_foot_w_d = this.chk_foot_opt; 
            }
            if (this.DataChk.chk_foot_m === 1) {
              this.chk_foot_opt = this.DataChk.chk_foot_opt;
              if (this.chk_foot_opt == 0) {
                this.chk_foot_opt = 1
              }
              this.chk_foot_m_d = this.chk_foot_opt; 
            }

            if (this.DataChk.chk_blind_w === 1) {
              this.chk_blind_opt = this.DataChk.chk_blind_opt;
              if (this.chk_blind_opt == 0) {
                this.chk_blind_opt = 1
              } 
              this.chk_blind_w_d = this.chk_blind_opt; 
            }
            if (this.DataChk.chk_blind_m === 1) {
              this.chk_blind_opt = this.DataChk.chk_blind_opt;
              if (this.chk_blind_opt == 0) {
                this.chk_blind_opt = 1
              } 
              this.chk_blind_m_d = this.chk_blind_opt; 
            }

            if (this.DataChk.chk_quiz_w === 1) {
              this.chk_quiz_opt = this.DataChk.chk_quiz_opt;
              if (this.chk_quiz_opt == 0) {
                this.chk_quiz_opt = 1
              } 
              this.chk_quiz_w_d = this.chk_quiz_opt; 
            }
            if (this.DataChk.chk_quiz_m === 1) {
              this.chk_quiz_opt = this.DataChk.chk_quiz_opt;
              if (this.chk_quiz_opt == 0) {
                this.chk_quiz_opt = 1
              } 
              this.chk_quiz_m_d = this.chk_quiz_opt; 
            }
// this.form_chk = {

// chk_hand_d: !!this.DataChk.chk_hand_d,
// chk_hand_m: !!this.DataChk.chk_hand_m,
// chk_hand_w: !!this.DataChk.chk_hand_w,
// chk_foot_d: !!this.DataChk.chk_foot_d,
// chk_foot_w: !!this.DataChk.chk_foot_w,
// chk_foot_m: !!this.DataChk.chk_foot_m,
// chk_blind_d: !!this.DataChk.chk_blind_d,
// chk_blind_w: !!this.DataChk.chk_blind_w,
// chk_blind_m: !!this.DataChk.chk_blind_m,
// chk_quiz_d: !!this.DataChk.chk_quiz_d,
// chk_quiz_w: !!this.DataChk.chk_quiz_w,
// chk_quiz_m: !!this.DataChk.chk_quiz_m,
// };
let chkValues = {
  chk_hand: this.DataChk.chk_hand_d === 1 ? 'd' : this.DataChk.chk_hand_w === 1 ? 'w' : this.DataChk.chk_hand_m === 1 ? 'm' : 0,
              chk_foot: this.DataChk.chk_foot_d === 1 ? 'd' : this.DataChk.chk_foot_w === 1 ? 'w' : this.DataChk.chk_foot_m === 1 ? 'm' : 0,
              chk_blind: this.DataChk.chk_blind_d === 1 ? 'd' : this.DataChk.chk_blind_w === 1 ? 'w' : this.DataChk.chk_blind_m === 1 ? 'm' : 0,
              chk_quiz: this.DataChk.chk_quiz_d === 1 ? 'd' : this.DataChk.chk_quiz_w === 1 ? 'w' : this.DataChk.chk_quiz_m === 1 ? 'm' : 0,
              chk_hand_opt: this.DataChk.chk_hand_opt, 
              chk_foot_opt: this.DataChk.chk_foot_opt, 
              chk_blind_opt: this.DataChk.chk_blind_opt, 
              chk_quiz_opt: this.DataChk.chk_quiz_opt, 

};
this.chk_hand = chkValues.chk_hand;
            this.chk_foot = chkValues.chk_foot;
            this.chk_blind = chkValues.chk_blind;
            this.chk_quiz = chkValues.chk_quiz;
   // console.log(this.form_chk);    
    // เปิดโมดัลหลังจากกำหนดค่าให้ form_chk
    this.$refs['my-modal'].show();    
    this.handleSubmit(); 
    //console.log( this.handleSubmit);
      })
      .catch(() => {});
  } catch (e) {
    // console.log(e);
  }
 
},

    editOpen(id) {
      try {
        memberService
          .getdataById(id)
          .then((response) => {
            this.form_Data = response.data.data
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }

      if (this.branchSel.length == 0) {
        this.getbranchSel()
      }

      if (this.DepOptions.length == 0) {
        this.getDepOptions();
      }
      this.isEditSidebarActive = true;
    },
    getbranchSel() {
      try {
        branchService
        .getDataBycusCode(this.cusCode)
          .then((response) => {
            this.branchSel = response.data.data;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    async editPermissOpen(id,firstname,lastname) {
    this.firstname = firstname;
  this.lastname = lastname;
  console.log(  this.firstname);
   
    this.memberUid = id;
    console.log(this.memberUid);
    
    
  },
    getDepOptions() {
      try {
        departmentService
          .getDataBycusCode(this.cusCode)
          .then((response) => {
            this.DepOptions = response.data.data
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    updateStatus(m_uid, status) {
      this.$swal({
        title: "Are you sure?",
        text: "ยืนยันการอัพเดทใช้งานผู้ใช้รายนี้ !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes,confirm it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          try {
            memberService
              .updateStatus(m_uid, status)
              .then((response) => {
                this.getbigdata();
              })
              .catch(() => {});
          } catch (e) {
            // console.log(e);
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "อัพเดทข้อมูลสำเร็จ!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      });
    },
    
    DeletePermiss(id) {
      this.memberUid = id;
    console.log(this.memberUid);
      this.$swal({
        title: "ยืนยันการลบสิทธิ์การใช้งาน?",
        
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          try {
            memberKiosService
              .deleteData(id)
              .then((response) => {
                console.log(response);
                this.getbigdata();
              })
              .catch(() => {});
          } catch (e) {
            // console.log(e);
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "ลบสิทธิ์การใช้งานสำเร็จ!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      });
    },
    removeCompany(c_id) {
      this.$swal({
        title: "Are you sure?",
        text: "ยืนยันการลบออกจากบริษัท !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          try {
            companyService
              .deleteData(c_id)
              .then((response) => {
                this.getbigdata();
              })
              .catch(() => {});
          } catch (e) {
            // console.log(e);
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "ลบพนักงานสำเร็จ!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      });
    },
    showtoken() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      //console.log(decoded);
      // console.log(decoded.role);
      this.user_role = decoded.role
      if (decoded.role == "superadmin" || decoded.role == "admin") {
        this.cusCode = this.$route.params.id;
        this.role = true;
      } else {
        this.cusCode = decoded.cusCode;
        this.role = false;
      }
    },
    refetchData() {
      this.getbigdata();
    },
  },
  async created() {
    await this.showtoken();
    await this.getbigdata();
  },
};
</script>

<style scoped>
.demo-inline-spacing {
  justify-content: space-around;
}
.col-fullname {
  min-width: 150px;
}
.btn-md-cutom-in {
  padding: 0.8rem 1rem;
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}

.btn-md-cutom-out {
  padding: 0.8rem 1rem;
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.btn-cutom-in {
  color: #2196f3 !important;
  border: 1px solid #2196f3!important;
}

.btn-cutom-out {
  color: #ffc107 !important;
  border: 1px solid #ffc107 !important;
}
#modal-lg-in .custom-select {
  text-align: center;
}
#modal-lg-out .custom-select {
  text-align: center;
}

.sel-grp-week .custom-select {
  max-width: 130px;
}
.sel-grp-month .custom-select {
  max-width: 120px;
}
.custom-control-chkin .custom-control-input:checked ~ .custom-control-label::before{
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}

.custom-control-chkout .custom-control-input:checked ~ .custom-control-label::before{
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.chk-permis-dep td .custom-control-label {
  position: relative !important;
 
  padding-right:3rem;

}
</style>


