<template>
  <div>
    <DepartmentAdd
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :customerData="customerData"
      @refetch-data="refetchData"
    />
    <DepartmentEdit
      :is-edit-sidebar-active.sync="isEditSidebarActive"
      :formData="formData"
      @refetch-data="refetchData"
    />
    <b-card>
      <b-col md="6" class="my-1 pl-0">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-button
              style="margin-left: 5px; border-radius: 0.357rem"
              variant="primary"
              size="sm"
              @click="addOpen"
            >
              <span class="text-nowrap">{{ $t('Add') }} {{ $t('Department') }}</span>
            </b-button>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-row v-for="item in bigdata" :key="item.id">
        <b-col md="8">
          <b-card no-body class="business-card">
            <div class="business-items">
              <div class="business-item">
                <div class="d-flex align-items-center justify-content-between">
                  <b>{{ $t('Department') }} : {{ item.name }}</b>
                  <div>


                    <b-button
                      v-b-modal.modal-lg-in
                      v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                      variant="outline-dark text-primary"
                      class="mr-2 btn-cutom-in"
                      @click="getdataKios(item.id,1,item.name)"

                    >
                    <span class="font-weight-bold">{{ $t('Check In') }}</span>
                    </b-button>
                    <b-button
                    v-b-modal.modal-lg-out
                      v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                      variant="outline-dark text-warning"
                      class="mr-2 btn-cutom-out"

                      @click="getdataKios(item.id,2,item.name)"
                    >
                    <span class="font-weight-bold">{{$t('Check Out')}}</span>
                    </b-button>
                    <feather-icon
                    icon="CopyIcon"
                    size="20"
                    class="mr-50 text-danger"
                    style="cursor: pointer"
                    @click="copyDepartmentInfo(item.id)"
                  />
                    <feather-icon

                      icon="EditIcon"
                      size="20"
                      class="mr-75 text-warning"
                      style="cursor: pointer"
                      @click="editOpen(item)"

                    />
                    <feather-icon
                      icon="Trash2Icon"
                      size="20"
                      class="mr-50 text-danger"
                      style="cursor: pointer"
                      @click="deleteData(item.id)"
                      v-if="item.dep_level !== 0 && item.dep_level !== -1"
                    />
                    <span
                    class="mr-50 text-danger"
                    style="
                      cursor: pointer;
                      display: inline-block;
                      width: 20px; /* กำหนดความกว้างเท่ากับขนาดของ icon */
                      height: 20px; /* กำหนดความสูงเท่ากับขนาดของ icon */
                      background-color: transparent; /* กำหนดสีพื้นหลังให้เป็นโปร่งแสง */
                    "
                    @click="deleteData(item.id)"
                    v-if="item.dep_level !== 1"
                  ></span>


                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>

    <!-- modal -->
    <b-modal
      id="modal-lg-in"
      ref="my-modal-in"
      size="lg"
      :ok-title="$t('Save')"
      :cancel-title="$t('Cancel')"
      cancel-variant="outline-secondary"
      @hidden="resetModalIn"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <!-- <b-card-text class="custom-card-text"> -->
          <b-row  class="mb-1" >
            <b-col md="2">


                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                    size="lg"
                    style="width: auto;"
                    class="h-100 w-100 btn-md-cutom-in"
                >
                  {{$t('Check In')}}
                </b-button>


            </b-col>
            <b-col md="10" class="pl-0">

                <h6 class="button-style-trans mb-0">
                  {{ $t("Department Chk Test") }} : {{ clickedDepartmentName }}
                </h6>

            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <b-card-text class="custom-card-text">
                {{ $t("Choose least one") }}
                <b-form-checkbox-group
                  v-model="selected"
                  :options="options"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                  class="demo-inline-spacing custom-control-chkin"
                />
              </b-card-text>
            </b-col>
            <b-col md="4">
              <b-form-checkbox-group
                v-model="selected2"
                :options="options2"
                value-field="item2"
                text-field="name"
                disabled-field="notEnabled"
                class="select-item custom-control-chkin"
              />
            </b-col>
          </b-row>

          <table class="responsive-table-input-matrix chk-permis-dep">
            <thead>
              <tr>
                <th></th>
                <th>{{ $t("Once a day") }}</th>
                <th>{{ $t("Once a week") }}</th>
                <th>{{ $t("Once a month") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-left">{{ $t("Hand response test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-chkin" v-model="chk_hand" value="d" name="chk_hand" @change="deselectEverything('chk_hand')"/>
                </td>
                <td>
                  <b-form-radio v-model="chk_hand" value="w" name="chk_hand" v-show="false" />
                 
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                      <b-form-select
                        :options="dayOptions"
                        v-model="chk_hand_w_d"
                        name="chk_hand_w_d"
                        v-show="showSelect"
                        @change="deselectEverything('chk_hand_w_d')"
                      />
                    </b-input-group>
                  </b-form-group>
                 
                </td>
                <td>
                  <b-form-radio class="custom-control-chkin" v-model="chk_hand" value="m" name="chk_hand" v-show="false"  />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                      <b-form-select
                        :options="monthOptions"
                        v-model="chk_hand_m_d"

                        name="chk_hand_m_d"
                        v-show="showSelect"
                        @change="deselectEverything('chk_hand_m_d')"
                      />
                    </b-input-group>
                  </b-form-group>
                </td>
                <td>
                  <feather-icon icon="XIcon" class="text-danger" v-if="chk_hand === 'd' || chk_hand === 'w' || chk_hand === 'm'"  type="button" @click="remove('chk_hand')"></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Foot response test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-chkin" v-model="chk_foot" value="d" name="chk_foot" @change="deselectEverything('chk_foot')"/>
                </td>
                <td>
                  <b-form-radio v-model="chk_foot" value="w" name="chk_foot" v-show="false" />

                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                      <b-form-select
                        :options="dayOptions"
                        v-model="chk_foot_w_d"

                        name="chk_foot_w_d"
                        v-show="showSelect"
                        @change="deselectEverything('chk_foot_w_d')"
                      />
                    </b-input-group>
                  </b-form-group>
                </td>
                <td>
                  <b-form-radio class="custom-control-chkin" v-model="chk_foot" value="m" name="chk_foot" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                      <b-form-select
                        :options="monthOptions"
                        v-model="chk_foot_m_d"

                        name="chk_foot_m_d"
                        v-show="showSelect"
                        @change="deselectEverything('chk_foot_m_d')"
                      />
                    </b-input-group>
                  </b-form-group>
                </td>
                <td>
                  <feather-icon icon="XIcon" class="text-danger" v-if="chk_foot === 'd' || chk_foot === 'w' || chk_foot === 'm'"  type="button" @click="remove('chk_foot')"></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Blind test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-chkin" v-model="chk_blind" value="d" name="chk_blind" @change="deselectEverything('chk_blind')"/>
                </td>
                <td>
                  <b-form-radio v-model="chk_blind" value="w" name="chk_blind" v-show="false" />

                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                      <b-form-select
                        :options="dayOptions"
                        v-model="chk_blind_w_d"

                        name="chk_blind_w_d"
                        v-show="showSelect"
                        @change="deselectEverything('chk_blind_w_d')"
                      />
                    </b-input-group>
                  </b-form-group>
                </td>
                <td>
                  <b-form-radio v-model="chk_blind" value="m" name="chk_blind" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                      <b-form-select
                        :options="monthOptions"
                        v-model="chk_blind_m_d"

                        name="chk_blind_m_d"
                        v-show="showSelect"
                        @change="deselectEverything('chk_blind_m_d')"
                      />
                    </b-input-group>
                  </b-form-group>
                </td>
                <td>
                  <feather-icon icon="XIcon" class="text-danger" v-if="chk_blind === 'd' || chk_blind === 'w' || chk_blind === 'm'"  type="button" @click="remove('chk_blind')"></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Quizs") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-chkin" v-model="chk_quiz" value="d" name="chk_quiz" @change="deselectEverything('chk_quiz')"/>
                </td>
                <td>
                  <b-form-radio v-model="chk_quiz" value="w" name="chk_quiz" v-show="false" />

                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                      <b-form-select
                        :options="dayOptions"
                        v-model="chk_quiz_w_d"

                        name="chk_quiz_w_d"
                        v-show="showSelect"
                        @change="deselectEverything('chk_quiz_w_d')"
                      />
                    </b-input-group>
                  </b-form-group>
                </td>
                <td>
                  <b-form-radio v-model="chk_quiz" value="m" name="chk_quiz" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                      <b-form-select
                        :options="monthOptions"
                        v-model="chk_quiz_m_d"

                        name="chk_quiz_m_d"
                        v-show="showSelect"
                        @change="deselectEverything('chk_quiz_m_d')"
                      />
                    </b-input-group>
                  </b-form-group>
                </td>
                <td>
                  <feather-icon icon="XIcon" class="text-danger" v-if="chk_quiz === 'd' || chk_quiz === 'w' || chk_quiz === 'm'"  type="button" @click="remove('chk_quiz')"></feather-icon>
                </td>
              </tr>
            </tbody>
          </table>
      </form>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="float-left"
            @click="resetDefault"
          >
          {{$t('Reset Default')}}
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="info btn-md-cutom-in"
            class="float-right"
            @click="handleSubmit"
          >
          {{$t('Save')}}
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="mr-1 float-right"
            @click="hideModal('my-modal-in')"
          >
          {{$t('Cancel')}}
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modal-lg-out"
      ref="my-modal-out"
      size="lg"
      :ok-title="$t('Save')"
      :cancel-title="$t('Cancel')"
      cancel-variant="outline-secondary"
      @hidden="resetModalOut"
    >
      <!-- ส่วนฟอร์มข้อมูลใน Modal ของ OUT -->
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <!-- <b-card-text class="custom-card-text"> -->
          <b-row class="mb-1">
            <b-col md="2">

              <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              size="lg"
              class="h-100 w-100 btn-md-cutom-out"
            >
            {{ $t('Check Out') }}
            </b-button>

            </b-col>
            <b-col md="10" class="pl-0">

                <h6 class="button-style-trans mb-0">
                  การตรวจวัดและทดสอบสำหรับออกงานแผนก : {{ clickedDepartmentName }}
                </h6>

            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <b-card-text class="custom-card-text">
                {{ $t("Choose least one") }}
                <b-form-checkbox-group
                  v-model="selected"
                  :options="options"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                  class="demo-inline-spacing  custom-control-warning custom-control-chkout"
                />
              </b-card-text>
            </b-col>
            <b-col md="4">
              <b-form-checkbox-group
                v-model="selected2"
                :options="options2"
                value-field="item2"
                text-field="name"
                disabled-field="notEnabled"
                class="select-item custom-control-warning custom-control-chkout"
              />
            </b-col>
          </b-row>

          <table class="responsive-table-input-matrix chk-permis-dep">
            <thead>
              <tr>
                <th></th>
                <th>{{ $t("Once a day") }}</th>
                <th>{{ $t("Once a week") }}</th>
                <th>{{ $t("Once a month") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-left">{{ $t("Hand response test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-warning custom-control-chkout" v-model="chk_hand" value="d" name="chk_hand" @change="deselectEverything('chk_hand')"/>
                </td>
                <td>
                  <b-form-radio v-model="chk_hand" value="w" name="chk_hand" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                      <b-form-select
                        :options="dayOptions"
                        v-model="chk_hand_w_d"
                        name="chk_hand_w_d"
                        v-show="showSelect"
                        @change="deselectEverything('chk_hand_w_d')"
                      />
                    </b-input-group>
                  </b-form-group>
                </td>
                <td>
                  <b-form-radio v-model="chk_hand" value="m" name="chk_hand" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                      <b-form-select
                        :options="monthOptions"
                        v-model="chk_hand_m_d"

                        name="chk_hand_m_d"
                        v-show="showSelect"
                        @change="deselectEverything('chk_hand_m_d')"
                      />
                    </b-input-group>
                  </b-form-group>
                </td>
                <td>
                  <feather-icon icon="XIcon" class="text-danger" v-if="chk_hand === 'd' || chk_hand === 'w' || chk_hand === 'm'"  type="button" @click="remove('chk_hand')"></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Foot response test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-warning custom-control-chkout" v-model="chk_foot" value="d" name="chk_foot" @change="deselectEverything('chk_foot')"/>
                </td>
                <td>
                  <b-form-radio v-model="chk_foot" value="w" name="chk_foot" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                      <b-form-select
                        :options="dayOptions"
                        v-model="chk_foot_w_d"

                        name="chk_foot_w_d"
                        v-show="showSelect"
                        @change="deselectEverything('chk_foot_w_d')"
                      />
                    </b-input-group>
                  </b-form-group>
                </td>
                <td>
                  <b-form-radio v-model="chk_foot" value="m" name="chk_foot" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                      <b-form-select
                        :options="monthOptions"
                        v-model="chk_foot_m_d"

                        name="chk_foot_m_d"
                        v-show="showSelect"
                        @change="deselectEverything('chk_foot_m_d')"
                      />
                    </b-input-group>
                  </b-form-group>
                </td>
                <td>
                  <feather-icon icon="XIcon" class="text-danger" v-if="chk_foot === 'd' || chk_foot === 'w' || chk_foot === 'm'"  type="button" @click="remove('chk_foot')"></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Blind test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-warning custom-control-chkout" v-model="chk_blind" value="d" name="chk_blind" @change="deselectEverything('chk_blind')"/>
                </td>
                <td>
                  <b-form-radio v-model="chk_blind" value="w" name="chk_blind" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                      <b-form-select
                        :options="dayOptions"
                        v-model="chk_blind_w_d"
                        name="chk_blind_w_d"
                        v-show="showSelect"
                        @change="deselectEverything('chk_blind_w_d')"
                      />
                    </b-input-group>
                  </b-form-group>
                </td>
                <td>
                  <b-form-radio v-model="chk_blind" value="m" name="chk_blind" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                      <b-form-select
                        :options="monthOptions"
                        v-model="chk_blind_m_d"
                        name="chk_blind_m_d"
                        v-show="showSelect"
                        @change="deselectEverything('chk_blind_m_d')"
                      />
                    </b-input-group>
                  </b-form-group>
                </td>
                <td>
                  <feather-icon icon="XIcon" class="text-danger" v-if="chk_blind === 'd' || chk_blind === 'w' || chk_blind === 'm'"  type="button" @click="remove('chk_blind')"></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Quizs") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-warning custom-control-chkout" v-model="chk_quiz" value="d" name="chk_quiz" @change="deselectEverything('chk_quiz')"/>
                </td>
                <td>
                  <b-form-radio v-model="chk_quiz" value="w" name="chk_quiz" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                      <b-form-select
                        :options="dayOptions"
                        v-model="chk_quiz_w_d"

                        name="chk_quiz_w_d"
                        v-show="showSelect"
                        @change="deselectEverything('chk_quiz_w_d')"
                      />
                    </b-input-group>
                  </b-form-group>
                </td>
                <td>
                  <b-form-radio v-model="chk_quiz" value="m" name="chk_quiz" v-show="false" />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                      <b-form-select
                        :options="monthOptions"
                        v-model="chk_quiz_m_d"

                        name="chk_quiz_m_d"
                        v-show="showSelect"
                        @change="deselectEverything('chk_quiz_m_d')"
                      />
                    </b-input-group>
                  </b-form-group>
                </td>
                <td>
                  <feather-icon icon="XIcon" class="text-danger" v-if="chk_quiz === 'd' || chk_quiz === 'w' || chk_quiz === 'm'"  type="button" @click="remove('chk_quiz')"></feather-icon>
                </td>
              </tr>
            </tbody>
          </table>
      </form>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="float-left"
            @click="resetDefault"
          >
          {{$t('Reset Default')}}
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="float-right btn-md-cutom-out"
            @click="handleSubmit"
          >
          {{$t('Save')}}
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            class="mr-1 float-right"
            @click="hideModal('my-modal-out')"
          >
          {{$t('Cancel')}}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BCardHeader,
  BCardText,
  BLink,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BImg,
  BDropdown,
  BDropdownItem,
  BFormCheckboxGroup,

  BFormRadio
} from "bootstrap-vue";
import jwt_decode from "jwt-decode";
import departmentService from "@/services/departmentService.js";
import depKiosService from "@/services/depKiosService.js";
import DepartmentAdd from "./DepartmentAdd.vue";
import DepartmentEdit from "./DepartmentEdit.vue";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BLink,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BImg,
    BDropdown,
    BDropdownItem,
    DepartmentAdd,
    DepartmentEdit,
    BFormCheckboxGroup,
    BFormRadio
  },
  directives: {
    Ripple,
  },
  setup() {
    const isAddNewSidebarActive = ref(false);
    const isEditSidebarActive = ref(false);

    // const notifyOptions = [
    //   { label: "No", value: 0 },
    //   { label: "Yes", value: 1 },
    // ];

    return {
      // Sidebar
      isAddNewSidebarActive,
      isEditSidebarActive,

      //notifyOptions,
    };
  },
  props: {
    customerData: {},
  },
  data() {
    return {
     // chk_hand: 'w',
      selectedOptionMonth: null, // ตั้งค่าเริ่มต้นของ selectedOption เป็น '1'
      selectedOptionWeek: null,
      showRadio: false, // แสดง <b-form-radio> เริ่มต้น
      showSelect: true, // ซ่อน <b-form-select> เริ่มต้น
      dayOptions: [
        { value: "0", text: '-- '+this.$t('Select day week')+' --' },
        { value: "1", text: this.$t('Monday') },
        { value: "2", text: this.$t('Tuesday') },
        { value: "3", text: this.$t('Wednesday') },
        { value: "4", text: this.$t('Thursday') },
        { value: "5", text: this.$t('Friday') },
        { value: "6", text: this.$t('Saturday') },
        { value: "7", text: this.$t('Sunday') },
      ],
      monthOptions: [
        { value: "0", text: '-- '+this.$t('Select day month')+' --' },
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4", text: "4" },
        { value: "5", text: "5" },
        { value: "6", text: "6" },
        { value: "7", text: "7" },
        { value: "8", text: "8" },
        { value: "9", text: "9" },
        { value: "10", text: "10" },
        { value: "11", text: "11" },
        { value: "12", text: "12" },
        { value: "13", text: "13" },
        { value: "14", text: "14" },
        { value: "15", text: "15" },
        { value: "16", text: "16" },
        { value: "17", text: "17" },
        { value: "18", text: "18" },
        { value: "19", text: "19" },
        { value: "20", text: "20" },
        { value: "21", text: "21" },
        { value: "22", text: "22" },
        { value: "23", text: "23" },
        { value: "24", text: "24" },
        { value: "25", text: "25" },
        { value: "26", text: "26" },
        { value: "27", text: "27" },
        { value: "28", text: "28" },
        { value: "29", text: "29" },
        { value: "30", text: "30" },
        { value: "31", text: "31" },
      ],
      defaultValues: {
        chk_hand: '',
        chk_foot: '',
        chk_blind: '',
        chk_quiz: ''
      },
      chk_hand_m_d: 0,
      chk_hand_w_d: 0,
      chk_foot_w_d: 0,
      chk_foot_m_d: 0,
      chk_blind_w_d: 0,
      chk_blind_m_d: 0,
      chk_quiz_w_d: 0,
      chk_quiz_m_d: 0,
      chk_hand:0,
      chk_quiz:0,
      chk_foot:0,
      chk_blind:0,
      clickedDepartmentName: '',
      modalItemId: null,

      selectedItems: [],
      options: [
        { item: "chk_alcohol", name: this.$t("Alcohol Breat") },
        { item: "chk_bp", name: this.$t("Measure blood pressure") },
      ],
      selected: [],

      selected2: [],
      form_chk: {
        // chk_hand: '',
        chk_hand_d:0,
        chk_hand_w: 0,
        chk_hand_m: 0,
        chk_foot_d: 0,
        chk_foot_w: 0,
        chk_foot_m: 0,
        chk_blind_d: 0,
        chk_blind_w: 0,
        chk_blind_m: 0,
        chk_quiz_d: 0,
        chk_quiz_w: 0,
        chk_quiz_m: 0,
        chk_hand_m_d:0,
        chk_hand_w_d:0,
        chk_foot_w_d:0,
        chk_foot_m_d:0,
        chk_blind_w_d:0,
        chk_blind_m_d:0,
        chk_quiz_w_d:0,
        chk_quiz_m_d:0,
      },
      options2: [{ item2: "chk_temp", name: this.$t("Temp measure1") }],
      cusCode: "",
      perPage: 20,
      pageOptions: [20, 30, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      role: true,

      bigdata: [],
      formData: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {},
  watch: {
    selectedOptionWeek(newVal) {
      if (newVal !== '1') {
        //this.chk_hand = '0'; // กำหนดค่า chk_hand เป็น 'd' ถ้า selectedOption ไม่ใช่ '1'
        this.showRadio = false; // แสดง <b-form-radio>
        this.showSelect = true; // ซ่อน <b-form-select>
      }
    },
    selectedOptionMonth(newVal) {
      if (newVal !== '1') {
        //this.chk_hand = '0'; // กำหนดค่า chk_hand เป็น 'd' ถ้า selectedOption ไม่ใช่ '1'
        this.showRadio = false; // แสดง <b-form-radio>
        this.showSelect = true; // ซ่อน <b-form-select>
      }
    },
  },
  methods: {
    remove(fieldName) {
      if (fieldName === 'chk_hand') {
        this.chk_hand = 0;
        this.chk_hand_m_d = 0;
        this.chk_hand_w_d = 0;
      }else if (fieldName === 'chk_foot') {
        this.chk_foot = 0;
        this.chk_foot_w_d = 0;
        this.chk_foot_m_d = 0;
      }else if (fieldName === 'chk_blind') {
        this.chk_blind = 0;
        this.chk_blind_w_d = 0;
        this.chk_blind_m_d = 0;
      }else if (fieldName === 'chk_quiz') {
        this.chk_quiz = 0;
        this.chk_quiz_w_d = 0;
        this.chk_quiz_m_d = 0;
      }
    },
    //   logChkFootValue(value) {
    //   console.log("chk_foot value:", value); // แสดงค่าที่เลือกผ่าน radio
    // },
    deselectEverything(fieldName) {
      if (fieldName === 'chk_hand') {
        this.chk_hand = 'd';
        this.chk_hand_w_d = 0;
        this.chk_hand_m_d = 0;
      } else if (fieldName === 'chk_hand_w_d') {
        this.chk_hand_m_d = 0;
        this.chk_hand = 'w';
      } else if (fieldName === 'chk_hand_m_d') {
        this.chk_hand_w_d = 0;
        this.chk_hand = 'm';

      } else if (fieldName === 'chk_foot') {
        this.chk_foot = 'd';
        this.chk_foot_w_d = 0;
        this.chk_foot_m_d = 0;
      } else if (fieldName === 'chk_foot_w_d') {
        this.chk_foot_m_d = 0;
        this.chk_foot = 'w';
      } else if (fieldName === 'chk_foot_m_d') {
        this.chk_foot_w_d = 0;
        this.chk_foot = 'm';

      } else if (fieldName === 'chk_blind') {
        this.chk_blind = 'd';
        this.chk_blind_w_d = 0;
        this.chk_blind_m_d = 0;
      } else if (fieldName === 'chk_blind_w_d') {
        this.chk_blind_m_d = 0;
        this.chk_blind = 'w';
      } else if (fieldName === 'chk_blind_m_d') {
        this.chk_blind_w_d = 0;
        this.chk_blind = 'm';

      } else if (fieldName === 'chk_quiz') {
        this.chk_quiz = 'd';
        this.chk_quiz_w_d = 0;
        this.chk_quiz_m_d = 0;
      }  else if (fieldName === 'chk_quiz_w_d') {
        this.chk_quiz_m_d = 0;
        this.chk_quiz = 'w';
      } else if (fieldName === 'chk_quiz_m_d') {
        this.chk_quiz_w_d = 0;
        this.chk_quiz = 'm';
      }
    },

    resetModalIn() {
      this.nameState = null;
      this.selected = [];
      this.selected2 = [];
     
      this.chk_hand = 0;
      this.chk_hand_w_d = 0;
      this.chk_hand_m_d = 0;
      this.chk_foot = 0;
      this.chk_foot_w_d = 0;
      this.chk_foot_m_d = 0;
      this.chk_blind = 0;
      this.chk_blind_w_d = 0;
      this.chk_blind_m_d = 0;
      this.chk_quiz = 0;
      this.chk_quiz_w_d = 0;
      this.chk_quiz_m_d = 0;

      this.form_chk = {
        chk_hand_w: 0,
        chk_hand_m: 0,
        chk_foot_d: 0,
        chk_foot_w: 0,
        chk_foot_m: 0,
        chk_blind_d: 0,
        chk_blind_w: 0,
        chk_blind_m: 0,
        chk_quiz_d: 0,
        chk_quiz_w: 0,
        chk_quiz_m: 0,
        chk_hand_m_d:0,
        chk_hand_w_d:0,
        chk_foot_w_d:0,
        chk_foot_m_d:0,
        chk_blind_w_d:0,
        chk_blind_m_d:0,
        chk_quiz_w_d:0,
        chk_quiz_m_d:0,
      }
    },
    resetModalOut() {
      this.nameState = null;
      this.selected = [];
      this.selected2 = [];

      this.chk_hand = 0;
      this.chk_hand_w_d = 0;
      this.chk_hand_m_d = 0;
      this.chk_foot = 0;
      this.chk_foot_w_d = 0;
      this.chk_foot_m_d = 0;
      this.chk_blind = 0;
      this.chk_blind_w_d = 0;
      this.chk_blind_m_d = 0;
      this.chk_quiz = 0;
      this.chk_quiz_w_d = 0;
      this.chk_quiz_m_d = 0;

      this.form_chk = {
        chk_hand_w: 0,
        chk_hand_m: 0,
        chk_foot_d: 0,
        chk_foot_w: 0,
        chk_foot_m: 0,
        chk_blind_d: 0,
        chk_blind_w: 0,
        chk_blind_m: 0,
        chk_quiz_d: 0,
        chk_quiz_w: 0,
        chk_quiz_m: 0,
        chk_hand_m_d:0,
        chk_hand_w_d:0,
        chk_foot_w_d:0,
        chk_foot_m_d:0,
        chk_blind_w_d:0,
        chk_blind_m_d:0,
        chk_quiz_w_d:0,
        chk_quiz_m_d:0,
      }
    },
    handleUpdateFieldIn(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleUpdateFieldOut(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    hideModal(ref_id) {
      this.$refs[ref_id].hide()
    },    

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async  getdataKios(dep_id,chk_type,name) {

      this.dep_id = dep_id;  // กำหนดค่า dep_id ในตัวแปร
      this.chk_type = chk_type;  // กำหนดค่า chk_type ในตัวแปร
      try {
        depKiosService
          .getdataById(dep_id,chk_type)
          .then((response) => {
            this.DataChk = response.data.data;
            this.clickedDepartmentName = name;
            console.log(this.DataChk);
            //console.log(this.clickedDepartmentName);
            this.selected = [];
            if (this.DataChk.chk_alcohol) {
              this.selected.push('chk_alcohol');
            }
            if (this.DataChk.chk_bp) {
              this.selected.push('chk_bp');
            }

            this.selected2 = [];
            if (this.DataChk.chk_temp) {
              this.selected2.push('chk_temp');
            }

            if (this.DataChk.chk_hand_w === 1) {
              this.chk_hand_opt = this.DataChk.chk_hand_opt; // กำหนดค่า chk_hand_opt จากข้อมูล
              if (this.chk_hand_opt == 0) {
                this.chk_hand_opt = 1
              }
              this.chk_hand_w_d = this.chk_hand_opt; // นำค่า chk_hand_opt ไปใส่ใน chk_hand_w_d
            }
            if (this.DataChk.chk_hand_m === 1) {
              this.chk_hand_opt = this.DataChk.chk_hand_opt; 
              if (this.chk_hand_opt == 0) {
                this.chk_hand_opt = 1
              }
              this.chk_hand_m_d = this.chk_hand_opt; 
            }

            if (this.DataChk.chk_foot_w === 1) {
              this.chk_foot_opt = this.DataChk.chk_foot_opt; 
              if (this.chk_foot_opt == 0) {
                this.chk_foot_opt = 1
              }
              this.chk_foot_w_d = this.chk_foot_opt; 
            }
            if (this.DataChk.chk_foot_m === 1) {
              this.chk_foot_opt = this.DataChk.chk_foot_opt;
              if (this.chk_foot_opt == 0) {
                this.chk_foot_opt = 1
              }
              this.chk_foot_m_d = this.chk_foot_opt; 
            }

            if (this.DataChk.chk_blind_w === 1) {
              this.chk_blind_opt = this.DataChk.chk_blind_opt;
              if (this.chk_blind_opt == 0) {
                this.chk_blind_opt = 1
              } 
              this.chk_blind_w_d = this.chk_blind_opt; 
            }
            if (this.DataChk.chk_blind_m === 1) {
              this.chk_blind_opt = this.DataChk.chk_blind_opt;
              if (this.chk_blind_opt == 0) {
                this.chk_blind_opt = 1
              } 
              this.chk_blind_m_d = this.chk_blind_opt; 
            }

            if (this.DataChk.chk_quiz_w === 1) {
              this.chk_quiz_opt = this.DataChk.chk_quiz_opt;
              if (this.chk_quiz_opt == 0) {
                this.chk_quiz_opt = 1
              } 
              this.chk_quiz_w_d = this.chk_quiz_opt; 
            }
            if (this.DataChk.chk_quiz_m === 1) {
              this.chk_quiz_opt = this.DataChk.chk_quiz_opt;
              if (this.chk_quiz_opt == 0) {
                this.chk_quiz_opt = 1
              } 
              this.chk_quiz_m_d = this.chk_quiz_opt; 
            }

            let chkValues = {
              chk_hand: this.DataChk.chk_hand_d === 1 ? 'd' : this.DataChk.chk_hand_w === 1 ? 'w' : this.DataChk.chk_hand_m === 1 ? 'm' : 0,
              chk_foot: this.DataChk.chk_foot_d === 1 ? 'd' : this.DataChk.chk_foot_w === 1 ? 'w' : this.DataChk.chk_foot_m === 1 ? 'm' : 0,
              chk_blind: this.DataChk.chk_blind_d === 1 ? 'd' : this.DataChk.chk_blind_w === 1 ? 'w' : this.DataChk.chk_blind_m === 1 ? 'm' : 0,
              chk_quiz: this.DataChk.chk_quiz_d === 1 ? 'd' : this.DataChk.chk_quiz_w === 1 ? 'w' : this.DataChk.chk_quiz_m === 1 ? 'm' : 0,
              chk_hand_opt: this.DataChk.chk_hand_opt, 
              chk_foot_opt: this.DataChk.chk_foot_opt, 
              chk_blind_opt: this.DataChk.chk_blind_opt, 
              chk_quiz_opt: this.DataChk.chk_quiz_opt, 
            };
            this.chk_hand = chkValues.chk_hand;
            this.chk_foot = chkValues.chk_foot;
            this.chk_blind = chkValues.chk_blind;
            this.chk_quiz = chkValues.chk_quiz;
            //console.log(this.chk_hand);

            //   this.form_chk = {

            // chk_hand_d: !!this.DataChk.chk_hand_d,
            // chk_hand_m: !!this.DataChk.chk_hand_m,
            // chk_hand_w: !!this.DataChk.chk_hand_w,
            // chk_foot_d: !!this.DataChk.chk_foot_d,
            // chk_foot_w: !!this.DataChk.chk_foot_w,
            // chk_foot_m: !!this.DataChk.chk_foot_m,
            // chk_blind_d: !!this.DataChk.chk_blind_d,
            // chk_blind_w: !!this.DataChk.chk_blind_w,
            // chk_blind_m: !!this.DataChk.chk_blind_m,
            // chk_quiz_d: !!this.DataChk.chk_quiz_d,
            // chk_quiz_w: !!this.DataChk.chk_quiz_w,
            // chk_quiz_m: !!this.DataChk.chk_quiz_m,
            // };
            //console.log(this.form_chk);
            // เปิดโมดัลหลังจากกำหนดค่าให้ form_chk
            //this.$refs['my-modal-in'].show();

          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }

    },

    async  resetDefault() {

      this.$swal({
        title: "ยืนยันการคืนค่าเริ่มต้น ?",
        text: "ข้อมูลที่เคยบันทึกจะถูกคืนค่าเป็นค่าเริ่มต้น !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ใช่, คืนค่าเริ่มต้น",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {

          try {
            depKiosService
              .resetDefDep(this.dep_id, this.chk_type)
              .then((response) => {
               
                if (response.data.status == "error") {
                  this.$swal({
                    type: "error",
                    title: "อัพเดทข้อมูลไม่สำเร็จ",
                    text: "Error! " + response.data.message,
                    timer: 3000,
                  });
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "อัพเดทข้อมูลสำเร็จ!",
                      icon: "CheckIcon",
                      variant: "success",
                    },
                  });
               
                  if (this.chk_type === 1) {  // เมื่อเป็น in
                    this.$nextTick(() => {
                      this.$refs["my-modal-in"].toggle("#toggle-btn");
                    });
                  } else if (this.chk_type === 2) {  // เมื่อเป็น out
                    this.$refs["my-modal-out"].toggle("#toggle-btn");
                  }
                }
              
              })
              .catch(() => {});
          } catch (e) {
            // console.log(e);
          }

        }
      });
     

      },

    async handleSubmit() {
      const formDataToSend = {};
      if (this.selected == '') {
        this.$swal({
          type: "error",
          title: "เลือกการทดสอบ !!!",
          text: "กรุณาเลือกการทดสอบอย่างน้อย 1 อย่าง",
        });
        return;
      }


      // Build selectedValues array from checkboxes and options
      const selectedValues = [];
      this.options.forEach((option) => {
        if (this.selected.includes(option.item)) {
          selectedValues.push({ [option.item]: '1' });
        } else {
          selectedValues.push({ [option.item]: '0' });
        }
      });

      this.options2.forEach((option) => {
        if (this.selected2.includes(option.item2)) {
          selectedValues.push({ [option.item2]: '1' });
        } else {
          selectedValues.push({ [option.item2]: '0' });
        }
      });

      // Combine selectedValues with formDataToSend
      selectedValues.forEach((valueObj) => {
        const key = Object.keys(valueObj)[0];
        formDataToSend[key] = valueObj[key];
      });

      // Convert the formDataToSend object to a JSON string
      const jsonString = JSON.stringify(formDataToSend, null, 2);

      // Update the formData
      this.formData = formDataToSend;
      this.form_chk.chk_hand_d = 0;
      this.form_chk.chk_hand_w = 0;
      this.form_chk.chk_hand_m = 0;
      this.formData.chk_hand_opt = 0;
      if (this.chk_hand != 0) {
        if (this.chk_hand == 'd') {
          this.form_chk.chk_hand_d = 1;
        } else if (this.chk_hand == 'w') {
          this.form_chk.chk_hand_w = 1;
          this.formData.chk_hand_opt = this.chk_hand_w_d;
        } else if (this.chk_hand == 'm') {
          this.form_chk.chk_hand_m = 1;
          this.formData.chk_hand_opt = this.chk_hand_m_d;
        }
      }

      this.form_chk.chk_foot_d = 0;
      this.form_chk.chk_foot_w = 0;
      this.form_chk.chk_foot_m = 0;
      this.formData.chk_foot_opt = 0;
      if (this.chk_foot != 0) {
        if (this.chk_foot == 'd') {
          this.form_chk.chk_foot_d = 1;
        } else if (this.chk_foot == 'w') {
          this.form_chk.chk_foot_w = 1;
          this.formData.chk_foot_opt = this.chk_foot_w_d;
        } else if (this.chk_foot == 'm') {
          this.form_chk.chk_foot_m = 1;
          this.formData.chk_foot_opt = this.chk_foot_m_d;
        }
      }

      this.form_chk.chk_blind_d = 0;
      this.form_chk.chk_blind_w = 0;
      this.form_chk.chk_blind_m = 0;
      this.formData.chk_blind_opt = 0;
      if (this.chk_blind != 0) {
        if (this.chk_blind == 'd') {
          this.form_chk.chk_blind_d = 1;
        } else if (this.chk_blind == 'w') {
          this.form_chk.chk_blind_w = 1;
          this.formData.chk_blind_opt = this.chk_blind_w_d;
        } else if (this.chk_blind == 'm') {
          this.form_chk.chk_blind_m = 1;
          this.formData.chk_blind_opt = this.chk_blind_m_d;
        }
      }

      this.form_chk.chk_quiz_d = 0;
      this.form_chk.chk_quiz_w = 0;
      this.form_chk.chk_quiz_m = 0;
      this.formData.chk_quiz_opt = 0;
      if (this.chk_quiz != 0) {
        if (this.chk_quiz == 'd') {
          this.form_chk.chk_quiz_d = 1;
        } else if (this.chk_quiz == 'w') {
          this.form_chk.chk_quiz_w = 1;
          this.formData.chk_quiz_opt = this.chk_quiz_w_d;
        } else if (this.chk_quiz == 'm') {
          this.form_chk.chk_quiz_m = 1;
          this.formData.chk_quiz_opt = this.chk_quiz_m_d;
        }
      }

      // console.log(this.chk_quiz_w_d);
      // console.log(this.chk_quiz_m_d);
      // console.log(this.formData.chk_quiz_opt);


      // if (this.chk_quiz_m_d !== null) {
      //   this.form_chk.chk_quiz_m = 1;
      // } else {
      //   this.form_chk.chk_quiz_m = 0;
      // }
      // }else if (this.chk_hand ===  'm'){
      //   this.form_chk.chk_hand_m = 1
      // }
      //  if (this.chk_foot ===  'd'){
      //   this.form_chk.chk_foot_d = '1'
      // }else if (this.chk_foot ===  'w'){
      //   this.form_chk.chk_foot_w = '1'
      // }else if (this.chk_foot ===  'm'){
      //   this.form_chk.chk_foot_m = '1'
      // }
      //  if (this.chk_blind ===  'd'){
      //   this.form_chk.chk_blind_d = '1'
      // }else if (this.chk_blind ===  'w'){
      //   this.form_chk.chk_blind_w = '1'
      // }else if (this.chk_blind ===  'm'){
      //   this.form_chk.chk_blind_m = '1'
      // }
      //  if (this.chk_quiz ===  'd'){
      //   this.form_chk.chk_quiz_d = 1
      // }else if (this.chk_quiz ===  'w'){
      //   this.form_chk.chk_quiz_w = 1
      // }else if (this.chk_quiz ===  'm'){
      //   this.form_chk.chk_quiz_m = 1
      // }


      // ฟังก์ชันอื่น ๆ ที่คุณมีอาจจะต้องปรับแก้เพิ่มเติมตามความต้องการ
      this.formData.chk_hand_d = this.form_chk.chk_hand_d
      this.formData.chk_hand_w = this.form_chk.chk_hand_w
      this.formData.chk_hand_m = this.form_chk.chk_hand_m
      this.formData.chk_foot_d = this.form_chk.chk_foot_d
      this.formData.chk_foot_w = this.form_chk.chk_foot_w
      this.formData.chk_foot_m = this.form_chk.chk_foot_m
      this.formData.chk_blind_d = this.form_chk.chk_blind_d
      this.formData.chk_blind_w = this.form_chk.chk_blind_w
      this.formData.chk_blind_m = this.form_chk.chk_blind_m
      this.formData.chk_quiz_d = this.form_chk.chk_quiz_d
      this.formData.chk_quiz_w = this.form_chk.chk_quiz_w
      this.formData.chk_quiz_m = this.form_chk.chk_quiz_m

      //this.formData.chk_hand_opt = this.chk_hand_m_d,
      // this.formData.chk_foot_m_d=this.chk_foot_m_d,
      // this.formData.chk_quiz_m_d= this.chk_quiz_m_d,
      // this.formData.chk_blind_m_d= this.chk_blind_m_d,

      //this.formData.chk_hand_opt = this.chk_hand_w_d,
      // this.formData.chk_foot_w_d=this.chk_foot_w_d,
      // this.formData.chk_quiz_w_d= this.chk_quiz_w_d,
      // this.formData.chk_blind_w_d= this.chk_blind_w_d,


      this.formData.cusId = this.customerData.cusId;
      this.formData.dep_id = this.dep_id;  // กำหนดค่า dep_id ใน this.formData
      this.formData.checkin_type = this.chk_type;  // กำหนดค่า chk_type ใน this.formData
      //console.log( this.formData.checkin_type);
      //console.log(this.formData.dep_id );

      try {
          depKiosService

            .insertData(this.formData)

            .then((response) => {
                console.log(this.formData);
                if (response.data.status == "error") {
                  this.$swal({
                    type: "error",
                    title: "บันทึกข้อมูลไม่สำเร็จ",
                    text: "Error! " + response.data.message,
                    timer: 3000,
                  });
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "บันทึกข้อมูลสำเร็จ!",
                      icon: "CheckIcon",
                      variant: "success",
                    },
                  });
                  this.refetchData();
                  if (this.chk_type === 1) {  // เมื่อเป็น in
              this.$nextTick(() => {
                this.$refs["my-modal-in"].toggle("#toggle-btn");
                this.resetModalIn();
              });
            } else if (this.chk_type === 2) {  // เมื่อเป็น out
              this.$refs["my-modal-out"].toggle("#toggle-btn");
              this.resetModalOut();
            }
          }
        })
        .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    getbigdata() {
      try {
        departmentService
          .getDataBycusCode(this.cusCode)
          .then((response) => {
            this.bigdata = response.data.data;
            this.totalRows = this.bigdata.length;
            //console.log(this.bigdata);
            ;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },


    copyDepartmentInfo(dep_id) {
      this.dep_id = dep_id;
      try {
        departmentService
          .CopyData(dep_id)

          .then((response) => {
           // console.log(response);
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "คัดลอกไม่สำเร็จ",
                text: "Error! " + response.data.message,
                timer: 3000,
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "คัดลอกสำเร็จ!",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.refetchData();
              this.$emit("update:is-add-new-sidebar-active", false);



            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },

    addOpen() {
      this.isAddNewSidebarActive = true;
    },
    editOpen(item) {
      this.formData = item;
      this.isEditSidebarActive = true;
    },

    deleteData(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          try {
            departmentService
              .deleteData(id)
              .then((response) => {
                this.getbigdata();
              })
              .catch(() => {});
          } catch (e) {
            // console.log(e);
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "ลบข้อมูลสำเร็จ!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      });
    },
    showtoken() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      // console.log(decoded);
      if (decoded.role == "superadmin" || decoded.role == "admin") {
        this.cusCode = this.$route.params.id;
      } else {
        this.cusCode = decoded.cusCode;
      }
    },
    refetchData() {
      this.getbigdata();
    },
  },
  async created() {
    await this.showtoken();
    await this.getbigdata();
  },
};
</script>

<style scoped>

.col-date-format {
  min-width: 110px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.button-style {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ebe9f1; /* สีเส้นกรอบเส้นโค้ง (สีน้ำเงิน) */
  border-radius: 15px; /* ความโค้งของกรอบเส้นโค้ง */
  font-size: 24px; /* ขนาดตัวอักษร */
  text-align: center;
  color: #007bff; /* สีข้อความ (สีน้ำเงิน) */
}

.button-style-trans {
  display: flex;
  justify-content: center; /* กำหนดให้เนื้อหาอยู่กึ่งกลางแนวนอน */
  align-items: center;
  padding: 10px;
  border: 1px solid #ebe9f1;
  border-radius: 5px;
  font-size: 24px;
  width: auto; /* ปรับความกว้างเป็น auto */
  max-width: 100%; /* กำหนดความกว้างสูงสุดเป็น 100% */
  box-sizing: border-box; /* ป้องกันการเพิ่มขนาดขององค์ประกอบ */
}

.btn-cutom-in {
  color: #2196f3 !important;
  border: 1px solid #2196f3!important;
}

.btn-cutom-out {
  color: #ffc107 !important;
  border: 1px solid #ffc107 !important;
}

.btn-md-cutom-in {
  padding: 0.8rem 1rem;
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}

.btn-md-cutom-out {
  padding: 0.8rem 1rem;
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

.custom-card-text {
  border: 1px solid #ebe9f1; /* สีเส้นกรอบสีดำ */
  padding: 10px; /* ขอบการเพิ่มระยะระหว่างเนื้อหากับกรอบ */
  border-radius: 5px; /* ความโค้งของกรอบสีดำ */
  text-align: center;
}
.select-item {
  padding: 55px;
}

.center-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-table {
  background-color: transparent; /* กำหนดพื้นหลังโปร่งใส */
  border: none; /* ลบเส้นกรอบ */
}
table {
  width: 100%;
  border-collapse: collapse;
}
/* Zebra striping */
tr:nth-of-type(odd) {
  background: transparent;
}
th {
  background:transparent;

  font-weight: bold;
}
td, th {
  padding: 6px;
  border: 1px solid transparent;
  text-align: left;
}

.chk-permis-dep th {
    text-align: center;
    padding-left: 10px; /* ระยะห่างทางซ้าย */
  padding-right: 10px; /* ระยะห่างทางขวา */
}

.chk-permis-dep td {
  text-align: center;
  padding-left: 10px; /* ระยะห่างทางซ้าย */
  padding-right: 10px; /* ระยะห่างทางขวา */



}

.chk-permis-dep td .custom-radio {
  padding: 0;
  padding-right: 1.8rem;
}

.demo-inline-spacing {
  justify-content: space-around;
}
.chk-permis-dep th:nth-child(3),
.chk-permis-dep td:nth-child(3) {
  padding-right: 0px; /* ระยะห่างทางซ้ายของ <th> และ <td> ในคอลัมน์ที่ 4 */
}
.chk-permis-dep th:nth-child(4),
.chk-permis-dep td:nth-child(4) {
  padding-left: 0px; /* ระยะห่างทางซ้ายของ <th> และ <td> ในคอลัมน์ที่ 4 */
}

/*
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
</style>

<style>
.chk-permis-dep td .custom-control-label {
  position: relative !important;
 
    padding-right:3rem;

}
#modal-lg-in .custom-select {
  text-align: center;
}
#modal-lg-out .custom-select {
  text-align: center;
}

.sel-grp-week .custom-select {
  max-width: 130px;
}
.sel-grp-month .custom-select {
  max-width: 120px;
}

.custom-control-chkin .custom-control-input:checked ~ .custom-control-label::before{
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
 
}

.custom-control-chkout .custom-control-input:checked ~ .custom-control-label::before{
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

</style>

