import http from "./http";

export default {
    getalldata(filterAll) {
        return http
            .get("quizs" + filterAll)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },


    getdataBycusCode(id) {
        return http
            .get("quizs/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },


    insertdataQuiz(form) {
        return http
            .post("quizs", form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },




    updatedataQuiz(id, form) {
        return http
            .put("quizs/" + id, form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },

    deletedataQuiz(id) {
        return http
            .delete("quizs/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    // insertdatamember(form) {
    //     return http
    //         .post("employee", form)
    //         .then(function (response) {
    //             return Promise.resolve(response);
    //         })
    //         .catch(function (error) {
    //             return Promise.reject(error);
    //         });
    // },
    updatecustomer(id, form) {
        return http
            .put("adminCustomer/" + id, form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    // updatedataproduct(id, form) {
    //     return http
    //         .put("product_info/" + id, form)
    //         .then(function (response) {
    //             return Promise.resolve(response);
    //         })
    //         .catch(function (error) {
    //             return Promise.reject(error);
    //         });
    // },
    // updatedatamember(cusid, form) {
    //     return http
    //         .put("employee/" + cusid, form)
    //         .then(function (response) {
    //             return Promise.resolve(response);
    //         })
    //         .catch(function (error) {
    //             return Promise.reject(error);
    //         });
    // },
    deletedata(id) {
        return http
            .delete("admin/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },

    getdatacustomer(id) {
        return http
            .get("detailcustomer/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },

}