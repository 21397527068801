<template>
  <b-modal
    id="modal-lg"
    ref="my-modal-lg"
    size="lg"
    :ok-title="$t('Save')"
    :cancel-title="$t('Cancel')"
    cancel-variant="outline-secondary"
    @hidden="resetModal"
    @ok="handleUpdateField"
  >
    <div class="mb-1">
      <b-row>
        <b-col md="12" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50"
              >{{ $t("Set default dep") }}</label
            >
            <b-input-group size="sm" @click="getbigdata">
              <b-form-select
                id="filterSelect"
                v-model="filterMemberSelect"
                class="w-75"
                @change="filterMemberSelectChanged"
              >
                <template>
                  <option value="">--{{ $t("Select") }} {{ $t("Department") }}--</option>

                  <option
                    :value="item.id"
                    v-for="item in bigdata"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </option>
                </template>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="justify-content-center">
        <b-nav pills>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="lg"
            style="width: 100px"
            class="btn-md-cutom-in"
            @click="setActiveTab('1')"
          >
          {{ $t("Check In") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            size="lg"
            style="width: 100px"
            class="btn-md-cutom-out"
            @click="setActiveTab('2')"
          >
          {{ $t("Check Out") }}
          </b-button>
        </b-nav>
      </b-row>

      <b-row class="tab-permiss" v-show="activetab === '1'">
        <form class="w-100 m-1" ref="form" @submit.stop.prevent="handleSubmit">
          <!-- <b-card-text class="custom-card-text"> -->
          <b-row class="mb-1 w-100">
            <b-col md="2">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                    size="lg"
                    style="width: auto;"
                    class="h-100 w-100 btn-md-cutom-in"
                >
                  {{$t('Check In')}}
                </b-button>
            </b-col>
            <b-col md="10" class="pl-0">
              <h6 class="button-style-trans mb-0">
                {{ $t("Emp screening test") }}: {{ firstname }}
                {{ lastname }}
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <b-card-text class="custom-card-text">
                {{ $t("Choose least one") }}
                <b-form-checkbox-group
                  v-model="selected_in"
                  :options="options_in"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                  class="demo-inline-spacing custom-control-chkin"
                />
              </b-card-text>
            </b-col>
            <b-col md="4">
              <b-form-checkbox-group
                v-model="selected2_in"
                :options="options2_in"
                value-field="item2"
                text-field="name"
                disabled-field="notEnabled"
                class="select-item custom-control-chkin"
              />
            </b-col>
          </b-row>

          <table class="responsive-table-input-matrix chk-permis-dep">
            <thead>
              <tr>
                <th></th>
                <th>{{ $t("Once a day") }}</th>
                <th>{{ $t("Once a week") }}</th>
                <th>{{ $t("Once a month") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-left">{{ $t("Hand response test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-chkin"
                    v-model="form_chk_in.chk_hand"
                    value="d"
                    name="form_chk_in.chk_hand"
                    @change="deselectEverything('form_chk_in.chk_hand')"
                  />
                </td>
                <td>
                  <b-form-radio class="custom-control-chkin"
                    v-model="form_chk_in.chk_hand"
                    value="w"
                    name="form_chk_in.chk_hand"
                    v-show="false"
                  />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="dayOptions"
                    v-model="form_chk_in.chk_hand_w_d"
                    name="chk_hand_w_d"
                    v-show="showSelect"
                    @change="deselectEverything('form_chk_in.chk_hand_w_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <b-form-radio class="custom-control-chkin"
                    v-model="form_chk_in.chk_hand"
                    value="m"
                    name="form_chk_in.chk_hand"
                    v-show="false"
                  />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="monthOptions"
                    v-model="form_chk_in.chk_hand_m_d"
                    name="chk_hand_m_d"
                    v-show="showSelect"
                    @change="deselectEverything('form_chk_in.chk_hand_m_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <feather-icon
                    icon="XIcon"
                    class="text-danger"
                    v-if="
                      form_chk_in.chk_hand === 'd' ||
                      form_chk_in.chk_hand === 'w' ||
                      form_chk_in.chk_hand === 'm'
                    "
                    type="button"
                    @click="remove('form_chk_in.chk_hand')"
                  ></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Foot response test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-chkin"
                    v-model="form_chk_in.chk_foot"
                    value="d"
                    name="form_chk_in.chk_foot"
                    @change="deselectEverything('form_chk_in.chk_foot')"
                  />
                </td>
                <td>
                  <b-form-radio class="custom-control-chkin"
                    v-model="form_chk_in.chk_foot"
                    value="w"
                    name="form_chk_in.chk_foot"
                    v-show="false"
                  />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="dayOptions"
                    v-model="form_chk_in.chk_foot_w_d"
                    name="chk_foot_w_d"
                    v-show="showSelect"
                    @change="deselectEverything('form_chk_in.chk_foot_w_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <b-form-radio 
                    v-model="form_chk_in.chk_foot"
                    value="m"
                    name="form_chk_in.chk_foot"
                    v-show="false"
                  />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="monthOptions"
                    v-model="form_chk_in.chk_foot_m_d"
                    name="chk_foot_m_d"
                    v-show="showSelect"
                    @change="deselectEverything('form_chk_in.chk_foot_m_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <feather-icon
                    icon="XIcon"
                    class="text-danger"
                    v-if="
                      form_chk_in.chk_foot === 'd' ||
                      form_chk_in.chk_foot === 'w' ||
                      form_chk_in.chk_foot === 'm'
                    "
                    type="button"
                    @click="remove('form_chk_in.chk_foot')"
                  ></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Blind test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-chkin"
                    v-model="form_chk_in.chk_blind"
                    value="d"
                    name="form_chk_in.chk_blind"
                    @change="deselectEverything('form_chk_in.chk_blind')"
                  />
                </td>
                <td>
                  <b-form-radio
                    v-model="form_chk_in.chk_blind"
                    value="w"
                    name="form_chk_in.chk_blind"
                    v-show="false"
                  />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="dayOptions"
                    v-model="form_chk_in.chk_blind_w_d"
                    name="chk_blind_w_d"
                    v-show="showSelect"
                    @change="deselectEverything('form_chk_in.chk_blind_w_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <b-form-radio
                    v-model="form_chk_in.chk_blind"
                    value="m"
                    name="form_chk_in.chk_blind"
                    v-show="false"
                  />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="monthOptions"
                    v-model="form_chk_in.chk_blind_m_d"
                    name="chk_blind_m_d"
                    v-show="showSelect"
                    @change="deselectEverything('form_chk_in.chk_blind_m_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <feather-icon
                    icon="XIcon"
                    class="text-danger"
                    v-if="
                      form_chk_in.chk_blind === 'd' ||
                      form_chk_in.chk_blind === 'w' ||
                      form_chk_in.chk_blind === 'm'
                    "
                    type="button"
                    @click="remove('form_chk_in.chk_blind')"
                  ></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Quizs") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-chkin"
                    v-model="form_chk_in.chk_quiz"
                    value="d"
                    name="form_chk_in.chk_quiz"
                    @change="deselectEverything('form_chk_in.chk_quiz')"
                  />
                </td>
                <td>
                  <b-form-radio
                    v-model="form_chk_in.chk_quiz"
                    value="w"
                    name="form_chk_in.chk_quiz"
                    v-show="false"
                  />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="dayOptions"
                    v-model="form_chk_in.chk_quiz_w_d"
                    name="chk_quiz_w_d"
                    v-show="showSelect"
                    @change="deselectEverything('form_chk_in.chk_quiz_w_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <b-form-radio
                    v-model="form_chk_in.chk_quiz"
                    value="m"
                    name="form_chk_in.chk_quiz"
                    v-show="false"
                  />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="monthOptions"
                    v-model="form_chk_in.chk_quiz_m_d"
                    name="chk_quiz_m_d"
                    v-show="showSelect"
                    @change="deselectEverything('form_chk_in.chk_quiz_m_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <feather-icon
                    icon="XIcon"
                    class="text-danger"
                    v-if="
                      form_chk_in.chk_quiz === 'd' ||
                      form_chk_in.chk_quiz === 'w' ||
                      form_chk_in.chk_quiz === 'm'
                    "
                    type="button"
                    @click="remove('form_chk_in.chk_quiz')"
                  ></feather-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </b-row>
      <b-row class="tab-permiss" v-show="activetab === '2'">
        <form class="w-100 m-1" ref="form" @submit.stop.prevent="handleSubmit">
          <!-- <b-card-text class="custom-card-text"> -->
          <b-row class="mb-1 w-100">
            <b-col md="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="warning"
                size="lg"
                class="h-100 w-100"
              >
                OUT
              </b-button>
            </b-col>
            <b-col md="10" class="pl-0">
              <h6 class="button-style-trans mb-0">
                การตรวจวัดและทดสอบสำหรับออกงานของ: {{ firstname }}
                {{ lastname }}
              </h6>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="8">
              <b-card-text class="custom-card-text">
                {{ $t("Choose least one") }}
                <b-form-checkbox-group
                  v-model="selected_out"
                  :options="options_out"
                  value-field="item"
                  text-field="name"
                  disabled-field="notEnabled"
                  class="demo-inline-spacing  custom-control-warning custom-control-chkout"
                />
              </b-card-text>
            </b-col>
            <b-col md="4">
              <b-form-checkbox-group
                v-model="selected2_out"
                :options="options2_out"
                value-field="item2"
                text-field="name"
                disabled-field="notEnabled"
                class="select-item custom-control-warning custom-control-chkout"
              />
            </b-col>
          </b-row>

          <table class="responsive-table-input-matrix chk-permis-dep">
            <thead>
              <tr>
                <th></th>
                <th>{{ $t("Once a day") }}</th>
                <th>{{ $t("Once a week") }}</th>
                <th>{{ $t("Once a month") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-left">{{ $t("Hand response test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-warning custom-control-chkout"
                    v-model="form_chk_out.chk_hand"
                    value="d"
                    name="form_chk_out.chk_hand"
                    @change="deselectEverything('form_chk_out.chk_hand')"
                  />
                </td>
                <td>
                  <b-form-radio
                    v-model="form_chk_out.chk_hand"
                    value="w"
                    name="form_chk_out.chk_hand"
                    v-show="false"
                  />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="dayOptions"
                    v-model="form_chk_out.chk_hand_w_d"
                    name="chk_hand_w_d"
                    v-show="showSelect"
                    @change="deselectEverything('form_chk_out.chk_hand_w_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <b-form-radio
                    v-model="form_chk_out.chk_hand"
                    value="m"
                    name="form_chk_out.chk_hand"
                    v-show="false"
                  />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="monthOptions"
                    v-model="form_chk_out.chk_hand_m_d"
                    name="chk_hand_m_d"
                    v-show="showSelect"
                    @change="deselectEverything('form_chk_out.chk_hand_m_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <feather-icon
                    icon="XIcon"
                    class="text-danger"
                    v-if="
                      form_chk_out.chk_hand === 'd' ||
                      form_chk_out.chk_hand === 'w' ||
                      form_chk_out.chk_hand === 'm'
                    "
                    type="button"
                    @click="remove('form_chk_out.chk_hand')"
                  ></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Foot response test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-warning custom-control-chkout"
                    v-model="form_chk_out.chk_foot"
                    value="d"
                    name="form_chk_out.chk_foot"
                    @change="deselectEverything('form_chk_out.chk_foot')"
                  />
                </td>
                <td>
                  <b-form-radio
                    v-model="form_chk_out.chk_foot"
                    value="w"
                    name="form_chk_out.chk_foot"
                    v-show="false"
                  />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="dayOptions"
                    v-model="form_chk_out.chk_foot_w_d"
                    name="chk_foot_w_d"
                    v-show="showSelect"
                    @change="deselectEverything('form_chk_out.chk_foot_w_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <b-form-radio
                    v-model="form_chk_out.chk_foot"
                    value="m"
                    name="form_chk_out.chk_foot"
                    v-show="false"
                  />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="monthOptions"
                    v-model="form_chk_out.chk_foot_m_d"
                    name="chk_foot_m_d"
                    v-show="showSelect"
                    @change="deselectEverything('form_chk_out.chk_foot_m_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <feather-icon
                    icon="XIcon"
                    class="text-danger"
                    v-if="
                      form_chk_out.chk_foot === 'd' ||
                      form_chk_out.chk_foot === 'w' ||
                      form_chk_out.chk_foot === 'm'
                    "
                    type="button"
                    @click="remove('form_chk_out.chk_foot')"
                  ></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Blind test") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-warning custom-control-chkout"
                    v-model="form_chk_out.chk_blind"
                    value="d"
                    name="form_chk_out.chk_blind"
                    @change="deselectEverything('form_chk_out.chk_blind')"
                  />
                </td>
                <td>
                  <b-form-radio
                    v-model="form_chk_out.chk_blind"
                    value="w"
                    name="form_chk_out.chk_blind"
                    v-show="false"
                  />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="dayOptions"
                    v-model="form_chk_out.chk_blind_w_d"
                    name="chk_blind_w_d"
                    v-show="showSelect"
                    @change="deselectEverything('form_chk_out.chk_blind_w_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <b-form-radio
                    v-model="form_chk_out.chk_blind"
                    value="m"
                    name="form_chk_out.chk_blind"
                    v-show="false"
                  />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="monthOptions"
                    v-model="form_chk_out.chk_blind_m_d"
                    name="chk_blind_m_d"
                    v-show="showSelect"
                    @change="deselectEverything('form_chk_out.chk_blind_m_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <feather-icon
                    icon="XIcon"
                    class="text-danger"
                    v-if="
                      form_chk_out.chk_blind === 'd' ||
                      form_chk_out.chk_blind === 'w' ||
                      form_chk_out.chk_blind === 'm'
                    "
                    type="button"
                    @click="remove('form_chk_out.chk_blind')"
                  ></feather-icon>
                </td>
              </tr>
              <tr>
                <td class="text-left">{{ $t("Quizs") }}</td>
                <td>
                  <b-form-checkbox class="custom-control-warning custom-control-chkout"
                    v-model="form_chk_out.chk_quiz"
                    value="d"
                    name="form_chk_out.chk_quiz"
                    @change="deselectEverything('form_chk_out.chk_quiz')"
                  />
                </td>
                <td>
                  <b-form-radio
                    v-model="form_chk_out.chk_quiz"
                    value="w"
                    name="form_chk_out.chk_quiz"
                    v-show="false"
                  />
                  <b-form-group class="mb-0 sel-grp-week">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="dayOptions"
                    v-model="form_chk_out.chk_quiz_w_d"
                    name="chk_quiz_w_d"
                    v-show="showSelect"
                    @change="deselectEverything('form_chk_out.chk_quiz_w_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <b-form-radio
                    v-model="form_chk_out.chk_quiz"
                    value="m"
                    name="form_chk_out.chk_quiz"
                    v-show="false"
                  />
                  <b-form-group class="mb-0 sel-grp-month">
                    <b-input-group size="sm" class="justify-content-center">
                  <b-form-select
                    :options="monthOptions"
                    v-model="form_chk_out.chk_quiz_m_d"
                    name="chk_quiz_m_d"
                    v-show="showSelect"
                    @change="deselectEverything('form_chk_out.chk_quiz_m_d')"
                  />
                </b-input-group>
              </b-form-group>
                </td>
                <td>
                  <feather-icon
                    icon="XIcon"
                    class="text-danger"
                    v-if="
                      form_chk_out.chk_quiz === 'd' ||
                      form_chk_out.chk_quiz === 'w' ||
                      form_chk_out.chk_quiz === 'm'
                    "
                    type="button"
                    @click="remove('form_chk_out.chk_quiz')"
                  ></feather-icon>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BModal,
  VBModal,
  BBadge,
  BButton,
  BInputGroup,
  BFormGroup,
  BRow,
  BCol,
  BTable,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
  BButtonGroup,
  BFormCheckboxGroup,
  BCardText,
  BFormSelect,
  BNav,
  BNavItem,
  BFormRadio,
} from "bootstrap-vue";
import jwt_decode from "jwt-decode";
import departmentService from "@/services/departmentService.js";
import depKiosService from "@/services/depKiosService.js";
import memberKiosService from "@/services/memberKiosService.js";
import permissionsService from "@/services/permissionsService.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BModal,
    BBadge,
    BButton,
    BInputGroup,
    BFormGroup,
    BRow,
    BCol,
    BTable,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BButtonGroup,
    BFormCheckboxGroup,
    BCardText,
    BFormSelect,
    BNav,
    BNavItem,
    BFormRadio,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    customerData: {},
    memberUid: "",
    firstname: "",
    lastname: "",
  },

  data() {
    return {
      activetab: "1",
      filterMemberSelect: "",
      cusId: "",
      m_uid: "",
      formType: "in", // 'in' for IN, 'out' for OUT
      clickedDepartmentName: "",
      selectedDepartment: "",
      modalItemId: null,

      selectedItems: [],

      // เพิ่มข้อมูลอื่น ๆ ตามความต้องการ
      options2_in: [{ item2: "chk_temp", name: this.$t("Temp measure1") }],
      options_in: [
        { item: "chk_alcohol", name: this.$t("Alcohol Breat") },
        { item: "chk_bp", name: this.$t("Measure blood pressure") },
      ],
      selected_in: [],

      selected2_in: [],

      options2_out: [{ item2: "chk_temp", name: this.$t("Temp measure1") }],
      options_out: [
        { item: "chk_alcohol", name: this.$t("Alcohol Breat") },
        { item: "chk_bp", name: this.$t("Measure blood pressure") },
      ],
      selected_out: [],

      selected2_out: [],
      showRadio: false, // แสดง <b-form-radio> เริ่มต้น
      showSelect: true,
      form_chk_in: {
        chk_hand: 0,
        chk_quiz: 0,
        chk_foot: 0,
        chk_blind: 0,
        chk_hand_m_d: 0,
        chk_hand_w_d: 0,
        chk_foot_w_d: 0,
        chk_foot_m_d: 0,
        chk_blind_w_d: 0,
        chk_blind_m_d: 0,
        chk_quiz_w_d: 0,
        chk_quiz_m_d: 0,
      },
      form_chk_out: {
        chk_hand: 0,
        chk_quiz: 0,
        chk_foot: 0,
        chk_blind: 0,
        chk_hand_m_d: 0,
        chk_hand_w_d: 0,
        chk_foot_w_d: 0,
        chk_foot_m_d: 0,
        chk_blind_w_d: 0,
        chk_blind_m_d: 0,
        chk_quiz_w_d: 0,
        chk_quiz_m_d: 0,
      },
      dayOptions: [
        { value: "0", text: '-- '+this.$t('Select day week')+' --' },
        { value: "1", text: this.$t('Monday') },
        { value: "2", text: this.$t('Tuesday') },
        { value: "3", text: this.$t('Wednesday') },
        { value: "4", text: this.$t('Thursday') },
        { value: "5", text: this.$t('Friday') },
        { value: "6", text: this.$t('Saturday') },
        { value: "7", text: this.$t('Sunday') },
      ],
      monthOptions: [
        { value: "0", text: '-- '+this.$t('Select day month')+' --' },
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4", text: "4" },
        { value: "5", text: "5" },
        { value: "6", text: "6" },
        { value: "7", text: "7" },
        { value: "8", text: "8" },
        { value: "9", text: "9" },
        { value: "10", text: "10" },
        { value: "11", text: "11" },
        { value: "12", text: "12" },
        { value: "13", text: "13" },
        { value: "14", text: "14" },
        { value: "15", text: "15" },
        { value: "16", text: "16" },
        { value: "17", text: "17" },
        { value: "18", text: "18" },
        { value: "19", text: "19" },
        { value: "20", text: "20" },
        { value: "21", text: "21" },
        { value: "22", text: "22" },
        { value: "23", text: "23" },
        { value: "24", text: "24" },
        { value: "25", text: "25" },
        { value: "26", text: "26" },
        { value: "27", text: "27" },
        { value: "28", text: "28" },
        { value: "29", text: "29" },
        { value: "30", text: "30" },
        { value: "31", text: "31" },
      ],
      //   form_chk_in: {chk_hand_d:0,
      // chk_hand_w: 0,
      // chk_hand_m: 0,
      // chk_foot_d: 0,
      // chk_foot_w: 0,
      // chk_foot_m: 0,
      // chk_blind_d: 0,
      // chk_blind_w: 0,
      // chk_blind_m: 0,
      // chk_quiz_d: 0,
      // chk_quiz_w: 0,
      // chk_quiz_m: 0},
      // form_chk_out: {chk_hand_d:0,
      // chk_hand_w: 0,
      // chk_hand_m: 0,
      // chk_foot_d: 0,
      // chk_foot_w: 0,
      // chk_foot_m: 0,
      // chk_blind_d: 0,
      // chk_blind_w: 0,
      // chk_blind_m: 0,
      // chk_quiz_d: 0,
      // chk_quiz_w: 0,
      // chk_quiz_m: 0},

      cusCode: "",
      formData: [],
      bigdata: [],
      m_uid: "",

      DataChk: [],
    };
  },
  setup() {},

  methods: {
    setActiveTab(tab) {
      this.activetab = tab;
    },
    //     deselectEverything(fieldName) {

    //       if (fieldName === 'form_chk_in.chk_hand') {
    //     this.form_chk_in.chk_hand = '';
    //   } else if (fieldName === 'form_chk_in.chk_foot') {
    //     this.form_chk_in.chk_foot = '';
    //   } else if (fieldName === 'form_chk_in.chk_blind') {
    //     this.form_chk_in.chk_blind = '';
    //   } else if (fieldName === 'form_chk_in.chk_quiz') {
    //     this.form_chk_in.chk_quiz = '';
    //   } else if (fieldName === 'form_chk_out.chk_hand') {
    //     this.form_chk_out.chk_hand = '';
    //   } else if (fieldName === 'form_chk_out.chk_foot') {
    //     this.form_chk_out.chk_foot = '';
    //   } else if (fieldName === 'form_chk_out.chk_blind') {
    //     this.form_chk_out.chk_blind = '';
    //   } else if (fieldName === 'form_chk_out.chk_quiz') {
    //     this.form_chk_out.chk_quiz = '';
    //    }

    // },
    remove(fieldName) {
      if (fieldName === "form_chk_in.chk_hand") {
        this.form_chk_in.chk_hand = 0;
        this.form_chk_in.chk_hand_m_d = 0;
        this.form_chk_in.chk_hand_w_d = 0;
      } else if (fieldName === "form_chk_in.chk_foot") {
        this.form_chk_in.chk_foot = 0;
        this.form_chk_in.chk_foot_w_d = 0;
        this.form_chk_in.chk_foot_m_d = 0;
      } else if (fieldName === "form_chk_in.chk_blind") {
        this.form_chk_in.chk_blind = 0;
        this.form_chk_in.chk_blind_w_d = 0;
        this.form_chk_in.chk_blind_m_d = 0;
      } else if (fieldName === "form_chk_in.chk_quiz") {
        this.form_chk_in.chk_quiz = 0;
        this.form_chk_in.chk_quiz_w_d = 0;
        this.form_chk_in.chk_quiz_m_d = 0;
      } else if (fieldName === "form_chk_out.chk_hand") {
        this.form_chk_out.chk_hand = 0;
        this.form_chk_out.chk_hand_m_d = 0;
        this.form_chk_out.chk_hand_w_d = 0;
      } else if (fieldName === "form_chk_out.chk_foot") {
        this.form_chk_out.chk_foot = 0;
        this.form_chk_out.chk_foot_w_d = 0;
        this.form_chk_out.chk_foot_m_d = 0;
      } else if (fieldName === "form_chk_out.chk_blind") {
        this.form_chk_out.chk_blind = 0;
        this.form_chk_out.chk_blind_w_d = 0;
        this.form_chk_out.chk_blind_m_d = 0;
      } else if (fieldName === "form_chk_out.chk_quiz") {
        this.form_chk_out.chk_quiz = 0;
        this.form_chk_out.chk_quiz_w_d = 0;
        this.form_chk_out.chk_quiz_m_d = 0;
      }
    },
    deselectEverything(fieldName) {
      if (fieldName === "form_chk_in.chk_hand") {
        this.form_chk_in.chk_hand = "d";
        this.form_chk_in.chk_hand_w_d = 0;
        this.form_chk_in.chk_hand_m_d = 0;
      } else if (fieldName === "form_chk_in.chk_hand_w_d") {
        this.form_chk_in.chk_hand_m_d = 0;
        this.form_chk_in.chk_hand = "w";
      } else if (fieldName === "form_chk_in.chk_hand_m_d") {
        this.form_chk_in.chk_hand_w_d = 0;
        this.form_chk_in.chk_hand = "m";
      } else if (fieldName === "form_chk_in.chk_foot") {
        this.form_chk_in.chk_foot = "d";
        this.form_chk_in.chk_foot_w_d = 0;
        this.form_chk_in.chk_foot_m_d = 0;
      } else if (fieldName === "form_chk_in.chk_foot_w_d") {
        this.form_chk_in.chk_foot_m_d = 0;
        this.form_chk_in.chk_foot = "w";
      } else if (fieldName === "form_chk_in.chk_foot_m_d") {
        this.form_chk_in.chk_foot_w_d = 0;
        this.form_chk_in.chk_foot = "m";
      } else if (fieldName === "form_chk_in.chk_blind") {
        this.form_chk_in.chk_blind = "d";
        this.form_chk_in.chk_blind_w_d = 0;
        this.form_chk_in.chk_blind_m_d = 0;
      } else if (fieldName === "form_chk_in.chk_blind_w_d") {
        this.form_chk_in.chk_blind_m_d = 0;
        this.form_chk_in.chk_blind = "w";
      } else if (fieldName === "form_chk_in.chk_blind_m_d") {
        this.form_chk_in.chk_blind_w_d = 0;
        this.form_chk_in.chk_blind = "m";
      } else if (fieldName === "form_chk_in.chk_quiz") {
        this.form_chk_in.chk_quiz = "d";
        this.form_chk_in.chk_quiz_w_d = 0;
        this.form_chk_in.chk_quiz_m_d = 0;
      } else if (fieldName === "form_chk_in.chk_quiz_w_d") {
        this.form_chk_in.chk_quiz_m_d = 0;
        this.form_chk_in.chk_quiz = "w";
      } else if (fieldName === "form_chk_in.chk_quiz_m_d") {
        this.form_chk_in.chk_quiz_w_d = 0;
        this.form_chk_in.chk_quiz = "m";
      } else if (fieldName === "form_chk_out.chk_hand") {
        this.form_chk_out.chk_hand = "d";
        this.form_chk_out.chk_hand_w_d = 0;
        this.form_chk_out.chk_hand_m_d = 0;
      } else if (fieldName === "form_chk_out.chk_hand_w_d") {
        this.form_chk_out.chk_hand_m_d = 0;
        this.form_chk_out.chk_hand = "w";
      } else if (fieldName === "form_chk_out.chk_hand_m_d") {
        this.form_chk_out.chk_hand_w_d = 0;
        this.form_chk_out.chk_hand = "m";
      } else if (fieldName === "form_chk_out.chk_foot") {
        this.form_chk_out.chk_foot = "d";
        this.form_chk_out.chk_foot_w_d = 0;
        this.form_chk_out.chk_foot_m_d = 0;
      } else if (fieldName === "form_chk_out.chk_foot_w_d") {
        this.form_chk_out.chk_foot_m_d = 0;
        this.form_chk_out.chk_foot = "w";
      } else if (fieldName === "form_chk_out.chk_foot_m_d") {
        this.form_chk_out.chk_foot_w_d = 0;
        this.form_chk_out.chk_foot = "m";
      } else if (fieldName === "form_chk_out.chk_blind") {
        this.form_chk_out.chk_blind = "d";
        this.form_chk_out.chk_blind_w_d = 0;
        this.form_chk_out.chk_blind_m_d = 0;
      } else if (fieldName === "form_chk_out.chk_blind_w_d") {
        this.form_chk_out.chk_blind_m_d = 0;
        this.form_chk_out.chk_blind = "w";
      } else if (fieldName === "form_chk_out.chk_blind_m_d") {
        this.form_chk_out.chk_blind_w_d = 0;
        this.form_chk_out.chk_blind = "m";
      } else if (fieldName === "form_chk_out.chk_quiz") {
        this.form_chk_out.chk_quiz = "d";
        this.form_chk_out.chk_quiz_w_d = 0;
        this.form_chk_out.chk_quiz_m_d = 0;
      } else if (fieldName === "form_chk_out.chk_quiz_w_d") {
        this.form_chk_out.chk_quiz_m_d = 0;
        this.form_chk_out.chk_quiz = "w";
      } else if (fieldName === "form_chk_out.chk_quiz_m_d") {
        this.form_chk_out.chk_quiz_w_d = 0;
        this.form_chk_out.chk_quiz = "m";
      }
    },

    toggleForm(type) {
      this.formType = type;
      // ... other logic ...
    },
    filterMemberSelectChanged() {
      // ไม่ต้องกำหนดค่า this.cusCode และไม่ต้องเช็คค่าว่าง

      // ดึง id ของ name ที่เลือก
      const selectedItemId = this.filterMemberSelect;

      // หากมีการเลือก id
      if (selectedItemId) {
        // หา item ที่มี id ตรงกับที่เลือก
        const selectedItem = this.bigdata.find(
          (item) => item.id === selectedItemId
        );

        // ตรวจสอบว่าพบ item ที่มี id ตรงกับที่เลือก
        if (selectedItem) {
          // เรียกใช้ getdataKios() โดยส่ง dep_id, chk_type, และ name
          this.getdataKios(selectedItem.id, "", selectedItem.name);
        }
      } else {
        // ถ้าไม่มีการเลือก id ให้เรียก getdataKios() โดยส่งค่าเป็น null
        this.getdataKios(null, null, null);
      }
    },
    toggleForm(isInForm) {
      this.formType = isInForm ? "in" : "out";
      // const chk_type = isInForm ? 1 : 2;
      const chk_type = "";
      // ค่า id ที่ได้จากการเลือก name ใน filterMemberSelectChanged()
      const id = id;

      // เรียกใช้ getdataKios() ด้วย id และ chk_type
      this.getdataKios(id, chk_type);
    },
    resetModal() {
      this.nameState = null;
      this.selected_in = [];
      this.selected2_in = [];
      this.selected_out = [];
      this.selected_out = [];
      this.selected2_out = [];
      this.chk_hand = 0;
      this.chk_hand_w_d = 0;
      this.chk_hand_m_d = 0;
      this.chk_foot = 0;
      this.chk_foot_w_d = 0;
      this.chk_foot_m_d = 0;
      this.chk_blind = 0;
      this.chk_blind_w_d = 0;
      this.chk_blind_m_d = 0;
      this.chk_quiz = 0;
      this.chk_quiz_w_d = 0;
      this.chk_quiz_m_d = 0;

      this.form_chk_in = {
        chk_hand_w: 0,
        chk_hand_m: 0,
        chk_foot_d: 0,
        chk_foot_w: 0,
        chk_foot_m: 0,
        chk_blind_d: 0,
        chk_blind_w: 0,
        chk_blind_m: 0,
        chk_quiz_d: 0,
        chk_quiz_w: 0,
        chk_quiz_m: 0,
        chk_hand_m_d: 0,
        chk_hand_w_d: 0,
        chk_foot_w_d: 0,
        chk_foot_m_d: 0,
        chk_blind_w_d: 0,
        chk_blind_m_d: 0,
        chk_quiz_w_d: 0,
        chk_quiz_m_d: 0,
      },
        this.form_chk_out = {
          chk_hand_w: 0,
          chk_hand_m: 0,
          chk_foot_d: 0,
          chk_foot_w: 0,
          chk_foot_m: 0,
          chk_blind_d: 0,
          chk_blind_w: 0,
          chk_blind_m: 0,
          chk_quiz_d: 0,
          chk_quiz_w: 0,
          chk_quiz_m: 0,
          chk_hand_m_d: 0,
          chk_hand_w_d: 0,
          chk_foot_w_d: 0,
          chk_foot_m_d: 0,
          chk_blind_w_d: 0,
          chk_blind_m_d: 0,
          chk_quiz_w_d: 0,
          chk_quiz_m_d: 0,
        };
    },
    
    getbigdata() {
      try {
        departmentService
          .getDataBycusCode(this.cusCode)
          .then((response) => {
            this.bigdata = response.data.data;
            this.totalRows = this.bigdata.length;
            //console.log(this.bigdata);
            this.cusId = this.bigdata[0].cusId;
           // console.log(response);
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    getdataKios(id, chk_type, name) {
      //this.dep_id = dep_id;  // กำหนดค่า dep_id ในตัวแปร
      this.chk_type = ""; // กำหนดค่า chk_type ในตัวแปร

      try {
        depKiosService
          .getdataById(id, chk_type)
          .then((response) => {
            this.DataChk = response.data.data;
            this.clickedDepartmentName = name;
            console.log(this.DataChk[0]);
            // console.log(this.clickedDepartmentName);
            //this.selected = [];

            // if (this.checkin_type === 2) {

            this.selected_out = [];
            if (this.DataChk[0].chk_alcohol) {
              this.selected_out.push("chk_alcohol");
            }
            if (this.DataChk[0].chk_bp) {
              this.selected_out.push("chk_bp");
            }

            this.selected2_out = [];
            if (this.DataChk[0].chk_temp) {
              this.selected2_out.push("chk_temp");
            }
           
            if (this.DataChk[0].chk_hand_w === 1) {
              this.chk_hand_opt = this.DataChk[0].chk_hand_opt; // กำหนดค่า chk_hand_opt จากข้อมูล
              if (this.chk_hand_opt == 0) {
                this.chk_hand_opt = 1;
              }
              this.form_chk_out.chk_hand_w_d = this.chk_hand_opt; // นำค่า chk_hand_opt ไปใส่ใน chk_hand_w_d
            }
            if (this.DataChk[0].chk_hand_m === 1) {
              this.chk_hand_opt = this.DataChk[0].chk_hand_opt;
              if (this.chk_hand_opt == 0) {
                this.chk_hand_opt = 1;
              }
              this.form_chk_out.chk_hand_m_d = this.chk_hand_opt;
            }

            if (this.DataChk[0].chk_foot_w === 1) {
              this.chk_foot_opt = this.DataChk[0].chk_foot_opt;
              if (this.chk_foot_opt == 0) {
                this.chk_foot_opt = 1;
              }
              this.form_chk_out.chk_foot_w_d = this.chk_foot_opt;
            }
            if (this.DataChk[0].chk_foot_m === 1) {
              this.chk_foot_opt = this.DataChk[0].chk_foot_opt;
              if (this.chk_foot_opt == 0) {
                this.chk_foot_opt = 1;
              }
              this.form_chk_out.chk_foot_m_d = this.chk_foot_opt;
            }

            if (this.DataChk[0].chk_blind_w === 1) {
              this.chk_blind_opt = this.DataChk[0].chk_blind_opt;
              if (this.chk_blind_opt == 0) {
                this.chk_blind_opt = 1;
              }
              this.form_chk_out.chk_blind_w_d = this.chk_blind_opt;
            }
            if (this.DataChk[0].chk_blind_m === 1) {
              this.chk_blind_opt = this.DataChk[0].chk_blind_opt;
              if (this.chk_blind_opt == 0) {
                this.chk_blind_opt = 1;
              }
              this.form_chk_out.chk_blind_m_d = this.chk_blind_opt;
            }

            if (this.DataChk[0].chk_quiz_w === 1) {
              this.chk_quiz_opt = this.DataChk[0].chk_quiz_opt;
              if (this.chk_quiz_opt == 0) {
                this.chk_quiz_opt = 1;
              }
              this.form_chk_out.chk_quiz_w_d = this.chk_quiz_opt;
            }
            if (this.DataChk[0].chk_quiz_m === 1) {
              this.chk_quiz_opt = this.DataChk[0].chk_quiz_opt;
              if (this.chk_quiz_opt == 0) {
                this.chk_quiz_opt = 1;
              }
              this.form_chk_out.chk_quiz_m_d = this.chk_quiz_opt;
            }

             //           let chkValues_out = {
            //             chk_hand: this.DataChk[0].chk_hand_d === 1 ? 'd' : this.DataChk[0].chk_hand_w === 1 ? 'w' : this.DataChk[0].chk_hand_m === 1 ? 'm' : 0,
            //       chk_foot: this.DataChk[0].chk_foot_d === 1 ? 'd' : this.DataChk[0].chk_foot_w === 1 ? 'w' : this.DataChk[0].chk_foot_m === 1 ? 'm' : 0,
            //       chk_blind: this.DataChk[0].chk_blind_d === 1 ? 'd' : this.DataChk[0].chk_blind_w === 1 ? 'w' : this.DataChk[0].chk_blind_m === 1 ? 'm' : 0,
            //       chk_quiz: this.DataChk[0].chk_quiz_d === 1 ? 'd' : this.DataChk[0].chk_quiz_w === 1 ? 'w' : this.DataChk[0].chk_quiz_m === 1 ? 'm' : 0,

            // };
            let chkValues_out = {
              chk_hand: this.DataChk[0].chk_hand_d === 1 ? 'd' : this.DataChk[0].chk_hand_w === 1 ? 'w' : this.DataChk[0].chk_hand_m === 1 ? 'm' : 0,
              chk_foot: this.DataChk[0].chk_foot_d === 1 ? 'd' : this.DataChk[0].chk_foot_w === 1 ? 'w' : this.DataChk[0].chk_foot_m === 1 ? 'm' : 0,
              chk_blind: this.DataChk[0].chk_blind_d === 1 ? 'd' : this.DataChk[0].chk_blind_w === 1 ? 'w' : this.DataChk[0].chk_blind_m === 1 ? 'm' : 0,
              chk_quiz: this.DataChk[0].chk_quiz_d === 1 ? 'd' : this.DataChk[0].chk_quiz_w === 1 ? 'w' : this.DataChk[0].chk_quiz_m === 1 ? 'm' : 0,
              chk_hand_opt: this.DataChk[0].chk_hand_opt, 
              chk_foot_opt: this.DataChk[0].chk_foot_opt, 
              chk_blind_opt: this.DataChk[0].chk_blind_opt, 
              chk_quiz_opt: this.DataChk[0].chk_quiz_opt, 
            };
           console.log(chkValues_out);
            this.form_chk_out.chk_hand = chkValues_out.chk_hand;
            this.form_chk_out.chk_foot = chkValues_out.chk_foot;
            this.form_chk_out.chk_blind = chkValues_out.chk_blind;
            this.form_chk_out.chk_quiz = chkValues_out.chk_quiz;
            //console.log(chkValues_out);
            // this.form_chk_out = {
            //   chk_hand_d: !!this.DataChk[0].chk_hand_d,
            //   chk_hand_m: !!this.DataChk[0].chk_hand_m,
            //   chk_hand_w: !!this.DataChk[0].chk_hand_w,
            //   chk_foot_d: !!this.DataChk[0].chk_foot_d,
            //   chk_foot_w: !!this.DataChk[0].chk_foot_w,
            //   chk_foot_m: !!this.DataChk[0].chk_foot_m,
            //   chk_blind_d: !!this.DataChk[0].chk_blind_d,
            //   chk_blind_w: !!this.DataChk[0].chk_blind_w,
            //   chk_blind_m: !!this.DataChk[0].chk_blind_m,
            //   chk_quiz_d: !!this.DataChk[0].chk_quiz_d,
            //   chk_quiz_w: !!this.DataChk[0].chk_quiz_w,
            //   chk_quiz_m: !!this.DataChk[0].chk_quiz_m,
            // };
            //console.log(this.form_chk);

            this.selected_in = [];
            if (this.DataChk[1].chk_alcohol) {
              this.selected_in.push("chk_alcohol");
            }
            if (this.DataChk[1].chk_bp) {
              this.selected_in.push("chk_bp");
            }

            this.selected2_in = [];
            if (this.DataChk[1].chk_temp) {
              this.selected2_in.push("chk_temp");
            }
            if (this.DataChk[1].chk_hand_w === 1) {
              this.chk_hand_opt = this.DataChk[1].chk_hand_opt; // กำหนดค่า chk_hand_opt จากข้อมูล
              if (this.chk_hand_opt == 0) {
                this.chk_hand_opt = 1;
              }
              this.form_chk_in.chk_hand_w_d = this.chk_hand_opt; // นำค่า chk_hand_opt ไปใส่ใน chk_hand_w_d
            }
            if (this.DataChk[1].chk_hand_m === 1) {
              this.chk_hand_opt = this.DataChk[1].chk_hand_opt;
              if (this.chk_hand_opt == 0) {
                this.chk_hand_opt = 1;
              }
              this.form_chk_in.chk_hand_m_d = this.chk_hand_opt;
            }

            if (this.DataChk[1].chk_foot_w === 1) {
              this.chk_foot_opt = this.DataChk[1].chk_foot_opt;
              if (this.chk_foot_opt == 0) {
                this.chk_foot_opt = 1;
              }
              this.form_chk_in.chk_foot_w_d = this.chk_foot_opt;
            }
            if (this.DataChk[1].chk_foot_m === 1) {
              this.chk_foot_opt = this.DataChk[1].chk_foot_opt;
              if (this.chk_foot_opt == 0) {
                this.chk_foot_opt = 1;
              }
              this.form_chk_in.chk_foot_m_d = this.chk_foot_opt;
            }

            if (this.DataChk[1].chk_blind_w === 1) {
              this.chk_blind_opt = this.DataChk[1].chk_blind_opt;
              if (this.chk_blind_opt == 0) {
                this.chk_blind_opt = 1;
              }
              this.form_chk_in.chk_blind_w_d = this.chk_blind_opt;
            }
            if (this.DataChk[1].chk_blind_m === 1) {
              this.chk_blind_opt = this.DataChk[1].chk_blind_opt;
              if (this.chk_blind_opt == 0) {
                this.chk_blind_opt = 1;
              }
              this.form_chk_in.chk_blind_m_d = this.chk_blind_opt;
            }

            if (this.DataChk[1].chk_quiz_w === 1) {
              this.chk_quiz_opt = this.DataChk[1].chk_quiz_opt;
              if (this.chk_quiz_opt == 0) {
                this.chk_quiz_opt = 1;
              }
              this.form_chk_in.chk_quiz_w_d = this.chk_quiz_opt;
            }
            if (this.DataChk[1].chk_quiz_m === 1) {
              this.chk_quiz_opt = this.DataChk[1].chk_quiz_opt;
              if (this.chk_quiz_opt == 0) {
                this.chk_quiz_opt = 1;
              }
              this.form_chk_in.chk_quiz_m_d = this.chk_quiz_opt;
            }
           // console.log(this.chk_quiz_opt);

             //           let chkValues_out = {
            //             chk_hand: this.DataChk[0].chk_hand_d === 1 ? 'd' : this.DataChk[0].chk_hand_w === 1 ? 'w' : this.DataChk[0].chk_hand_m === 1 ? 'm' : 0,
            //       chk_foot: this.DataChk[0].chk_foot_d === 1 ? 'd' : this.DataChk[0].chk_foot_w === 1 ? 'w' : this.DataChk[0].chk_foot_m === 1 ? 'm' : 0,
            //       chk_blind: this.DataChk[0].chk_blind_d === 1 ? 'd' : this.DataChk[0].chk_blind_w === 1 ? 'w' : this.DataChk[0].chk_blind_m === 1 ? 'm' : 0,
            //       chk_quiz: this.DataChk[0].chk_quiz_d === 1 ? 'd' : this.DataChk[0].chk_quiz_w === 1 ? 'w' : this.DataChk[0].chk_quiz_m === 1 ? 'm' : 0,

            // };
            let chkValues = {
              chk_hand: this.DataChk[0].chk_hand_d === 1 ? 'd' : this.DataChk[0].chk_hand_w === 1 ? 'w' : this.DataChk[0].chk_hand_m === 1 ? 'm' : 0,
              chk_foot: this.DataChk[0].chk_foot_d === 1 ? 'd' : this.DataChk[0].chk_foot_w === 1 ? 'w' : this.DataChk[0].chk_foot_m === 1 ? 'm' : 0,
              chk_blind: this.DataChk[0].chk_blind_d === 1 ? 'd' : this.DataChk[0].chk_blind_w === 1 ? 'w' : this.DataChk[0].chk_blind_m === 1 ? 'm' : 0,
              chk_quiz: this.DataChk[0].chk_quiz_d === 1 ? 'd' : this.DataChk[0].chk_quiz_w === 1 ? 'w' : this.DataChk[0].chk_quiz_m === 1 ? 'm' : 0,
              chk_hand_opt: this.DataChk[0].chk_hand_opt, 
              chk_foot_opt: this.DataChk[0].chk_foot_opt, 
              chk_blind_opt: this.DataChk[0].chk_blind_opt, 
              chk_quiz_opt: this.DataChk[0].chk_quiz_opt, 
            };
            this.form_chk_in.chk_hand = chkValues.chk_hand;
            this.form_chk_in.chk_foot = chkValues.chk_foot;
            this.form_chk_in.chk_blind = chkValues.chk_blind;
            this.form_chk_in.chk_quiz = chkValues.chk_quiz;
           // console.log(this.form_chk_in.chk_hand);
            // let chkValues = {
            //   chk_hand:
            //     this.DataChk[1].chk_hand_d === 1
            //       ? "d"
            //       : this.DataChk[1].chk_hand_w === 1
            //       ? "w"
            //       : this.DataChk[1].chk_hand_m === 1
            //       ? "m"
            //       : 0,
            //   chk_foot:
            //     this.DataChk[1].chk_foot_d === 1
            //       ? "d"
            //       : this.DataChk[1].chk_foot_w === 1
            //       ? "w"
            //       : this.DataChk[1].chk_foot_m === 1
            //       ? "m"
            //       : 0,
            //   chk_blind:
            //     this.DataChk[1].chk_blind_d === 1
            //       ? "d"
            //       : this.DataChk[1].chk_blind_w === 1
            //       ? "w"
            //       : this.DataChk[1].chk_blind_m === 1
            //       ? "m"
            //       : 0,
            //   chk_quiz:
            //     this.DataChk[1].chk_quiz_d === 1
            //       ? "d"
            //       : this.DataChk[1].chk_quiz_w === 1
            //       ? "w"
            //       : this.DataChk[1].chk_quiz_m === 1
            //       ? "m"
            //       : 0,
            // };
            // this.form_chk_in.chk_hand = chkValues.chk_hand;
            // this.form_chk_in.chk_foot = chkValues.chk_foot;
            // this.form_chk_in.chk_blind = chkValues.chk_blind;
            // this.form_chk_in.chk_quiz = chkValues.chk_quiz;
            // console.log(this.form_chk_in.chk_hand);

            //  this.form_chk_in = {
            //    chk_hand_d: !!this.DataChk[1].chk_hand_d,
            //    chk_hand_m: !!this.DataChk[1].chk_hand_m,
            //    chk_hand_w: !!this.DataChk[1].chk_hand_w,
            //    chk_foot_d: !!this.DataChk[1].chk_foot_d,
            //    chk_foot_w: !!this.DataChk[1].chk_foot_w,
            //    chk_foot_m: !!this.DataChk[1].chk_foot_m,
            //    chk_blind_d: !!this.DataChk[1].chk_blind_d,
            //    chk_blind_w: !!this.DataChk[1].chk_blind_w,
            //    chk_blind_m: !!this.DataChk[1].chk_blind_m,
            //    chk_quiz_d: !!this.DataChk[1].chk_quiz_d,
            //    chk_quiz_w: !!this.DataChk[1].chk_quiz_w,
            //    chk_quiz_m: !!this.DataChk[1].chk_quiz_m,
            //  };
            //console.log(this.form_chk);

            if (this.checkin_type === 1) {
              // เมื่อเป็น in
              this.$nextTick(() => {
                this.$refs["my-modal-lg"].toggle("#toggle-btn");
                this.resetModal();
              });
            } else if (this.chk_type === 2) {
              // เมื่อเป็น out
              this.$refs["my-modal-lg"].toggle("#toggle-btn");
              this.resetModal();
            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    handleUpdateField(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      
      this.form_chk_in.chk_hand_d = 0;
      this.form_chk_in.chk_hand_w = 0;
      this.form_chk_in.chk_hand_m = 0;
      this.form_chk_in.chk_hand_opt = 0;
      if (this.form_chk_in.chk_hand != 0) {
        if (this.chk_hand == 'd') {
          this.form_chk_in.chk_hand_d = 1;
        } else if (this.form_chk_in.chk_hand == 'w') {
          this.form_chk_in.chk_hand_w = 1;
          this.form_chk_in.chk_hand_opt = this.form_chk_in.chk_hand_w_d;
        } else if (this.form_chk_in.chk_hand == 'm') {
          this.form_chk_in.chk_hand_m = 1;
          this.form_chk_in.chk_hand_opt = this.form_chk_in.chk_hand_m_d;
        }
      }

      this.form_chk_in.chk_foot_d = 0;
      this.form_chk_in.chk_foot_w = 0;
      this.form_chk_in.chk_foot_m = 0;
      this.form_chk_in.chk_foot_opt = 0;
      if (this.form_chk_in.chk_foot != 0) {
        if (this.form_chk_in.chk_foot == 'd') {
          this.form_chk_in.chk_foot_d = 1;
        } else if (this.form_chk_in.chk_foot == 'w') {
          this.form_chk_in.chk_foot_w = 1;
          this.form_chk_in.chk_foot_opt = this.form_chk_in.chk_foot_w_d;
        } else if (this.form_chk_in.chk_foot == 'm') {
          this.form_chk_in.chk_foot_m = 1;
          this.form_chk_in.chk_foot_opt = this.form_chk_in.chk_foot_m_d;
        }
      }

      this.form_chk_in.chk_blind_d = 0;
      this.form_chk_in.chk_blind_w = 0;
      this.form_chk_in.chk_blind_m = 0;
      this.form_chk_in.chk_blind_opt = 0;
      if (this.form_chk_in.chk_blind != 0) {
        if (this.form_chk_in.chk_blind == 'd') {
          this.form_chk_in.chk_blind_d = 1;
        } else if (this.form_chk_in.chk_blind == 'w') {
          this.form_chk_in.chk_blind_w = 1;
          this.form_chk_in.chk_blind_opt = this.form_chk_in.chk_blind_w_d;
        } else if (this.form_chk_in.chk_blind == 'm') {
          this.form_chk_in.chk_blind_m = 1;
          this.form_chk_in.chk_blind_opt = this.form_chk_in.chk_blind_m_d;
        }
      }

      this.form_chk_in.chk_quiz_d = 0;
      this.form_chk_in.chk_quiz_w = 0;
      this.form_chk_in.chk_quiz_m = 0;
      this.form_chk_in.chk_quiz_opt = 0;
      if (this.form_chk_in.chk_quiz != 0) {
        if (this.form_chk_in.chk_quiz == 'd') {
          this.form_chk_in.chk_quiz_d = 1;
        } else if (this.form_chk_in.chk_quiz == 'w') {
          this.form_chk_in.chk_quiz_w = 1;
          this.form_chk_in.chk_quiz_opt = this.form_chk_in.chk_quiz_w_d;
        } else if (this.form_chk_in.chk_quiz == 'm') {
          this.form_chk_in.chk_quiz_m = 1;
          this.form_chk_in.chk_quiz_opt = this.form_chk_in.chk_quiz_m_d;
        }
      }
      // if (this.form_chk_in.chk_hand === "d") {
      //   this.form_chk_in.chk_hand_d = 1;
      // } else if (this.form_chk_in.chk_hand === "w") {
      //   this.form_chk_in.chk_hand_w = 1;
      // } else if (this.form_chk_in.chk_hand === "m") {
      //   this.form_chk_in.chk_hand_m = 1;
      // }
      // if (this.form_chk_in.chk_foot === "d") {
      //   this.form_chk_in.chk_foot_d = "1";
      // } else if (this.form_chk_in.chk_foot === "w") {
      //   this.form_chk_in.chk_foot_w = "1";
      // } else if (this.form_chk_in.chk_foot === "m") {
      //   this.form_chk_in.chk_foot_m = "1";
      // }
      // if (this.form_chk_in.chk_blind === "d") {
      //   this.form_chk_in.chk_blind_d = "1";
      // } else if (this.form_chk_in.chk_blind === "w") {
      //   this.form_chk_in.chk_blind_w = "1";
      // } else if (this.form_chk_in.chk_blind === "m") {
      //   this.form_chk_in.chk_blind_m = "1";
      // }
      // if (this.form_chk_in.chk_quiz === "d") {
      //   this.form_chk_in.chk_quiz_d = 1;
      // } else if (this.form_chk_in.chk_quiz === "w") {
      //   this.form_chk_in.chk_quiz_w = 1;
      // } else if (this.form_chk_in.chk_quiz === "m") {
      //   this.form_chk_in.chk_quiz_m = 1;
      // }

      // if (this.form_chk_out.chk_hand === "d") {
      //   this.form_chk_out.chk_hand_d = 1;
      // } else if (this.form_chk_out.chk_hand === "w") {
      //   this.form_chk_out.chk_hand_w = 1;
      // } else if (this.form_chk_out.chk_hand === "m") {
      //   this.form_chk_out.chk_hand_m = 1;
      // }
      // if (this.form_chk_out.chk_foot === "d") {
      //   this.form_chk_out.chk_foot_d = "1";
      // } else if (this.form_chk_out.chk_foot === "w") {
      //   this.form_chk_out.chk_foot_w = "1";
      // } else if (this.form_chk_out.chk_foot === "m") {
      //   this.form_chk_out.chk_foot_m = "1";
      // }
      // if (this.form_chk_out.chk_blind === "d") {
      //   this.form_chk_out.chk_blind_d = "1";
      // } else if (this.form_chk_out.chk_blind === "w") {
      //   this.form_chk_out.chk_blind_w = "1";
      // } else if (this.form_chk_out.chk_blind === "m") {
      //   this.form_chk_out.chk_blind_m = "1";
      // }
      // if (this.form_chk_out.chk_quiz === "d") {
      //   this.form_chk_out.chk_quiz_d = 1;
      // } else if (this.form_chk_out.chk_quiz === "w") {
      //   this.form_chk_out.chk_quiz_w = 1;
      // } else if (this.form_chk_out.chk_quiz === "m") {
      //   this.form_chk_out.chk_quiz_m = 1;
      // }
      this.form_chk_out.chk_hand_d = 0;
      this.form_chk_out.chk_hand_w = 0;
      this.form_chk_out.chk_hand_m = 0;
      this.form_chk_out.chk_hand_opt = 0;
      if (this.form_chk_out.chk_hand != 0) {
        if (this.form_chk_out.chk_hand == 'd') {
          this.form_chk_out.chk_hand_d = 1;
        } else if (this.form_chk_out.chk_hand == 'w') {
          this.form_chk_out.chk_hand_w = 1;
          this.form_chk_out.chk_hand_opt = this.form_chk_out.chk_hand_w_d;
        } else if (this.form_chk_out.chk_hand == 'm') {
          this.form_chk_out.chk_hand_m = 1;
          this.form_chk_out.chk_hand_opt = this.form_chk_out.chk_hand_m_d;
        }
      }

      this.form_chk_out.chk_foot_d = 0;
      this.form_chk_out.chk_foot_w = 0;
      this.form_chk_out.chk_foot_m = 0;
      this.form_chk_out.chk_foot_opt = 0;
      if (this.form_chk_out.chk_foot != 0) {
        if (this.form_chk_out.chk_foot == 'd') {
          this.form_chk_out.chk_foot_d = 1;
        } else if (this.form_chk_out.chk_foot == 'w') {
          this.form_chk_out.chk_foot_w = 1;
          this.form_chk_out.chk_foot_opt = this.form_chk_out.chk_foot_w_d;
        } else if (this.form_chk_out.chk_foot == 'm') {
          this.form_chk_out.chk_foot_m = 1;
          this.form_chk_out.chk_foot_opt = this.form_chk_out.chk_foot_m_d;
        }
      }

      this.form_chk_out.chk_blind_d = 0;
      this.form_chk_out.chk_blind_w = 0;
      this.form_chk_out.chk_blind_m = 0;
      this.form_chk_out.chk_blind_opt = 0;
      if (this.form_chk_out.chk_blind != 0) {
        if (this.form_chk_out.chk_blind == 'd') {
          this.form_chk_out.chk_blind_d = 1;
        } else if (this.form_chk_out.chk_blind == 'w') {
          this.form_chk_out.chk_blind_w = 1;
          this.form_chk_out.chk_blind_opt = this.form_chk_out.chk_blind_w_d;
        } else if (this.form_chk_out.chk_blind == 'm') {
          this.form_chk_out.chk_blind_m = 1;
          this.form_chk_out.chk_blind_opt = this.form_chk_out.chk_blind_m_d;
        }
      }

      this.form_chk_out.chk_quiz_d = 0;
      this.form_chk_out.chk_quiz_w = 0;
      this.form_chk_out.chk_quiz_m = 0;
      this.form_chk_out.chk_quiz_opt = 0;
      if (this.form_chk_out.chk_quiz != 0) {
        if (this.form_chk_out.chk_quiz == 'd') {
          this.form_chk_out.chk_quiz_d = 1;
        } else if (this.form_chk_out.chk_quiz == 'w') {
          this.form_chk_out.chk_quiz_w = 1;
          this.form_chk_out.chk_quiz_opt = this.form_chk_out.chk_quiz_w_d;
        } else if (this.form_chk_out.chk_quiz == 'm') {
          this.form_chk_out.chk_quiz_m = 1;
          this.form_chk_out.chk_quiz_opt = this.form_chk_out.chk_quiz_m_d;
        }
      }
   // console.log( this.form_chk_out.chk_quiz_opt);

      const checkin = {
        chk_alcohol: this.form_chk_in.chk_alcohol ? 1 : 0,
        chk_bp: this.form_chk_in.chk_bp ? 1 : 0,
        chk_temp: this.form_chk_in.chk_temp ? 1 : 0,
        chk_hand_d: this.form_chk_in.chk_hand_d ? 1 : 0,
        chk_hand_w: this.form_chk_in.chk_hand_w ? 1 : 0,
        chk_hand_m: this.form_chk_in.chk_hand_m ? 1 : 0,
        chk_foot_d: this.form_chk_in.chk_foot_d ? 1 : 0,
        chk_foot_w: this.form_chk_in.chk_foot_w ? 1 : 0,
        chk_foot_m: this.form_chk_in.chk_foot_m ? 1 : 0,
        chk_blind_d: this.form_chk_in.chk_blind_d ? 1 : 0,
        chk_blind_w: this.form_chk_in.chk_blind_w ? 1 : 0,
        chk_blind_m: this.form_chk_in.chk_blind_m ? 1 : 0,
        chk_quiz_d: this.form_chk_in.chk_quiz_d ? 1 : 0,
        chk_quiz_w: this.form_chk_in.chk_quiz_w ? 1 : 0,
        chk_quiz_m: this.form_chk_in.chk_quiz_m ? 1 : 0,
        chk_hand_opt: this.form_chk_in.chk_hand_opt,
        chk_foot_opt: this.form_chk_in.chk_foot_opt,
  chk_blind_opt: this.form_chk_in.chk_blind_opt,
  chk_quiz_opt: this.form_chk_in.chk_quiz_opt,
        // chk_hand_opt:,
        // chk_foot_opt:,
        // chk_blind_opt:,
        // chk_quiz_opt:,
      };
      const checkout = {
        chk_alcohol: this.form_chk_out.chk_alcohol ? 1 : 0,
        chk_bp: this.form_chk_out.chk_bp ? 1 : 0,
        chk_temp: this.form_chk_out.chk_temp ? 1 : 0,
        chk_hand_d: this.form_chk_out.chk_hand_d ? 1 : 0,
        chk_hand_w: this.form_chk_out.chk_hand_w ? 1 : 0,
        chk_hand_m: this.form_chk_out.chk_hand_m ? 1 : 0,
        chk_foot_d: this.form_chk_out.chk_foot_d ? 1 : 0,
        chk_foot_w: this.form_chk_out.chk_foot_w ? 1 : 0,
        chk_foot_m: this.form_chk_out.chk_foot_m ? 1 : 0,
        chk_blind_d: this.form_chk_out.chk_blind_d ? 1 : 0,
        chk_blind_w: this.form_chk_out.chk_blind_w ? 1 : 0,
        chk_blind_m: this.form_chk_out.chk_blind_m ? 1 : 0,
        chk_quiz_d: this.form_chk_out.chk_quiz_d ? 1 : 0,
        chk_quiz_w: this.form_chk_out.chk_quiz_w ? 1 : 0,
        chk_quiz_m: this.form_chk_out.chk_quiz_m ? 1 : 0,
        chk_hand_opt: this.form_chk_out.chk_hand_opt,
  chk_foot_opt: this.form_chk_out.chk_foot_opt,
  chk_blind_opt: this.form_chk_out.chk_blind_opt,
  chk_quiz_opt: this.form_chk_out.chk_quiz_opt,
      //chk_hand_opt : this.form_chk_out.chk_hand_opt ? 1 : 0;
// chk_foot_opt  :  this.form_chk_out.chk_foot_opt ? 1 : 0;
// chk_blind_opt  :  this.form_chk_out.chk_blind_opt ? 1 : 0;
// chk_quiz_opt  :  this.form_chk_out.chk_quiz_opt ? 1 : 0;
      };
      // console.log(checkin);
      // console.log(checkout);
      // for (const key in this.form_chk_out) {
      //   if (this.form_chk_out.hasOwnProperty(key)) {
      //     checkout[key] = this.form_chk_out[key];
      //    // checkout[key] = 0; // Initialize checkout values to 0
      //   }
      // }
      // Set selected checkboxes to 1
  
      this.selected_in.forEach((selectedItem) => {
        checkin[selectedItem] = 1;
      });

      this.selected2_in.forEach((selectedItem) => {
        checkin[selectedItem] = 1;
      });
      this.selected_out.forEach((selectedItem) => {
        checkout[selectedItem] = 1;
      });

      this.selected2_out.forEach((selectedItem) => {
        checkout[selectedItem] = 1;
      });
      //console.log(checkin);
      if (this.selected_in.length === 0 && this.selected_out.length === 0) {
  this.$swal({
    type: "error",
    title: "เลือกการทดสอบ !!!",
    text: "กรุณาเลือกการทดสอบอย่างน้อย 1 อย่าง",
  });
  return;
}
    
      const formData = {
        cusId: this.cusId,
        m_uid: this.memberUid,
        checkin: checkin,
        checkout: checkout,
      };
      try {
        memberKiosService

          .insertData(formData)

          .then((response) => {
            console.log(formData);

            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "บันทึกข้อมูลไม่สำเร็จ",
                text: "Error! " + response.data.message,
                timer: 3000,
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "บันทึกข้อมูลสำเร็จ!",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });

              // เมื่อเป็น in
              this.$nextTick(() => {
                this.$refs["my-modal-lg"].toggle("#toggle-btn");
                this.resetModal();
                this.$emit("refetchPmData");
              });

              //
            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },

    showtoken() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      // console.log(decoded);
      if (decoded.role == "superadmin" || decoded.role == "admin") {
        this.cusCode = this.$route.params.id;
      } else {
        this.cusCode = decoded.cusCode;
      }
    },
    refetchData() {
      this.getbigdata();
      //this.getdataKios();
    },
  },
  async created() {
    await this.showtoken();
    await this.getbigdata();
    //await this.getdataKios();
  },
};
</script>

<style lang="scss">
.col-date-format {
  min-width: 110px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.button-style {
  display: inline-block;
  padding: 10px;
  border: 1px solid #ebe9f1; /* สีเส้นกรอบเส้นโค้ง (สีน้ำเงิน) */
  border-radius: 15px; /* ความโค้งของกรอบเส้นโค้ง */
  font-size: 24px; /* ขนาดตัวอักษร */
  text-align: center;
  color: #007bff; /* สีข้อความ (สีน้ำเงิน) */
}

.button-style-trans {
  display: flex;
  justify-content: center; /* กำหนดให้เนื้อหาอยู่กึ่งกลางแนวนอน */
  align-items: center;
  padding: 10px;
  border: 1px solid #ebe9f1;
  border-radius: 5px;
  font-size: 24px;
  width: auto; /* ปรับความกว้างเป็น auto */
  max-width: 100%; /* กำหนดความกว้างสูงสุดเป็น 100% */
  box-sizing: border-box; /* ป้องกันการเพิ่มขนาดขององค์ประกอบ */
}

.custom-card-text {
  border: 1px solid #ebe9f1; /* สีเส้นกรอบสีดำ */
  padding: 10px; /* ขอบการเพิ่มระยะระหว่างเนื้อหากับกรอบ */
  border-radius: 5px; /* ความโค้งของกรอบสีดำ */
  text-align: center;
}
.select-item {
  padding: 55px;
}

.center-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-table {
  background-color: transparent; /* กำหนดพื้นหลังโปร่งใส */
  border: none; /* ลบเส้นกรอบ */
}
table {
  width: 100%;
  border-collapse: collapse;
}
/* Zebra striping */
tr:nth-of-type(odd) {
  background: transparent;
}
th {
  background: transparent;

  font-weight: bold;
}
td,
th {
  padding: 6px;
  border: 1px solid transparent;
  text-align: left;
}

.chk-permis-dep th {
  text-align: center;
  padding-left: 10px; /* ระยะห่างทางซ้าย */
  padding-right: 10px; /* ระยะห่างทางขวา */
}

.chk-permis-dep td {
  text-align: center;
  padding-left: 10px; /* ระยะห่างทางซ้าย */
  padding-right: 10px; /* ระยะห่างทางขวา */
}

.chk-permis-dep td .custom-radio {
  padding: 0;
  padding-right: 2rem;
}

.demo-inline-spacing {
  justify-content: space-around;
}
.chk-permis-dep th:nth-child(3),
.chk-permis-dep td:nth-child(3) {
  padding-right: 0px; /* ระยะห่างทางซ้ายของ <th> และ <td> ในคอลัมน์ที่ 4 */
}
.chk-permis-dep th:nth-child(4),
.chk-permis-dep td:nth-child(4) {
  padding-left: 0px; /* ระยะห่างทางซ้ายของ <th> และ <td> ในคอลัมน์ที่ 4 */
}
.tab-permiss {
  display: contents;
}
#modal-lg-in .custom-select {
  text-align: center;
}
#modal-lg-out .custom-select {
  text-align: center;
}

.sel-grp-week .custom-select {
  max-width: 130px;
}
.sel-grp-month .custom-select {
  max-width: 120px;
}
.btn-md-cutom-in {
  padding: 0.8rem 1rem;
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}

.btn-md-cutom-out {
  padding: 0.8rem 1rem;
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.custom-control-chkin .custom-control-input:checked ~ .custom-control-label::before{
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}

.custom-control-chkout .custom-control-input:checked ~ .custom-control-label::before{
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.chk-permis-dep td .custom-control-label {
  position: relative !important;
 
  padding-right:3rem;

}
</style>
