<template>
  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              {{ $t("Detail") }}
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item to="/">
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                
                <b-breadcrumb-item v-if="role"
                  to="/customer"
                >
                {{ $t("Customer") }}
                </b-breadcrumb-item>
              
                 <b-breadcrumb-item
                  active
                >
                 {{customerData.cusName}}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  
    <b-card>
      <b-tabs pills>
        <b-tab active @click="changeMenu('general')">
          <template #title>
            <feather-icon icon="InfoIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">{{ $t("General") }}</span>
          </template>

          <CustomerGeneral
            v-if="menuTab.general"
            @changecusData="changecusData($event)"
          />
        </b-tab>

        <b-tab @click="changeMenu('branch')" v-if="roleAcc">
          <template #title>
            <feather-icon icon="WindIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">{{ $t("Branch") }}</span>
          </template>

          <CustomerBranch v-if="menuTab.branch" 
          :customerData="customerData"/>
        </b-tab>

        <b-tab @click="changeMenu('products')">
          <template #title>
            <feather-icon icon="SettingsIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">{{ $t("Products") }}</span>
          </template>

          <CustomerSetup v-if="menuTab.products" />
        </b-tab>

        <b-tab @click="changeMenu('members')">
          <template #title>
            <feather-icon icon="UserIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">{{ $t("Member") }}</span>
          </template>

          <CustomerMembers v-if="menuTab.members" />
        </b-tab>

        <b-tab @click="changeMenu('department')">
          <template #title>
            <feather-icon icon="PackageIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">{{ $t("Department") }}</span>
          </template>

          <CustomerDepartments
            v-if="menuTab.department"
            :customerData="customerData"
          />
        </b-tab>

        <!-- <b-tab @click="changeMenu('credits')">
          <template #title>
            <feather-icon icon="StopCircleIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">Credits</span>
          </template>

          <CustomerCredits v-if="menuTab.credits" :customerData="customerData" />
        </b-tab> -->

        <b-tab @click="changeMenu('users')" v-if="roleAcc">
          <template #title>
            <feather-icon icon="UsersIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">{{ $t("Users") }}</span>
          </template>

          <CustomerUsers v-if="menuTab.users" />
        </b-tab>

        <!-- <b-tab @click="changeMenu('scores')" v-if="roleAcc">
          <template #title>
            <feather-icon icon="BarChartIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">{{ $t("Scores") }}</span>
          </template>

          <CustomerScores v-if="menuTab.scores" :customerData="customerData" />
        </b-tab> -->

        <b-tab @click="changeMenu('quizs')" v-if="roleAcc">
          <template #title>
            <feather-icon icon="HelpCircleIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">{{ $t("Quizs") }}</span>
          </template>

          <CustomerQuizs v-if="menuTab.quizs" :customerData="customerData" />
        </b-tab>

        <b-tab @click="changeMenu('videos')" v-if="roleAcc">
          <template #title>
            <feather-icon icon="VideoIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">{{ $t("Videos") }}</span>
          </template>

          <CustomerVideos v-if="menuTab.videos" />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BButton,
  BRow,
  BCol,
  BBreadcrumb, 
  BBreadcrumbItem,
  BAvatar,
} from "bootstrap-vue";
import CustomerGeneral from "./general/CustomerGeneral.vue";
import CustomerBranch from "./branch/BranchList.vue";
import CustomerSetup from "./product-setup/Setup.vue";
import CustomerUsers from "./users/UsersList.vue";
import CustomerMembers from "./members/MemberList.vue";
import CustomerCredits from "./credits/CreditList.vue";
import CustomerDepartments from "./department/DepartmentList.vue";
import CustomerScores from "./scores/ScoresList.vue";
import CustomerQuizs from "./quizs/QuizsList.vue";
import CustomerVideos from "./videos/VideosList.vue";

import jwt_decode from "jwt-decode";

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBreadcrumb,
    BBreadcrumbItem,
    
    CustomerGeneral,
    CustomerBranch,
    CustomerSetup,
    CustomerUsers,
    CustomerUsers,
    CustomerMembers,
    CustomerDepartments,
    CustomerCredits,
    CustomerScores,
    CustomerQuizs,
    CustomerVideos,
  },
  data() {
    return {
      items: [
        {
          text: 'ลูกค้า',
          to: {name: 'customer'}
        },
      ],
      options: {},
      role: true,
      menuTab: {
        general: true,
        branch: false,
        products: false,
        members: false,
        department: false,

        users: false,
        credits: false,
        scores: false,
        quizs: false,
        videos: false,
      },
      customerData: {},
    };
  },
  methods: {
    changeMenu(value) {
      if (value == "general") {
        this.menuTab.general = true;
      }

      if (value == "branch") {
        this.menuTab.branch = true;
      }

      if (value == "products") {
        this.menuTab.products = true;
      }

      if (value == "members") {
        this.menuTab.members = true;
      }

      if (value == "department") {
        this.menuTab.department = true;
      }

      if (value == "users") {
        this.menuTab.users = true;
      }
      if (value == "credits") {
        this.menuTab.credits = true;
      }
      if (value == "scores") {
        this.menuTab.scores = true;
      }
      if (value == "quizs") {
        this.menuTab.quizs = true;
      }
      if (value == "videos") {
        this.menuTab.videos = true;
      }
    },
    changecusData(cusData) {
      this.customerData = cusData;
    },
    showtoken() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      // console.log(decoded.role);
      if (decoded.role == "superadmin" || decoded.role == "admin") {
        this.role = true;
      } else {
        this.role = false;
      }

      if (decoded.role == "manager" || decoded.role == "user") {
        this.roleAcc = false;
      } else {
        this.roleAcc = true;
      }


    },
  },
  async created() {
    await this.showtoken();
  },
};
</script>
