<template>
  <div>
    <SetupAddNew
      v-if="role"
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :notifyOptions="notifyOptions"
      :productOptions="productOptions"
      :branchSel="branchSel"
      @refetch-data="refetchData"
    />
    <SetupEdit
      v-if="role"
      :is-edit-sidebar-active.sync="isEditSidebarActive"
      :notifyOptions="notifyOptions"
      :productOptions="productOptions"
      :branchSel="branchSel"
      :formData="formData"
      @refetch-data="refetchData"
    />
    <b-card>
      
    <template v-if="role">
      <b-row>
        <b-col md="3" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Sort") }}</label>
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="6" class="my-1 d-none d-md-block"></b-col>

        <b-col md="6" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Filter") }}</label>
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('Search')"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  {{ $t("Clear") }}
                </b-button>

                <b-button
                  style="margin-left: 5px; border-radius: 0.357rem"
                  variant="primary"
                  @click="addOpen"
                >
                  <span class="text-nowrap">Add Setup</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            striped
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="bigdata"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
              <template #cell(show_details)="row">
                <b-form-checkbox
                  v-model="row.detailsShowing"
                  plain
                  class="vs-checkbox-con"
                  @change="row.toggleDetails"
                >
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <i class="vs-icon feather icon-check" />
                    </span>
                  </span>
                  <span class="vs-label">{{
                    row.detailsShowing ? "Hide" : "Show"
                  }}</span>
                </b-form-checkbox>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col md="3" class="mb-1">
                      <strong>DATE START : </strong>{{ row.item.setDate }}
                    </b-col>
                    <b-col md="3" class="mb-1">
                      <strong>Date Warranty : </strong>{{ row.item.setWarranty }}
                    </b-col>
                    <b-col md="3" class="mb-1">
                      <strong>Line Token : </strong>
                      <span v-if="row.item.lineToken == ''"></span>
                      <span v-else>{{ row.item.lineToken }}</span>
                    </b-col>
                    <b-col md="3" class="mb-1">
                      <strong>Notify Status. : </strong>
                      <b-badge v-if="row.item.lineNotify == '1'" variant="success"
                        >Yes</b-badge
                      >
                      <b-badge v-else variant="danger">No</b-badge>
                    </b-col>
                    <b-col md="12" class="mb-1" v-if="role">
                      <b-button
                        size="sm"
                        variant="outline-warning"
                        @click="editOpen(row.item.setId)"
                      >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">{{ $t("Edit") }}</span> </b-button
                      >&nbsp;
                      <b-button
                        size="sm"
                        variant="outline-danger"
                        @click="deleteData(row.item.setId)"
                      >
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">{{ $t("Delete") }}</span>
                      </b-button>
                    </b-col>
                  </b-row>

                  <b-button
                    size="sm"
                    variant="outline-secondary"
                    @click="row.toggleDetails"
                  >
                    Hide Details
                  </b-button>
                </b-card>
              </template>

              <template #cell(setDate)="row">
                <div v-if="row.item.setDate == ''"></div>
                <div v-else>{{ row.item.setDate }}</div>
              </template>

              <template #cell(setWarranty)="row">
                <div v-if="row.item.setWarranty == ''"></div>
                <div v-else>{{ row.item.setWarranty }}</div>
              </template>

              <template #cell(lineToken)="row">
                <div v-if="row.item.lineToken == ''"></div>
                <div v-else>{{ row.item.lineToken }}</div>
              </template>

              <template #cell(notiCheckin)="row">
                <div v-if="row.item.notiCheckin == '1'">
                  <b-badge variant="success">Yes</b-badge>
                </div>
                <div v-else><b-badge variant="danger">No</b-badge></div>
              </template>

              <template #cell(notiAlert)="row">
                <div v-if="row.item.notiAlert == '1'">
                  <b-badge variant="success">Yes</b-badge>
                </div>
                <div v-else><b-badge variant="danger">No</b-badge></div>
              </template>

              <template #cell(actions)="row" v-if="role">
                <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item @click="editOpen(row.item.setId)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ $t("Edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="deleteData(row.item.setId)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{ $t("Delete") }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-col>

          <b-col md="2" sm="4" class="my-1">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">{{ $t("Per page") }}</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />

              <div class="show-entries" v-if="totalRows == 0">
                <label class="text-muted">{{ $t("Showing") }} 0 {{ $t("To") }}  0 {{ $t("Of") }}  0 {{ $t("Entries") }} </label>
              </div>
              <div class="show-entries" v-else>
                <div v-if="currentPage * perPage > totalRows">
                  <label class="text-muted"
                    >{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                    {{ currentPage * perPage }} {{ $t("Of") }} {{ totalRows }} {{ $t("Entries") }}</label
                  >
                </div>
                <div v-else>
                  <label class="text-muted"
                    >{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                    {{ currentPage * perPage }} {{ $t("Of") }} {{ totalRows }} {{ $t("Entries") }}</label
                  >
                </div>
              </div>
            </b-form-group>
          </b-col>

          <b-col md="10" sm="8">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </template>

      <template v-else>
        <b-row  v-for="item in bigdata" :key="item.id">
          <b-col md="8">
            <b-card no-body class="business-card">
              <div class="business-items">
                <div class="business-item">
                  <div class="d-flex align-items-left justify-content-between">
                    <div>
                      {{ $t('Name') }} : <b>{{ item.setName }}</b>
                     
                      | {{ $t('Serial') }} : <b>{{ item.proSerial }}</b>
                      <feather-icon
                        :id="'pro_report_'+item.setId"
                        icon="EyeIcon"
                        size="18"
                        class="ml-50 mr-50"
                        v-b-modal.modal-prevent-closing
                        @click="
                          $router.push({
                            name: 'report',
                            query: {
                              date_st: date_st,
                              date_end: date_end,
                              proset_q: item.proSerial,
                            },
                          })
                        "
                      />
                      <b-tooltip :target="'pro_report_'+item.setId">{{ $t("View Kiosk") }}</b-tooltip>
                    </div>
                    <div>
                      <template v-if="item.notiCheckin == '1'">
                        <b-badge :id="'img_chkin_'+item.setId" pill class="mr-50 pr-50" style="background-color: #39cd00 !important;">
                          <b-img  class="d-inline-block" height="20" :src="require('@/assets/images/line_logo.png')"/>
                          Checkin &nbsp;
                        </b-badge>
                        <b-tooltip :target="'img_chkin_'+item.setId">{{ $t("Notify summary") }}</b-tooltip>
                      </template>

                      <template v-if="item.notiAlert == '1'">
                        <b-badge :id="'img_alert_'+item.setId" pill class="mr-50 pr-50" style="background-color: #39cd00 !important;">
                          <b-img  class="d-inline-block" height="20" :src="require('@/assets/images/line_logo.png')"/>
                          Alert &nbsp;
                        </b-badge>
                        <b-tooltip :target="'img_alert_'+item.setId">{{ $t('Notify summary') }}</b-tooltip>
                      </template>
                     
                      <!-- <b-form-checkbox
                        :checked="item.notiCheckin == '0' ? false : true"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                        @change="switchStatus(item.setId, 'checkin', $event)"
                      >
                        Line Notify Checkin
                      </b-form-checkbox>

                      <b-form-checkbox
                        :checked="item.notiAlert == '0' ? false : true"
                        class="custom-control-success"
                        name="check-button"
                        switch
                        inline
                        @change="switchStatus(item.setId, 'alert', $event)"
                      >
                        Line Notify Alert
                      </b-form-checkbox> -->
                      <feather-icon
                        icon="EditIcon"
                        size="18"
                        class="ml-50 mr-50"
                        v-b-modal.modal-prevent-closing
                        @click="editField(item)"
                      />
                      <!-- <feather-icon
                        icon="BellIcon"
                        size="18"
                        :class="item.notiCustom == 1 ? 'text-success' : ''"
                      /> -->

                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </template>
      <b-col md="10" v-if="totalRows == 0">
        ---------- <b>{{ $t('Data not found') }}เครื่อง กรุณาติดต่อแอดมิน</b> --------
      </b-col>
    </b-card>

    <!-- modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      :ok-title="$t('Save')"
      :cancel-title="$t('Cancel')"
      cancel-variant="outline-secondary"
      @hidden="resetModal"
      @ok="handleUpdateField"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          :state="nameState"
          :label="$t('Name')"
          label-for="name-input"
          invalid-feedback="Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="formEditField.setName"
            :state="nameState"
            required
          />
        </b-form-group>

        <b-form-group
          :label="$t('Notify all')"
          label-for="Line Notify Customer"
        >
          <b-form-checkbox
            v-model="swNotiCheckin"
            checked="true"
            class="custom-control-success mb-25"
            name="check-button"
            switch
            @change="toggleNotiCheckin"
          >
            <span class="switch-icon-left">
              <feather-icon icon="BellIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="BellOffIcon" />
            </span>
          </b-form-checkbox>

          <!-- <b-form-input
            v-if="dpNotiCustom"
            id="lineTokenCustom"
            v-model="formEditField.lineTokenCustom"
            autofocus
            trim
            placeholder="Token"
          /> -->
          <template  v-if="dpNotiCheckin">
            <template  v-if="formEditField.lineTokenCheckin == ''">
              <b-overlay
                :show="connProcess"
                class="d-inline-block"
              >
                <vue-qr
                  :text="getLinkLineConn(formEditField.proSerial,'notiCheckin')"
                  :margin="0"
                  :logoMargin="1"
                  :logoSrc="require('@/assets/images/line_logo.png')"
                  :size="180"
                  class="qr-code"
                ></vue-qr>
              </b-overlay>
              <span>&nbsp; -- {{ $t("Or") }} -- &nbsp;</span>
              <b-button
                variant="success"
                target="_blank"
                :href="getLinkLineConn(formEditField.proSerial,'notiCheckin')"
              >
                <b-img class="mr-50 d-inline-block" height="20" :src="require('@/assets/images/line_logo.png')"/>
                <span class="align-middle"> 
                  <span v-if="connProcess">{{ $t("Connecting") }}... <b-spinner  small></b-spinner></span>
                  <span v-else>{{ $t("Connect") }}</span>
                </span>
              </b-button>
            </template>
            <template  v-else>
              <b-button
                variant="success"
                disabled
              >
                <b-img class="mr-50 d-inline-block" height="20" :src="require('@/assets/images/line_logo.png')"/>
                <span class="align-middle">{{ $t("Connected") }} </span><feather-icon icon="CheckIcon"  style="vertical-align: middle;"/>
              </b-button>
            </template>
          </template>
        </b-form-group>
        <hr>
        <b-form-group
          :label="$t('Notify summary')"
          label-for="Line Notify Customer"
        >

          <b-form-checkbox
            v-model="swNotiAlert"
            checked="true"
            class="custom-control-success mb-25"
            name="check-button"
            switch
            @change="toggleNotiAlert"
          >
            <span class="switch-icon-left">
              <feather-icon icon="BellIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="BellOffIcon" />
            </span>
          </b-form-checkbox>

          <template  v-if="dpNotiAlert">
            <template  v-if="formEditField.lineTokenAlert == ''">
              <b-overlay
                :show="connProcess"
                class="d-inline-block"
              >
                <vue-qr
                  :text="getLinkLineConn(formEditField.proSerial,'notiAlert')"
                  :margin="0"
                  :logoMargin="1"
                  :logoSrc="require('@/assets/images/line_logo.png')"
                  :size="180"
                  class="qr-code"
                ></vue-qr>
              </b-overlay>
              <span>&nbsp; -- {{ $t("Or") }} -- &nbsp;</span>
              <b-button
                variant="success"
                target="_blank"
                :href="getLinkLineConn(formEditField.proSerial,'notiAlert')"
              >
                <b-img class="mr-50 d-inline-block" height="20" :src="require('@/assets/images/line_logo.png')"/>
                <span class="align-middle"> 
                  <span v-if="connProcess">{{ $t("Connecting") }}... <b-spinner  small></b-spinner></span>
                  <span v-else>{{ $t("Connect") }}</span>
                </span>
              </b-button>
            </template>
            <template  v-else>
              <b-button
                variant="success"
                disabled
              >
                <b-img class="mr-50 d-inline-block" height="20" :src="require('@/assets/images/line_logo.png')"/>
                <span class="align-middle">{{ $t("Connected") }} </span><feather-icon icon="CheckIcon"  style="vertical-align: middle;"/>
              </b-button>
            </template>
          </template>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BCardHeader,
  BCardText,
  BLink,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BImg,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BTooltip,
  BSpinner,
  BOverlay
} from "bootstrap-vue";
import jwt_decode from "jwt-decode";
import setupService from "@/services/setupService.js";
import productService from "@/services/productService.js";
import branchService from "@/services/branchService.js";
import helperService from "@/services/helperService.js";
import SetupAddNew from "./SetupAddNew.vue";
import SetupEdit from "./SetupEdit.vue";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import dateFormat from "dateformat";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueQr from "vue-qr";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BLink,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BImg,
    BDropdown,
    BDropdownItem,
    BModal,
    SetupAddNew,
    SetupEdit,
    VueQr,
    BTooltip,
    BSpinner,
    BOverlay
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup() {
    const isAddNewSidebarActive = ref(false);
    const isEditSidebarActive = ref(false);

    const notifyOptions = [
      { label: "No", value: 0 },
      { label: "Yes", value: 1 },
    ];

    return {
      // Sidebar
      isAddNewSidebarActive,
      isEditSidebarActive,

      notifyOptions,
    };
  },
  data() {
    return {
      date_st: dateFormat(new Date(), "yyyy-mm-dd"),
      date_end: dateFormat(new Date(), "yyyy-mm-dd"),
      cusCode: "",
      perPage: 20,
      pageOptions: [20, 30, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      role: true,
      fields: [
        {
          key: "setName",
          label: "Name",
          sortable: true,
          class: "text-left",
        },
        {
          key: "proSerial",
          label: "Seriel",
          sortable: true,
          class: "text-left",
        },
        {
          key: "setDate",
          label: "Date Start.",
          sortable: true,
          class: "text-left col-date-format",
        },
        {
          key: "setWarranty",
          label: "Date Warranty",
          sortable: true,
          class: "text-left col-date-format",
        },
        // {
        //   key: "lineTokenCheckin",
        //   label: "Line Token",
        //   class: "text-left",
        // },
        {
          key: "notiCheckin",
          label: "Checkin Notify",
          class: "text-center",
        },
        {
          key: "notiAlert",
          label: "Alert Notify",
          class: "text-center",
        },
        {
          key: "actions",
          label: this.$t('Actions'),
          class: "text-center",
        },
      ],
      swNotiCheckin: false,
      dpNotiCheckin: false,
      swNotiAlert: false,
      dpNotiAlert: false,
      swNotiCustom: false,
      dpNotiCustom: false,
      bigdata: [],
      productOptions: [],
      branchSel: [],
      formData: [],
      
      formEditField: {
        setId: "",
        setName: "",
        proSerial: "",
        lineTokenCheckin: "",
        lineTokenAlert: "",
        lineTokenCustom: '',
        notiCustom: ''
      },
      connProcess: false,
      timeOutCount: 0,
      nameState: null,
      notifyStatus: {
        status: "",
        notiCheckin: 0,
        notiAlert: 0,
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {},
  methods: {
    getLinkLineConn(proSerial, notiType) {
      return process.env.VUE_APP_URL + "call_linenoti_service.html?redirect_service=line_notify&notiType="+notiType+"&proSerial="+proSerial;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    toggleNotiCheckin() {
      if (this.swNotiCheckin) {
        this.formEditField.notiCheckin = 1
        this.dpNotiCheckin = true

        this.timeOutCount = 0
        this.getChkConnLine('notiCheckin');

      } else {
        this.formEditField.notiCheckin = 0
        this.dpNotiCheckin = false
      }
    },
    toggleNotiAlert() {
      if (this.swNotiAlert) {
        this.formEditField.notiAlert = 1
        this.dpNotiAlert = true

        this.timeOutCount = 0
        this.getChkConnLine('notiAlert');
      } else {
        this.formEditField.notiAlert = 0
        this.dpNotiAlert = false
      }
    },
    toggleNotiCustom() {
      if (this.swNotiCustom) {
        this.formEditField.notiCustom = 1
        this.dpNotiCustom = true
      } else {
        this.formEditField.notiCustom = 0
        this.dpNotiCustom = false
      }
    },

    getChkConnLine(notiType) {
      let param_fill = "?proSerial="+this.formEditField.proSerial+"&notiType="+notiType
      try {
        helperService
          .getLogNotifyConn(param_fill)
          .then((response) => {

            if (this.timeOutCount < 10) {
              setTimeout(() => {
              this.getChkConnLine(notiType)
              }, 5000);
            } else {
              this.connProcess = false
            }

            if (response.status == 200 && response.data.data != null) {
              if (response.data.data.message == 'waiting') {
                this.timeOutCount = 1;
                this.connProcess = true
              } else {
                this.timeOutCount = 10

                if (notiType == 'notiCheckin') {
                  this.formEditField.lineTokenCheckin = "yes"
                } else if(notiType == 'notiAlert') {
                  this.formEditField.lineTokenAlert = "yes"
                }
                
                this.connProcess = false
              }
              
            } else {
              this.timeOutCount +=1;
            }
           
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },

    editField(field) {
      this.formEditField.setId = field.setId;
      this.formEditField.setName = field.setName;
      this.formEditField.proSerial = field.proSerial;
      this.formEditField.lineTokenCheckin = field.lineTokenCheckin
      this.formEditField.notiCheckin = field.notiCheckin;
      this.formEditField.lineTokenAlert = field.lineTokenAlert
      this.formEditField.notiAlert = field.notiAlert;
      this.formEditField.lineTokenCustom = field.lineTokenCustom;
      this.formEditField.notiCustom = field.notiCustom;

      if (field.notiCheckin == 1) {
        this.swNotiCheckin = true;
        this.dpNotiCheckin = true
      } else {
        this.swNotiCheckin = false;
        this.dpNotiCheckin = false
      }

      if (field.notiAlert == 1) {
        this.swNotiAlert = true;
        this.dpNotiAlert = true
      } else {
        this.swNotiAlert = false;
        this.dpNotiAlert = false
      }

      if (field.notiCustom == 1) {
        this.swNotiCustom = true;
        this.dpNotiCustom = true
      } else {
        this.swNotiCustom = false;
        this.dpNotiCustom = false
      }
      
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    resetModal() {     
      this.nameState = null;
    },
    handleUpdateField(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.$nextTick(() => {
        this.$refs["my-modal"].toggle("#toggle-btn");
      });

      if (!this.formEditField.lineTokenCustom) {
        this.formEditField.notiCustom = 0
      }

      try {
        setupService
          .updateFieldByCus(this.formEditField.setId, this.formEditField)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "อัพเดทสำเร็จ",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.refetchData();
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    switchStatus(id, status, $event) {
      this.notifyStatus.status = status;

      if ($event) {
        this.notifyStatus.notiCheckin = 1;
        this.notifyStatus.notiAlert = 1;
      } else {
        this.notifyStatus.notiCheckin = 0;
        this.notifyStatus.notiAlert = 0;
      }

      try {
        setupService
          .updateStatusNotify(id, this.notifyStatus)
          .then((response) => {
            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "อัพเดทสถานะ",
            //     icon: "CheckIcon",
            //     variant: "success",
            //   },
            // });
            // this.refetchData()
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    getbigdata() {
      try {
        setupService
          .getDataBycusCode(this.cusCode)
          .then((response) => {
            this.bigdata = response.data.data;
            this.totalRows = this.bigdata.length;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    addOpen() {
      this.getProductOptions();
      this.isAddNewSidebarActive = true;
    },
    editOpen(id) {
      this.getProductOptions();

      try {
        setupService
          .getdataById(id)
          .then((response) => {
            this.formData = response.data.data;
            if (this.formData.setDate) {
              this.formData.setDate = dateFormat(
                this.formData.setDate,
                "yyyy-mm-dd"
              );
            }

            if (this.formData.setWarranty) {
              this.formData.setWarranty = dateFormat(
                this.formData.setWarranty,
                "yyyy-mm-dd"
              );
            }

            if (response.data.data_selected) {
              this.productOptions.push(response.data.data_selected);
            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
      this.isEditSidebarActive = true;
    },
    deleteData(setId) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          try {
            setupService
              .deleteData(setId)
              .then((response) => {
                console.log(response);
                this.getbigdata();
              })
              .catch(() => {});
          } catch (e) {
            // console.log(e);
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "ลบข้อมูลสำเร็จ!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      });
    },
    getProductOptions() {
      try {
        productService
          .getProductOptionsdata()
          .then((response) => {
            this.productOptions = response.data.data;

            if (this.branchSel.length == 0) {
              this.getbranchSel()
            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    getbranchSel() {
      try {
        branchService
        .getDataBycusCode(this.cusCode)
          .then((response) => {
            this.branchSel = response.data.data;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    showtoken() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      // console.log(decoded);
      // console.log(decoded.role);
      if (decoded.role == "superadmin" || decoded.role == "admin") {
        this.cusCode = this.$route.params.id;
        this.role = true;
      } else {
        this.cusCode = decoded.cusCode;
        this.role = false;
      }
    },
    refetchData() {
      this.getbigdata();
    },
  },
  async created() {
    await this.showtoken();
    await this.getbigdata();
  },
};
</script>

<style>
.col-date-format {
  min-width: 110px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
</style>
