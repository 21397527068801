import http from "./http";

export default {
    getdataBycusCode(cusCode) {
        return http
            .get("customer-config/" + cusCode)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    getdataMember(uid) {
        return http
            .get("member-config/" + uid)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    updateData(cusCode, form) {
        return http
            .post("customer-config/"+ cusCode, { data: form })
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    updateMemData(uid, form) {
        return http
            .post("member-config/"+ uid, { data: form })
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },


}