<template>
  <b-sidebar
    id="add-new-quiz-sidebar"
    :visible="isAddNewQuizSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-quiz-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("Add New") }} Question</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(insertdata)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            :name="$t('Question')"
            rules="required"
          >
            <b-form-group :label="$t('Question')" :label-for="$t('Question')">
              <b-form-input
                id="question"
                v-model="formData.question"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="ตัวเลือกที่1"
            rules="required"
          >
            <b-form-group :label="$t('Choice No') +' 1'" label-for="ตัวเลือกที่1">
              <b-form-input
                id="choice1"
                v-model="formData.choice1"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="ตัวเลือกที่2"
            rules="required"
          >
            <b-form-group :label="$t('Choice No') +' 2'" label-for="ตัวเลือกที่2">
              <b-form-input
                id="choice2"
                v-model="formData.choice2"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="ตัวเลือกที่3"
            rules="required"
          >
            <b-form-group :label="$t('Choice No') +' 3'" label-for="ตัวเลือกที่3">
              <b-form-input
                id="choice3"
                v-model="formData.choice3"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="ตัวเลือกที่4"
            rules="required"
          >
            <b-form-group :label="$t('Choice No') +' 4'" label-for="ตัวเลือกที่4">
              <b-form-input
                id="choice4"
                v-model="formData.choice4"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="คะแนน"
            rules="required"
          >
            <b-form-group :label="$t('Score')" label-for="คะแนน">
              <b-form-input
                id="score"
                v-model="formData.score"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="คำตอบ"
            rules="required"
          >
            <b-form-group :label="$t('Answer')" label-for="คำตอบ">
              <b-form-input
                id="answer"
                v-model="formData.answer"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="ประเภทคำถาม"
            rules="required"
          >
            <b-form-group :label="$t('Question Type')" label-for="ประเภทคำถาม">
              <b-form-input
                id="type"
                v-model="formData.type"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("Add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
            {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, min, length } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import quizService from "@/services/quizService.js";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewQuizSidebarActive",
    event: "update:is-add-new-quiz-sidebar-active",
  },
  props: {
    isAddNewQuizSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    };
  },
  setup() {
    const blankformData = {
      question: "",
      choice1: "",
      choice2: "",
      choice3: "",
      choice4: "",
      score: "",
      type: "",
      answer: "",
    };

    const formData = ref(JSON.parse(JSON.stringify(blankformData)));
    const resetformData = () => {
      formData.value = JSON.parse(JSON.stringify(blankformData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetformData);

    return {
      formData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    insertdata() {
      try {
        quizService
          .insertdataQuiz(this.formData)
          .then((response) => {
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "บันทึกข้อมูลไม่สำเร็จ",
                text: "Error!",
                timer: 3000,
              });
            } else {
              this.$swal({
                type: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                text: "Save data successfully!",
                timer: 3000,
              });
              this.$emit("update:is-add-new-quiz-sidebar-active", false);
              this.$emit("refetch-data");
            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
