import http from "./http";

export default {
    getalldata(filterAll) {
        return http
            .get("branch" + filterAll)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    getdataById(id) {
        return http
            .get("branch/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    getDataBycusCode(id) {
        return http
            .get("/branch/customer/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    insertData(form) {
        return http
            .post("branch", form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    updateData(id, form) {
        return http
            .put("branch/" + id, form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    deleteData(id) {
        return http
            .delete("branch/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
}