<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t("Add New") }} Product Setup
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(insertSetup)"
          @reset.prevent="resetForm"
        >

          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              :label="$t('Name')"
              label-for="Name"
            >
              <b-form-input
                id="name"
                v-model="formData.setName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Product Ref."
            rules="required"
          >
            <b-form-group
              label="Product Ref."
              label-for="Product Ref."
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="formData.proId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="productOptions"
                :reduce="(option) => option.proId"
                label="proCode"
                input-id="proId"
              />

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

         
          <b-form-group
            :label="$t('Branch')"
            label-for="Branch"
          >
            <v-select
              v-model="formData.b_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="branchSel"
              :reduce="(option) => option.id"
              label="b_name"
              input-id="id"
            />
          </b-form-group>

          <validation-provider
            #default="validationContext"
            name="Date Start"
            rules="required"
          >
            <b-form-group
              label="Date Start"
              label-for="Date Start"
            >
              <b-form-datepicker
                id="setDate"
                v-model="formData.setDate"
                class="form-control mb-1"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Date Warranty"
            rules="required"
          >
            <b-form-group
              label="Date Warranty"
              label-for="Date Warranty"
            >
              <b-form-datepicker
                id="setWarranty"
                v-model="formData.setWarranty"
                class="form-control mb-1"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Line Token"
          >
            <b-form-group
              label="Line Notify Checkin"
              label-for="Line Token"
              description="สร้าง Token https://notify-bot.line.me/"
            >

              <b-form-checkbox
                v-model="swLineNoti"
                checked="true"
                class="custom-control-success mb-25"
                name="check-button"
                switch
                @change="toggleLineNoti"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="BellIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="BellOffIcon" />
                </span>
              </b-form-checkbox>

              <b-form-input
                v-if="dpLineNoti"
                id="lineTokenCheckin"
                v-model="formData.lineTokenCheckin"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Token"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Line Token"
          >
            <b-form-group
              label="Line Notify Alert"
              label-for="Line Notify Alert"
              description="สร้าง Token https://notify-bot.line.me/"
            >

              <b-form-checkbox
                v-model="swNotiAlert"
                checked="true"
                class="custom-control-success mb-25"
                name="check-button"
                switch
                @change="toggleNotiAlert"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="BellIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="BellOffIcon" />
                </span>
              </b-form-checkbox>

              <b-form-input
                v-if="dpNotiAlert"
                id="lineTokenAlert"
                v-model="formData.lineTokenAlert"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Token"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Line Notify Customer"
          >
            <b-form-group
              label="Line Notify Customer"
              label-for="Line Notify Customer"
              description="สร้าง Token https://notify-bot.line.me/"
            >

              <b-form-checkbox
                v-model="swNotiCustom"
                checked="true"
                class="custom-control-success mb-25"
                name="check-button"
                switch
                @change="toggleNotiCustom"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="BellIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="BellOffIcon" />
                </span>
              </b-form-checkbox>

              <b-form-input
                v-if="dpNotiCustom"
                id="lineTokenCustom"
                v-model="formData.lineTokenCustom"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Token"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("Add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
            {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormDatepicker, BFormCheckbox 
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import setupService from "@/services/setupService.js";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    notifyOptions: {
      type: Array,
      required: true,
    },
    productOptions: {
      type: Array,
      required: true,
    },
    branchSel: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      swLineNoti: false,
      dpLineNoti: false,
      swNotiAlert: false,
      dpNotiAlert: false,
      swNotiCustom: false,
      dpNotiCustom: false
    }
  },
  setup() {
    const blankformData = {
      setName: '',
      proId: '',
      setDate: '',
      setWarranty: '',
      lineTokenCheckin: '',
      notiCheckin: '',
      lineTokenAlert: '',
      notiAlert: '',
      lineTokenCustom: '',
      notiCustom: '',
      b_id: '',
    }

    const formData = ref(JSON.parse(JSON.stringify(blankformData)))
    const resetformData = () => {
      formData.value = JSON.parse(JSON.stringify(blankformData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetformData)

    return {
      formData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  methods: {
    toggleLineNoti() {
      if (this.swLineNoti) {
        this.formData.notiCheckin = 1
        this.dpLineNoti = true
      } else {
        this.formData.notiCheckin = 0
        this.dpLineNoti = false
      }
    },
    toggleNotiAlert() {
      if (this.swNotiAlert) {
        this.formData.notiAlert = 1
        this.dpNotiAlert = true
      } else {
        this.formData.notiAlert = 0
        this.dpNotiAlert = false
      }
    },
    toggleNotiCustom() {
      if (this.swNotiCustom) {
        this.formData.notiCustom = 1
        this.dpNotiCustom = true
      } else {
        this.formData.notiCustom = 0
        this.dpNotiCustom = false
      }
    },
    insertSetup() {
      
      
      try {
        setupService
          .insertCusSetup(this.$route.params.id, this.formData)
          .then((response) => {
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "บันทึกข้อมูลไม่สำเร็จ",
                text: "Error! " + response.data.message,
                timer: 3000,
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "บันทึกข้อมูลสำเร็จ!",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit('update:is-add-new-sidebar-active', false)
              this.$emit('refetch-data')
            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
