<template>
    <b-modal
      id="modal-import-mem"
      ref="modal-import-mem"
      size="lg"
      no-close-on-backdrop
      :ok-title="getOkName"
      centered
      :title="$t('Import') +' '+ $t('Employeers')"
      :cancel-title="$t('Cancel')"
      cancel-variant="outline-secondary"
      @hidden="resetModal"
      @ok="confirmImport" 
    >
      <b-overlay
        :show="ok_title == 'process_import'"
        class="d-inline-block"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-row>
              <b-col md="12" v-if="branchSel.length > 0">
                <h6>{{ $t('Branch') }}</h6>
                <b-form-group>
                  <v-select
                    v-model="formData.b_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="branchSel"
                    :reduce="(option) => option.id"
                    label="b_name"
                    input-id="id"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <h6>{{ $t('Department') }}</h6>
                <b-form-group>
                  <v-select
                    v-model="formData.dep_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="DepNewOptions"
                    :reduce="(option) => option.id"
                    label="name"
                    input-id="id"
                    @input="depChange"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <h6>{{ $t('Choose file') }}</h6>
                <validation-provider
                  #default="{ errors }"
                  name="file"
                  rules="required"
                  ref="provider"
                >
                  <b-form-file
                    ref="refInputEl"
                    type="file"
                    accept=".xlsx"
                    id="input_file_import_mem"
                    v-model="fileImport"
                    :state="Boolean(fileImport)"
                    @change="changeFileImport"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-card-text style="font-size: 12px; color: red; font-style: italic"
                  >{{ $t('Import mem limit') }}</b-card-text
                >
              </b-col>
            </b-row>
          </form>
        </validation-observer>

        <b-alert
        v-if="dupData.length > 0"
          variant="danger"
          show
          class="mt-1"
        >
          <h4 class="alert-heading">
            {{ $t('Import failed') }} ({{ dupData.length }})
          </h4>
          <div class="alert-body">
            <span>{{ $t('Import failed desc') }}
              <b-link href="#" @click="exportExcel">{{ $t('Download file') }}</b-link>
            </span>
          </div>
        </b-alert>

        <b-alert
          v-if="import_total > 0"
          variant="success"
          dismissible
          show
        >
          <h4 class="alert-heading">
            {{ $t('Import all success') }} ({{ import_total }})
          </h4>
        </b-alert>
      </b-overlay>

      <b-col cols="12" class="mt-2 p-0">
        <h6>{{ $t('Data example') }}</h6>
        <b-table
          id = "table-demo-import-mems"
          responsive
          :items="demoImportData"
          :fields="demofields"
        >
        </b-table>

        <h6>{{ $t('Remark') }}</h6>
        <ul>
          <li>{{ $t('Remark 1.') }}</li>
          <li>{{ $t('Remark 2.') }}</li>
          <li>{{ $t('Remark 3.') }}</li>
          <li>{{ $t('Remark 4.') }}</li>
          <li>{{ $t('Remark 5.') }}</li>
          <li>{{ $t('Remark 6.') }}</li>
        </ul>
        <b-button
          id="btn-download-temp"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          size="sm"
          @click="downloadTempImport"
        >
          <feather-icon  icon="FileTextIcon" class="mr-50"/>{{ $t('Download') }} Template
        </b-button>
      </b-col>
    </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BModal,
  VBModal,
  BRow,
  BCol,
  BTable,
  BFormGroup,
  BFormInput,
  BFormFile,
  BCardText,
  BButton,
  BTooltip,
  BAlert,
  BLink,
  BOverlay,
} from "bootstrap-vue";
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as XLSX from "xlsx";
import memberService from "@/services/memberService.js";

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormInput,
    BFormFile,
    BCardText,
    BButton,
    BTooltip,
    BAlert,
    BLink,
    BOverlay,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    cusCode: "",
    branchSel: {
      type: Array,
      required: true,
    },
    DepOptions: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const file_import = ""

    const blankformData = {
      b_id: '',
      dep_id: "",
      dep_name: "",
    }

    const formData = ref(JSON.parse(JSON.stringify(blankformData)))
    const resetformData = () => {
      formData.value = JSON.parse(JSON.stringify(blankformData))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetformData)

    return {
      formData,
      file_import,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  data() {
    return {
      fileImport: null,
      formfileData: new FormData(),
      nameState: null,
      ok_title: "confirm_import",
      import_total: 0,
      dupData: [],
      DepNewOptions: [],
      demofields: [
        {
          key: "tel",
          label: this.$t('Tel'),
          class: "text-left col-tel text-red",
        },
        {
          key: "firstname",
          label: this.$t('Firstname'),
          class: "text-left text-red",
        },
        {
          key: "lastname",
          label: this.$t('Lastname'),
          class: "text-left text-red",
        },
        {
          key: "gender",
          label: this.$t('Gender'),
          class: "text-left",
        },
        {
          key: "birthday",
          label: this.$t('Birthday'),
          class: "text-left",
        },
        {
          key: "emp_id",
          label: this.$t('ID CODE'),
          class: "text-left col-tel text-red",
        },
        {
          key: "position",
          label: this.$t('Position'),
          class: "text-left",
        },
      ],
      demoImportData: [
        {
          "firstname": "สมชาย",
          "lastname": "มาดี",
          "birthday": "1999-09-09",
          "tel": "0999999999",
          "emp_id": "99999999",
          "gender": "male",
          "position": "Driver"
        },
        {
          "firstname": "สมศรี",
          "lastname": "มีนา",
          "birthday": "1991-01-01",
          "tel": "0123456789",
          "emp_id": "01234567",
          "gender": "female",
          "position": "บัญชี"
        }
      ]
    };
  },
  computed: {
    getOkName() {
      let OkName = this.$t('Import confirm')
      if (this.ok_title == 'process_import') {
        OkName = this.$t('Importing data')+"..."
      } else if (this.ok_title == 'success_import') {
        OkName = this.$t('Close')
      }
      return OkName;
    },
  },
  watch: {
    DepOptions() {
      this.DepNewOptions = [] 
      this.DepOptions.forEach((item) => {
        if (item.dep_level != -1) {
          this.DepNewOptions.push(item)
        }
      })
    }
  },
  methods: {
    downloadTempImport() {
      const link = document.createElement("a");
      link.href = process.env.VUE_APP_UPLOAD_IMAGE + "tmp/import-members-template.xlsx?version=1_2";
      link.click();
    },
    depChange(value) {
      let dep_data = this.DepNewOptions.filter((x) => x.id === value);
      dep_data = dep_data[0];
      this.formData.dep_name = dep_data.name;
    },
    async changeFileImport(e) {
     
      const file = document.getElementById("input_file_import_mem");
      if (file.size > 20480 * 20480) {
        e.preventDefault();
        alert("File too big (> 20MB)");

        return;
      }

      if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        this.file_import = e.target.files[0];
        //console.log(e.target.files[0]);
      }
    },
    confirmImport(bvModalEvt) {
      if (this.ok_title == "confirm_import") {
        bvModalEvt.preventDefault();
        this.handleSubmit();
      } else {
        this.$refs["modal-import-mem"].hide();
      }
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.ok_title = "process_import";

      this.formfileData = new FormData()
      this.formfileData.append("cusCode", this.cusCode);
      this.formfileData.append("b_id", this.formData.b_id);
      this.formfileData.append("dep_id", this.formData.dep_id);
      this.formfileData.append("dep_name", this.formData.dep_name);
      this.formfileData.append("file_import", this.file_import);

      try {
        memberService
          .importMemberData(this.formfileData)
          .then((response) => {
          
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "นำเข้าข้อมูลไม่สำเร็จ",
                text: "Error! " + response.data.message,
                timer: 5000,
              });
              this.ok_title = "confirm_import";
            } else {
             

              this.import_total = response.data.total;
              this.dupData = response.data.dup_data;
              this.ok_title = "success_import";
              if ( this.dupData.length == 0) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "นำเข้าข้อมูลสำเร็จ!",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                this.$refs["modal-import-mem"].toggle("#toggle-btn");
              }
              this.$emit("refetch-data");
            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      if (this.fileImport == null) {
        return false;
      }
      this.nameState = valid;
      return valid;
    },
    resetModal() {  
      this.nameState = null;
      this.fileImport = null
      this.import_total = 0
      this.ok_title = "confirm_import";
      this.dupData = []
    },
    exportExcel() {

      if (this.dupData.length > 0) {
              
        let title = "";
        let heading = "";
        let filename = "";
        let data = "";
        title = [
          [
            "รายชื่อที่ซ้ำในระบบ " 
          ],
        ];

        heading = [
          [
            "ลำดับ",
            "เบอร์โทร",
            "ชื่อ",
            "นามสกุล",
            "ชื่อ (Eng)",
            "นามสกุล (Eng)",
            "เพศ",
            "วันเกิด",
            "รหัสพนักงาน",
            "ตำแหน่ง",
            "หมายเหตุ",
          ],
        ];

        filename = "duplicate_data.xlsx";
        data = [];
        let i = 1;

        this.dupData.forEach((item) => {
          data.push({
            no: i,
            tel: item.tel,
            firstname: item.firstname,
            lastname: item.lastname,
            firstname_en: item.firstname_en,
            lastname_en: item.lastname_en,
            gender: item.gender,
            birthday: item.birthday,
            emp_id: item.emp_id,
            position: item.position,
            note_problem: item.note_problem,
          });
          i++;
        });

        let fm_data = {
          // title: title,
          heading: heading,
          data: data,
          filename: filename,
        };

        const wb = XLSX.utils.book_new();
        //XLSX.utils.sheet_add_aoa(wb, fm_data.title);
        XLSX.utils.sheet_add_aoa(wb, fm_data.heading, { origin: "A1" });

        const dataWS = XLSX.utils.sheet_add_json(wb, fm_data.data, {
          origin: "A2",
          skipHeader: true,
        });

        XLSX.utils.book_append_sheet(wb, dataWS);
        XLSX.writeFile(wb, fm_data.filename);
      }
    },
  },
  async created() {
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#table-demo-import-mems {
  th, td {
     border: 1px solid #ebe9f1;
     padding: 0.5rem 1rem;
  }

  th.text-red {
    color: red;
  }

  .col-tel {
    min-width: 110px;
  }
}

</style>
