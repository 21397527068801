<template>
  <div>
    <BranchAdd
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :customerData="customerData"
      @refetch-data="refetchData"
    />
    <BranchEdit
      :is-edit-sidebar-active.sync="isEditSidebarActive"
      :formData="formData"
      @refetch-data="refetchData"
    />
    <b-card>
      <b-col
          md="6"
          class="my-1 pl-0"
        >
          <b-form-group
            class="mb-0"
          >
            <b-input-group >
              <b-button style="margin-left: 5px;border-radius: 0.357rem;"
                  variant="primary"
                  size="sm"
                  @click="addOpen"
                >
                  <span class="text-nowrap"> {{ $t("Add") }}  {{ $t("Branch") }}</span>
                </b-button>
            </b-input-group>
          </b-form-group>
        </b-col>
      <b-row
        v-for="item in bigdata" :key="item.id">
        <b-col md="6">
          <b-card
            no-body
            class="business-card"
          >
            <div class="business-items">
              <div class="business-item">
                <div class="d-flex align-items-left justify-content-between">
                 <b>สาขา : {{item.b_name}}</b>
                  <div>
                     <feather-icon
                        icon="EditIcon"
                        size="20"
                        class="mr-75 text-warning"
                        style="cursor: pointer;"
                        @click="editOpen(item)"
                      />
                      <feather-icon
                        icon="Trash2Icon"
                        size="20"
                        class="mr-50 text-danger"
                        style="cursor: pointer;"
                        @click="deleteData(item.b_code)"
                      />
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard, BCardHeader, BCardText, BLink, BRow, BCol, BFormGroup, BInputGroup, BButton
} from 'bootstrap-vue'
import jwt_decode from "jwt-decode";
import branchService from "@/services/branchService.js";
import BranchAdd from './BranchAdd.vue'
import BranchEdit from './BranchEdit.vue'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BLink,
    BRow,
    BCol,
    BFormGroup, BInputGroup,
    BButton,
    BranchAdd,
    BranchEdit,
  },
  directives: {
    Ripple,
  },
  setup() {
    const isAddNewSidebarActive = ref(false)
    const isEditSidebarActive = ref(false) 

    return {
      // Sidebar
      isAddNewSidebarActive,
      isEditSidebarActive,
    }
  },
  props: {
    customerData: {
    },
  },
  data() {
    return {
      cusCode: "",
      role: true,
      bigdata: [],
      formData: [],
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    getbigdata() {
      try {
        branchService
          .getDataBycusCode(this.cusCode)
          .then((response) => {
            this.bigdata = response.data.data;
            this.totalRows = this.bigdata.length;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    addOpen() {
      this.isAddNewSidebarActive = true; 
    },
    editOpen(item){  
      this.formData = item
      this.isEditSidebarActive = true; 
    },
    deleteData(id) {

      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        
        if (result.value) {
          try {
            branchService
              .deleteData(id)
              .then((response) => {
                
                this.getbigdata()
              })
              .catch(() => {});

          } catch (e) {
            // console.log(e);
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "ลบข้อมูลสำเร็จ!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      })
    },
    showtoken() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      // console.log(decoded);
      if (decoded.role == "superadmin" || decoded.role == "admin") {
        this.cusCode = this.$route.params.id
      } else {
        this.cusCode = decoded.cusCode
      }
    },
    refetchData(){
      this.getbigdata()
    }
  },
  async created() { 
    await this.showtoken();
    await this.getbigdata();
  },

}
</script>

<style>
.col-date-format {
  min-width: 110px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
</style>
