import http from "./http";

export default {
    getLogNotifyConn(params) {
        return http
            .get("get-log-notify"+ params)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    insertLogNoti(form) {
        return http
            .post("save-log-notify", form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
}