<template>
  <b-sidebar
    id="add-update-dep-sidebar"
    :visible="isAddUpdateDepSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-update-dep-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">เพิ่มแผนก พนักงาน</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(updatedata)"
        >
          
          <validation-provider
            #default="validationContext"
            name="แผนก"
            rules="required"
          >
            <b-form-group
              label="แผนก"
              label-for="แผนก"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="formDataDep.dep_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="DepOptions"
                :reduce="(option) => option.id"
                label="name"
                input-id="id"
                @input="depChange"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("Add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
            {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import companyService from "@/services/companyService.js";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddUpdateDepSidebarActive",
    event: "update:is-add-update-dep-sidebar-active",
  },
  props: {
    isAddUpdateDepSidebarActive: {
      type: Boolean,
      required: true,
    },
    formDataDep: {
    },
    DepOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup() {
    const { refFormObserver, getValidationState } = formValidation();

    return {
      refFormObserver,
      getValidationState,
    };
  },
  methods: {
    depChange(value) {
      let dep_data = this.DepOptions.filter((x) => x.id === value);
      dep_data = dep_data[0];
      this.formDataDep.dep_name = dep_data.name;
    },
    updatedata() {
      
      try {
        companyService
          .updateData(this.formDataDep.c_id, this.formDataDep)
          .then((response) => {
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "บันทึกข้อมูลไม่สำเร็จ",
                text: "Error! " + response.data.message,
                timer: 3000,
              });
            } else {
              this.$swal({
                type: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                text: "Save data successfully!",
                timer: 3000,
              });
              this.$emit("update:is-add-update-dep-sidebar-active", false);
              this.$emit("refetch-data");
            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
