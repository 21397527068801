<template>
  <div>
    <UsersAddNew
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      :role-options="roleOptions"
      :branchSel="branchSel"
      :cusCode="cusCode"
      :roleAccess="role"
      @refetch-data="refetchData"
    />
    <UsersEdit
      :is-edit-sidebar-active.sync="isEditSidebarActive"
      :role-options="roleOptions"
      :branchSel="branchSel"
      :form_Data="form_Data"
      :roleAccess="role"
      @refetch-data="refetchData"
    /> 
    <b-card>
      <b-row>
        <b-col md="3" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Sort") }}</label>
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="6" class="my-1 d-none d-md-block"></b-col>

        <b-col md="6" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Filter") }}</label>
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('Search')"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  {{ $t("Clear") }}
                </b-button>

                <b-button
                  style="margin-left: 5px; border-radius: 0.357rem"
                  variant="primary"
                  @click="addOpen"
                >
                  <span class="text-nowrap">{{ $t("Add") }} {{ $t("User") }}</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            striped
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="bigdata"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(show_details)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
                <span class="vs-label">{{
                  row.detailsShowing ? "Hide" : "Show"
                }}</span>
              </b-form-checkbox>
            </template>

            <template #row-details="row">
              <b-card>
                <b-row class="mb-2">
                  <b-col md="3" class="mb-1">
                    <strong>Username : </strong>{{ row.item.username }}
                    <span v-if="row.item.id == currentUserId"
                      ><b-badge variant="success">My</b-badge></span
                    >
                  </b-col>
                  <b-col md="3" class="mb-1">
                    <strong>Password : </strong>
                    <span v-if="row.item.password == ''"></span>
                    <span v-else>******</span>
                  </b-col>
                  <b-col md="3" class="mb-1">
                    <strong>Role : </strong>
                    <div v-if="row.item.role == 'customer'">Customer</div>
                    <div v-else>User</div>
                  </b-col>
                  <b-col md="12" class="mb-1">
                    <b-button
                      size="sm"
                      variant="outline-warning"
                      @click="editOpen(row.item.id)"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">{{ $t("Edit") }}</span> </b-button
                    >&nbsp;
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      @click="deleteData(row.item.id)"
                    >
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">{{ $t("Delete") }}</span>
                    </b-button>
                  </b-col>
                </b-row>

                <b-button
                  size="sm"
                  variant="outline-secondary"
                  @click="row.toggleDetails"
                >
                  Hide Details
                </b-button>
              </b-card>
            </template>

            <template #cell(username)="row">
              <div v-if="row.item.username == ''"></div>
              <div v-else>
                {{ row.item.username }}
                <span v-if="row.item.id == currentUserId"
                  ><b-badge variant="success">My</b-badge></span
                >
              </div>
            </template>

            <!-- <template #cell(password)="row">
              <div v-if="row.item.password == ''"></div>
              <div v-else>******</div>
            </template> -->

            <template #cell(role)="row">
              <div v-if="row.item.role == 'customer'">Customer</div>
              <div v-else-if="row.item.role == 'manager'">Manager</div>
              <div v-else>User</div>
            </template>

            <template #cell(actions)="row">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="editOpen(row.item.id)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t("Edit") }}</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteData(row.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t("Delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>

        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Per page") }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />

            <div class="show-entries" v-if="totalRows == 0">
              <label class="text-muted">{{ $t("Showing") }} 0 {{ $t("To") }}  0 {{ $t("Of") }}  0 {{ $t("Entries") }} </label>
            </div>
            <div class="show-entries" v-else>
              <div v-if="currentPage * perPage > totalRows">
                <label class="text-muted"
                  >{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                  {{ currentPage * perPage }} {{ $t("Of") }} {{ totalRows }} {{ $t("Entries") }}</label
                >
              </div>
              <div v-else>
                <label class="text-muted"
                  >{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                  {{ currentPage * perPage }} {{ $t("Of") }} {{ totalRows }} {{ $t("Entries") }}</label
                >
              </div>
            </div>
          </b-form-group>
        </b-col>

        <b-col md="10" sm="8">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BCardHeader,
  BCardText,
  BLink,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BImg,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import jwt_decode from "jwt-decode";
import usersService from "@/services/usersService.js";
import branchService from "@/services/branchService.js";
import UsersAddNew from "./UsersAddNew.vue";
import UsersEdit from "./UsersEdit.vue";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BLink,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BImg,
    BDropdown,
    BDropdownItem,
    UsersAddNew,
    UsersEdit,
  },
  directives: {
    Ripple,
  },
  setup() {
    const isAddNewSidebarActive = ref(false);
    const isEditSidebarActive = ref(false);

    const roleOptions = [
      { label: "Customer", value: "customer" },
      { label: "Manager", value: "manager" },
      { label: "User", value: "user" },
    ];

    return {
      // Sidebar
      isAddNewSidebarActive,
      isEditSidebarActive,

      roleOptions,
    };
  },
  data() {
    return {
      currentUserId: "",
      cusCode: "",
      perPage: 20,
      pageOptions: [20, 30, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      role: true,
      fields: [
        {
          key: "show_details",
          label: "Details",
          class: "d-sm-none",
        },
        {
          key: "username",
          label: this.$t("Username"),
          sortable: true,
          class: "text-left",
        },
        // {
        //   key: "password",
        //   label: "Password",
        //   class: "text-left",
        // },
        {
          key: "role",
          label: this.$t("User Role"),
          sortable: true,
          class: "text-left",
        },
        {
          key: "b_name",
          label: this.$t("Branch"),
          sortable: true,
          class: "text-left",
        },
        {
          key: "actions",
          label: this.$t('Actions'),
          class: "text-center",
        },
      ],
      bigdata: [],
      form_Data: [],
      branchSel: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {},
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getbigdata() {
      try {
        usersService
          .getDataByCusID(this.cusCode)
          .then((response) => {
            this.bigdata = response.data.data;
            this.totalRows = this.bigdata.length;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    getbranchSel() {
      try {
        branchService
        .getDataBycusCode(this.cusCode)
          .then((response) => {
            this.branchSel = response.data.data;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    addOpen() {
      if (this.branchSel.length == 0) {
        this.getbranchSel()
      }
      this.isAddNewSidebarActive = true;
    },
    editOpen(id) {
      if (this.branchSel.length == 0) {
        this.getbranchSel()
      }
      
      try {
        usersService
          .getDataById(id)
          .then((response) => {
            this.form_Data = response.data.data;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
      this.isEditSidebarActive = true;
    },
    deleteData(key_id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          try {
            usersService
              .deleteData(key_id)
              .then((response) => {
                console.log(response);
                this.getbigdata();
              })
              .catch(() => {});
          } catch (e) {
            // console.log(e);
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "ลบข้อมูลสำเร็จ",
              icon: "CheckIcon",
              variant: "success",
            },
          });
         
        }
      });
    },
    showtoken() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      //console.log(decoded);
      // console.log(decoded.role);
      this.currentUserId = decoded.sub;
      if (decoded.role == "superadmin" || decoded.role == "admin") {
        this.cusCode = this.$route.params.id;
        this.role = true;
      } else {
        this.cusCode = decoded.cusCode;
        this.role = false;
      }
    },
    refetchData() {
      this.getbigdata();
    },
  },
  async created() {
    await this.showtoken();
    await this.getbigdata();
  },
};
</script>

<style></style>
