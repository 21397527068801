import http from "./http";

export default {
    getalldata(filterAll) {
        return http
            .get("members" + filterAll)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    getdataById(id) {
        return http
            .get("members/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    getDataBycusCode(id, filterAll) {
        return http
            .get("/members/customer/" + id + filterAll)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    insertData(form) {
        return http
            .post("members", form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    insertWithCusId(id, form) {
        return http
            .post("members/customer/" + id, form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    updateData(id, form) {
        return http
            .put("members/customer/" + id, form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    deleteData(id) {
        return http
            .delete("members/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    updateStatus(id, status) {
        return http
            .post("members/status/" + id, { del_flag: status })
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },

    getlogHistory(filter = '') {
        return http
            .get("/logs" + filter)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.resolve(error);
            });
    },

    importMemberData(form) {
        return http
            .post("/import/members", form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
        });
    },

}