<template>
    <b-modal
      id="modal-customer-permission"
      ref="modal-customer-permission"
      title="สิทธิการทดสอบเครื่อง"
      :ok-title="$t('Save')"
      :cancel-title="$t('Cancel')"
      cancel-variant="outline-secondary"
      centered
      @ok="handleUpdateField"
    >
      <div class="mb-1">
        <b-badge  href="#" pill variant="primary" class="mr-25" @click="changedGui('rem')">แนะนำ</b-badge>
        <b-badge  href="#" pill variant="success" class="mr-25" @click="changedGui('all')">ทั้งหมด</b-badge>
        <b-badge  href="#" pill variant="danger" @click="changedGui('def')">ค่าเรื่มต้น</b-badge>
      </div>
      <b-table
        striped
        responsive
        class="table-permis-mem mb-0"
        :items="formData"
        :fields="fields"
      >
        <template #cell(name)="data">
          {{ data.item.f_name }}
        </template>
        <template #cell(in)="data">
          <b-form-checkbox :checked="data.value" v-model="formData[data.index].f_in"/>
        </template>
        <template #cell(out)="data">
          <b-form-checkbox :checked="data.value" v-model="formData[data.index].f_out"/>
        </template>
      </b-table>
      <br>
      <b-form-checkbox class="d-inline-block" :checked="chkMemAll" v-model="chkMemAll"/> <span class="d-inline-block"> นำไปใช้กับพนักงานทั้งหมด</span>
    </b-modal>
</template>

<script>
/* eslint-disable */
import {
  BModal,
  VBModal,
  BBadge,
  BButton,
  BRow, BCol, BTable, BFormCheckbox,
} from "bootstrap-vue";
import permissionsService from "@/services/permissionsService.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BModal,
    BBadge,
    BButton,
    BRow, BCol, BTable, BFormCheckbox,
  },
  directives: {
    "b-modal": VBModal,
  },
  props: {
    cusCode: "",
    permissionsForm: [],
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "การทดสอบ",
        },
        {
          key: "in",
          label: "เข้างาน",
          class: "text-center",
        },
        {
          key: "out",
          label: "จบงาน",
          class: "text-center",
        },
      ],
      formData: [],
      chkMemAll: false
    };
  },
  setup() {
  },
  watch: {
    permissionsForm() {
      this.getData()
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.formData = []
      this.permissionsForm.forEach((item) => {
        let v =  {
          f_name: item.name,
          f_key: item.name_key,
          f_in: item.in,
          f_out: item.out,
        };
        this.formData.push(v);
      })
    },
    changedGui(val) {
      this.formData.forEach((item, index) => {
        if (val == 'rem') {
          if (index == 1 || index == 3 || index == 4 || index == 5) {
            this.formData[index].f_in = true
            this.formData[index].f_out = false
          } else {
            this.formData[index].f_in = true
            this.formData[index].f_out = true
          }
        } else {
          this.formData[index].f_in = true
          this.formData[index].f_out = true
        }
      })
    },
    handleUpdateField(bvModalEvt) {
      bvModalEvt.preventDefault();

      if (this.chkMemAll) {
        this.$swal({
          title: "ยืนยันการอัพเดทสิทธิของพนักงาน ?",
          text: "ข้อมูลสิทธิการใช้งานก่อนหน้านี้ของพนักงานจะถูกลบทั้งหมด",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก", 
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.formData[5].chkMemAll = this.chkMemAll
            this.updatedata();
          }
        });
      } else {
        this.updatedata();
      }
    },
    updatedata() {

      this.$nextTick(() => {
        this.$refs["modal-customer-permission"].toggle("#toggle-btn");
      });

      console.log(this.formData)
      try {
        permissionsService
          .updateData(this.cusCode, this.formData)
          .then((response) => {
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "อัพเดทข้อมูลไม่สำเร็จ",
                text: "Error! " + response.data.message,
                timer: 3000,
              });
            } 
            else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Update Success",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            
              this.$emit("refetchPmsData");
            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
      
    },
    
  },
};
</script>

<style lang="scss">
</style>
