<template>
  <div>
    <score-edit
      v-if="role == 'superadmin' || role == 'admin'"
      :is-edit-sidebar-active.sync="isEditSidebarActive"
      :form_Data="form_Data"
      @refetch-data="refetchData"
    />

    <score-edit-blind
      v-if="role == 'superadmin' || role == 'admin'"
      :is-edit-blind-sidebar-active.sync="isEditBlindSidebarActive"
      :form_Data="form_Data"
      @refetch-data="refetchData"
    />

    <score-edit-alcohol
      v-if="role == 'superadmin' || role == 'admin'"
      :is-edit-alcohol-sidebar-active.sync="isEditAlcoholSidebarActive"
      :form_Data="form_Data"
      @refetch-data="refetchData"
    />

    <score-edit-foot
      v-if="role == 'superadmin' || role == 'admin'"
      :is-edit-foot-sidebar-active.sync="isEditFootSidebarActive"
      :form_Data="form_Data"
      @refetch-data="refetchData"
    />
    <score-edit-hand
      v-if="role == 'superadmin' || role == 'admin'"
      :is-edit-hand-sidebar-active.sync="isEditHandSidebarActive"
      :form_Data="form_Data"
      @refetch-data="refetchData"
    />

    <!-- <ScoreAL
      v-if="role == 'superadmin' || role == 'admin'"
      :is-al-sidebar-active.sync="isALSidebarActive"
      :form_Data="form_Data"
      @refetch-data="refetchData"
    /> -->

    <b-card>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(insertdata)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col xl="4" md="4" sm="6">
              <label class="sb" style="font-size: 15px; font-weight: bold"
                >Score Blind</label
              >
              <div style="overflow-x: auto">
                <table class="tbb">
                  <tr>
                    <th>1/5</th>
                    <th>2/5</th>
                    <th>3/5</th>
                    <th>4/5</th>
                    <th>5/5</th>
                    <th>Action</th>
                  </tr>
                  <tr>
                    <td>{{ customerData.cfs_blind1 }}</td>
                    <td>{{ customerData.cfs_blind2 }}</td>
                    <td>{{ customerData.cfs_blind3 }}</td>
                    <td>{{ customerData.cfs_blind4 }}</td>
                    <td>{{ customerData.cfs_blind5 }}</td>
                    <td>
                      <b-button
                        v-if="role == 'superadmin' || role == 'admin'"
                        @click="editOpen2(customerData.cusCode)"
                        variant="primary"
                        size="sm"
                      >
                        Edit
                      </b-button>
                    </td>
                  </tr>
                  <tr>
                    <!-- <td>February</td>
              <td>$80</td> -->
                  </tr>
                </table>
              </div>
            </b-col>

            <b-col xl="4" md="4" sm="6">
              <label class="sb" style="font-size: 15px; font-weight: bold"
                >Score AL</label
              >
              <table class="tbal">
                <tr>
                  <th>1/3</th>
                  <th>2/3</th>
                  <th>3/3</th>
                  <th>Action</th>
                </tr>
                <tr>
                  <td>{{ customerData.cfs_al1 }}</td>
                  <td>{{ customerData.cfs_al2 }}</td>
                  <td>{{ customerData.cfs_al3 }}</td>
                  <td>
                    <b-button
                      v-if="role == 'superadmin' || role == 'admin'"
                      @click="editOpen3(customerData.cusCode)"
                      variant="primary"
                      size="sm"
                    >
                      Edit
                    </b-button>
                  </td>
                </tr>
                <tr>
                  <!-- <td>February</td>
              <td>$80</td> -->
                </tr>
              </table>
            </b-col>

            <b-col xl="4" md="4" sm="6">
              <label class="sb" style="font-size: 15px; font-weight: bold"
                >Score BP</label
              >
              <div style="overflow-x: auto">
                <table class="tbbp">
                  <tr>
                    <th>1/5</th>
                    <th>2/5</th>
                    <th>3/5</th>
                    <th>4/5</th>
                    <th>5/5</th>
                    <th>Action</th>
                  </tr>
                  <tr>
                    <td>{{ customerData.cfs_bp1 }}</td>
                    <td>{{ customerData.cfs_bp2 }}</td>
                    <td>{{ customerData.cfs_bp3 }}</td>
                    <td>{{ customerData.cfs_bp4 }}</td>
                    <td>{{ customerData.cfs_bp5 }}</td>
                    <td>
                      <b-button
                        v-if="role == 'superadmin' || role == 'admin'"
                        @click="editOpen(customerData.cusCode)"
                        variant="primary"
                        size="sm"
                      >
                        Edit
                      </b-button>
                    </td>
                  </tr>
                  <tr>
                    <!-- <td>February</td>
              <td>$80</td> -->
                  </tr>
                </table>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="6" md="12" style="margin-top: 44px">
              <label
                class="sb text-left"
                style="font-size: 15px; font-weight: bold"
                >Score SpeedFoot</label
              >
              <table class="tbf">
                <tr>
                  <th>1/3</th>
                  <th>2/3</th>
                  <th>3/3</th>
                  <th>Action</th>
                </tr>
                <tr>
                  <td>{{ customerData.cfs_foot1 }}</td>
                  <td>{{ customerData.cfs_foot2 }}</td>
                  <td>{{ customerData.cfs_foot3 }}</td>
                  <td>
                    <b-button
                      v-if="role == 'superadmin' || role == 'admin'"
                      @click="editOpen4(customerData.cusCode)"
                      variant="primary"
                      size="sm"
                    >
                      Edit
                    </b-button>
                  </td>
                </tr>
                <tr>
                  <!-- <td>February</td>
              <td>$80</td> -->
                </tr>
              </table>
            </b-col>
            <b-col lg="6" md="12" style="margin-top: 44px">
              <label
                class="sb text-left"
                style="font-size: 15px; font-weight: bold"
                >Score SpeedHand</label
              >
              <table class="tbh">
                <tr>
                  <th>1/3</th>
                  <th>2/3</th>
                  <th>3/3</th>
                  <th>Action</th>
                </tr>
                <tr>
                  <td>{{ customerData.cfs_hand1 }}</td>
                  <td>{{ customerData.cfs_hand2 }}</td>
                  <td>{{ customerData.cfs_hand3 }}</td>
                  <td>
                    <b-button
                      v-if="role == 'superadmin' || role == 'admin'"
                      @click="editOpen5(customerData.cusCode)"
                      variant="primary"
                      size="sm"
                    >
                      Edit
                    </b-button>
                  </td>
                </tr>
                <tr>
                  <!-- <td>February</td>
              <td>$80</td> -->
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <!-- <div class="d-flex flex-wrap float-right">
      <b-button
        v-if="role == 'superadmin' || role == 'admin'"
        @click="editOpen(customerData.cusCode)"
        variant="primary"
        size="sm"
      >
        Edit
      </b-button>
     
    </div> -->
  </div>
</template>

<script>
import {
  BTab,
  BCard,
  BButton,
  BRow,
  BCol,
  BAvatar,
  BMediaBody,
  BFormFile,
  BCardText,
  BButtonToolbar,
  BButtonGroup,
  BCardHeader,
  BCardBody,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
} from "bootstrap-vue";
// import CustomerEdit from "./CustomerEdit.vue";
import jwt_decode from "jwt-decode";
import customerService from "@/services/customerService.js";
import { ref } from "@vue/composition-api";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ScoreEdit from "./ScoreEditBP.vue";

import ScoreEditBlind from "./ScoreEditBlind.vue";
import ScoreEditAlcohol from "./ScoreEditAlcohol.vue";
import ScoreEditFoot from "./ScoreEditFoot.vue";
import ScoreEditHand from "./ScoreEditHand.vue";

export default {
  components: {
    BTab,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BMediaBody,
    BFormFile,
    BCardText,
    ScoreEdit,
    ScoreEditAlcohol,
    ScoreEditFoot,
    ScoreEditHand,

    ScoreEditBlind,
    BButtonToolbar,
    BButtonGroup,
    BCardHeader,
    BCardBody,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // ScoreAL,
  },
  directives: {
    Ripple,
  },
  setup() {
    const isEditSidebarActive = ref(false);
    const isEditBlindSidebarActive = ref(false);
    const isEditAlcoholSidebarActive = ref(false);
    const isEditFootSidebarActive = ref(false);
    const isEditHandSidebarActive = ref(false);

    const refInputEl = ref(null);
    const previewEl = ref(null);
    const cusImg = ref(null);
    const Chkavatar = ref(false);
    const formImg = new FormData();

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign

        cusImg.value = base64;
        formImg.append("file", refInputEl.value.files[0]);
        Chkavatar.value = true;
      }
    );

    const blankformData = {
      cusCode: "",

      cusCredit: "",
    };

    const formData = ref(JSON.parse(JSON.stringify(blankformData)));
    const resetformData = () => {
      formData.value = JSON.parse(JSON.stringify(blankformData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetformData);

    return {
      // Sidebar
      isEditSidebarActive,
      isEditBlindSidebarActive,
      isEditAlcoholSidebarActive,
      isEditFootSidebarActive,
      isEditHandSidebarActive,

      refInputEl,
      previewEl,
      inputImageRenderer,
      cusImg,
      formImg,
      Chkavatar,
      formData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  data() {
    return {
      cusCode: "",
      profileFile: null,
      options: {},
      customerData: {},
      form_Data: {},
      role: "",
    };
  },
  async created() {
    await this.showtoken();
    await this.getCustomerdata();
  },
  methods: {
    insertdata() {
      try {
        customerService
          .insertcredit(this.formData.cusId)
          .then((response) => {
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "เพิ่มเครดิตไม่สำเร็จ",
                text: "Error!",
                timer: 3000,
              });
            } else {
              this.$swal({
                type: "success",
                title: "เพิ่มเครดิตสำเร็จ",
                text: "Save data successfully!",
                timer: 3000,
              });
              this.$emit("update:is-add-new-user-sidebar-active", false);
              this.$emit("refetch-data");
            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },

    getCustomerdata() {
      try {
        customerService
          .getdataBycusCode(this.cusCode)
          .then((response) => {
            this.customerData = response.data.data;

            this.getImgCustomer(this.customerData.cusImg);
            this.$emit("changecusData", this.customerData);
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    updateCusImg(id) {
      if (this.refInputEl.files[0]) {
        let file_size = this.refInputEl.files[0]["size"];
        if (file_size > 1000000) {
          this.$swal({
            type: "error",
            title: "Error",
            text: "ขนาดไฟล์รูปภาพเกินที่กำหนด",
            timer: 3000,
          });
          this.formImg.delete("file");
          return;
        }
      }

      try {
        customerService
          .updateCusImg(id, this.formImg)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "อัพเดทรูปสำเร็จ",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.Chkavatar = false;
            this.customerData.cusImg = response.data.data.cusImg;
            this.getImgCustomer(this.customerData.cusImg);
            //this.$emit("changeCusImg", this.customerData.cusImg);
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    removeCusImg(id) {
      this.$swal({
        title: "ยืนยันการลบ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ใช่, ต้องการลบ",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          try {
            customerService
              .deleteCusImg(id)
              .then((response) => {
                this.customerData.cusImg = response.data.data.cusImg;
                this.getImgCustomer(this.customerData.cusImg);
                this.$emit("changeCusImg", this.customerData.cusImg);
              })
              .catch(() => {});
          } catch (e) {
            // console.log(e);
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "ลบข้อมูลสำเร็จ!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      });
    },
    editOpen(id) {
      try {
        customerService
          .getdataBycusCode(id)
          .then((response) => {
            this.form_Data = response.data.data;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
      this.isEditSidebarActive = true;

      // this.isAlSidebarActive = true;
    },

    editOpen2(id) {
      try {
        customerService
          .getdataBycusCode(id)
          .then((response) => {
            this.form_Data = response.data.data;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
      this.isEditBlindSidebarActive = true;
      // this.isAlSidebarActive = true;
    },
    editOpen3(id) {
      try {
        customerService
          .getdataBycusCode(id)
          .then((response) => {
            this.form_Data = response.data.data;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
      this.isEditAlcoholSidebarActive = true;
      // this.isAlSidebarActive = true;
    },

    editOpen4(id) {
      try {
        customerService
          .getdataBycusCode(id)
          .then((response) => {
            this.form_Data = response.data.data;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
      this.isEditFootSidebarActive = true;
      // this.isAlSidebarActive = true;
    },

    editOpen5(id) {
      try {
        customerService
          .getdataBycusCode(id)
          .then((response) => {
            this.form_Data = response.data.data;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
      this.isEditHandSidebarActive = true;
      // this.isAlSidebarActive = true;
    },
    deleteData(cusCode) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          try {
            customerService
              .deletedataCustomer(cusCode)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Deleted!",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                this.$router.push("/customer");
              })
              .catch(() => {});
          } catch (e) {
            // console.log(e);
          }
        }
      });
    },
    refetchData() {
      this.getCustomerdata();
    },
    getImgCustomer(item) {
      this.cusImg = process.env.VUE_APP_UPLOAD_IMAGE + item;
    },
    imageUrlAlt(event) {
      event.target.src =
        "https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg";
    },
    showtoken() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      //console.log(decoded);
      this.role = decoded.role;
      if (decoded.role == "superadmin" || decoded.role == "admin") {
        this.cusCode = this.$route.params.id;
      } else {
        this.cusCode = decoded.cusCode;
      }
    },
  },
};
</script>
<style scoped>
/* .demo-spacing{
      right: -21px;
} */
/* .btn-success {
  border-color: #28c76f !important;
  background-color: #28c76f !important;
  right: -21px;
} */
/* .tbb,
th,
td {
  border: 1px solid black;

  margin-top: 10px;
} */
.tbf {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  width: 100%;
}
.tbh {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  width: 100%;
}
.tbbp {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  width: 100%;
}
.tbb {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  width: 100%;
}
.tbal {
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  width: 100%;
}

th,
td {
  padding: 1em;
  background: #f3f2f7;
  border-bottom: 2px solid white;
}
.d-inline-block {
  margin-top: 5px;
}

/* .spf {
  margin-right: -6px;
} */
/* .sph {
  margin-left: -6px;
}  */

/* table,
th,
td {
  border: 1px solid black;
} */
</style>
