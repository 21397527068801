<template>
  <div>
    <customer-edit
      v-if="role == 'superadmin' || role == 'admin'"
      :is-edit-sidebar-active.sync="isEditSidebarActive"
      :form_Data="form_Data"
      @refetch-data="refetchData"
    />

    <b-card>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(insertdata)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col
              cols="21"
              xl="6"
              class="d-flex justify-content-between flex-column"
            >
              <div class="d-flex justify-content-start">
                <b-avatar
                  :src="cusImg"
                  @error="imageUrlAlt"
                  size="104px"
                  rounded
                />
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h4 class="mb-0">
                      {{ customerData.cusName }}
                    </h4>
                    <span class="card-text">{{ customerData.cusEmail }}</span>
                  </div>

                  <b-media-body>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      size="sm"
                      class="mb-25 mr-75"
                      @click="$refs.refInputEl.$el.click()"
                    >
                    {{ $t("Upload") }}
                    </b-button>
                    <b-form-file
                      ref="refInputEl"
                      v-model="profileFile"
                      accept=".jpg,.png"
                      :hidden="true"
                      plain
                      @input="inputImageRenderer"
                    />

                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button
                      v-if="Chkavatar"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-25 mr-75"
                      @click="updateCusImg(cusCode)"
                    >
                    {{ $t("Update") }}
                    </b-button>
                    <b-button
                      v-if="customerData.cusImg && !Chkavatar"
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="danger"
                      size="sm"
                      class="mb-25 mr-75"
                      @click="removeCusImg(cusCode)"
                    >
                    {{ $t("Remove") }}
                    </b-button>
                    <b-card-text
                      style="font-size: 12px; color: red; font-style: italic"
                      >{{ $t("Upload Profile Limit") }}</b-card-text
                    >
                  </b-media-body>
                </div>
              </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col cols="12" xl="6">
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    # <span class="font-weight-bold"> {{ $t("Customer Code") }}</span>
                  </th>
                  <td>
                    {{ customerData.cusCode }}
                  </td>
                  <td></td>
                </tr>
                <!-- <tr>
                  <th class="pb-50">
                    <feather-icon icon="StopCircleIcon" class="mr-75" />
                    <span class="font-weight-bold">Cr{{ $t("Edit") }}</span>
                  </th>
                  <td>
                    {{ customerData.cusCredit }}
                  </td>
                  <td></td>
                </tr> -->
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="PhoneIcon" class="mr-75" />
                    <span class="font-weight-bold">{{ $t("Tel") }}.</span>
                  </th>
                  <td>
                    {{ customerData.cusTel }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="FlagIcon" class="mr-75" />
                    <span class="font-weight-bold">{{ $t("Address") }}</span>
                  </th>
                  <td class="pb-50">
                    {{ customerData.cusAddress }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="BookIcon" class="mr-75" />
                    <span class="font-weight-bold">{{ $t("Note") }}</span>
                  </th>
                  <td class="pb-50">
                    {{ customerData.cusNote }}
                  </td>
                </tr>
                <!-- <tr>
                  <th class="pb-50">
                    <feather-icon icon="PlusCircleIcon" class="mr-75" />

                    <span class="font-weight-bold">เพิ่มเครดิต</span>
                  </th>
                  <td class="pb-50">
                    <b-button-toolbar
                      class="demo-spacing"
                      aria-label="Toolbar with button groups and input groups"
                    >
                      
                      <validation-provider #default="validationContext">
                        <b-form-input
                          size="sm"
                          id="full-name-eng"
                          v-model="formData.cusCredit"
                          autofocus
                          :state="getValidationState(validationContext)"
                        />

                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    
                      <b-button-group size="sm">
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="success"
                          type="submit"
                        >
                          Save
                        </b-button>
                      
                      </b-button-group>
                    </b-button-toolbar>
                  </td>
                </tr> -->
              </table>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <div class="d-flex flex-wrap float-right">
      <b-button
        v-if="role == 'superadmin' || role == 'admin'"
        @click="editOpen(customerData.cusCode)"
        variant="primary"
        size="sm"
      >
      {{ $t("Edit") }}
      </b-button>
      <b-button
        v-if="role == 'superadmin' || role == 'admin'"
        @click="deleteData(customerData.cusCode)"
        variant="outline-danger"
        class="ml-1"
        size="sm"
      >
      {{ $t("Delete") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BTab,
  BCard,
  BButton,
  BRow,
  BCol,
  BAvatar,
  BMediaBody,
  BFormFile,
  BCardText,
  BButtonToolbar,
  BButtonGroup,
  BCardHeader,
  BCardBody,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import CustomerEdit from "./CustomerEdit.vue";
import jwt_decode from "jwt-decode";
import customerService from "@/services/customerService.js";
import { ref } from "@vue/composition-api";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  components: {
    BTab,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BMediaBody,
    BFormFile,
    BCardText,
    CustomerEdit,
    BButtonToolbar,
    BButtonGroup,
    BCardHeader,
    BCardBody,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BForm,
    BFormGroup,

    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  setup() {
    const isEditSidebarActive = ref(false);

    const refInputEl = ref(null);
    const previewEl = ref(null);
    const cusImg = ref(null);
    const Chkavatar = ref(false);
    const formImg = new FormData();

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign

        cusImg.value = base64;
        formImg.append("file", refInputEl.value.files[0]);
        Chkavatar.value = true;
      }
    );

    const blankformData = {
      cusCode: "",

      cusCredit: "",
    };

    const formData = ref(JSON.parse(JSON.stringify(blankformData)));
    const resetformData = () => {
      formData.value = JSON.parse(JSON.stringify(blankformData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetformData);

    return {
      // Sidebar
      isEditSidebarActive,
      refInputEl,
      previewEl,
      inputImageRenderer,
      cusImg,
      formImg,
      Chkavatar,
      formData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  data() {
    return {
      cusCode: "",
      profileFile: null,
      options: {},
      customerData: {},
      form_Data: {},
      role: "",
    };
  },
  async created() {
    await this.showtoken();
    await this.getCustomerdata();
  },
  methods: {
    insertdata() {
      try {
        customerService
          .insertcredit(this.formData.cusId)
          .then((response) => {
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "เพิ่มเครดิตไม่สำเร็จ",
                text: "Error!",
                timer: 3000,
              });
            } else {
              this.$swal({
                type: "success",
                title: "เพิ่มเครดิตสำเร็จ",
                text: "Save data successfully!",
                timer: 3000,
              });
              this.$emit("update:is-add-new-user-sidebar-active", false);
              this.$emit("refetch-data");
            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },

    getCustomerdata() {
      try {
        customerService
          .getdataBycusCode(this.cusCode)
          .then((response) => {
            this.customerData = response.data.data;

            this.getImgCustomer(this.customerData.cusImg);
            this.$emit("changecusData", this.customerData);
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    updateCusImg(id) {
      if (this.refInputEl.files[0]) {
        let file_size = this.refInputEl.files[0]["size"];
        if (file_size > 1000000) {
          this.$swal({
            type: "error",
            title: "Error",
            text: "ขนาดไฟล์รูปภาพเกินที่กำหนด",
            timer: 3000,
          });
          this.formImg.delete("file");
          return;
        }
      }

      try {
        customerService
          .updateCusImg(id, this.formImg)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "อัพเดทรูปสำเร็จ",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.Chkavatar = false;
            this.customerData.cusImg = response.data.data.cusImg;
            this.getImgCustomer(this.customerData.cusImg);
            //this.$emit("changeCusImg", this.customerData.cusImg);
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    removeCusImg(id) {
      this.$swal({
        title: "ยืนยันการลบ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ใช่, ต้องการลบ",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          try {
            customerService
              .deleteCusImg(id)
              .then((response) => {
                this.customerData.cusImg = response.data.data.cusImg;
                this.getImgCustomer(this.customerData.cusImg);
                this.$emit("changeCusImg", this.customerData.cusImg);
              })
              .catch(() => {});
          } catch (e) {
            // console.log(e);
          }

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "ลบข้อมูลสำเร็จ!",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
      });
    },
    editOpen(id) {
      try {
        customerService
          .getdataBycusCode(id)
          .then((response) => {
            this.form_Data = response.data.data;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
      this.isEditSidebarActive = true;
    },
    deleteData(cusCode) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          try {
            customerService
              .deletedataCustomer(cusCode)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Deleted!",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                this.$router.push("/customer");
              })
              .catch(() => {});
          } catch (e) {
            // console.log(e);
          }
        }
      });
    },
    refetchData() {
      this.getCustomerdata();
    },
    getImgCustomer(item) {
      this.cusImg = process.env.VUE_APP_UPLOAD_IMAGE + item;
    },
    imageUrlAlt(event) {
      event.target.src =
        "https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg";
    },
    showtoken() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      //console.log(decoded);
      this.role = decoded.role;
      if (decoded.role == "superadmin" || decoded.role == "admin") {
        this.cusCode = this.$route.params.id;
      } else {
        this.cusCode = decoded.cusCode;
      }
    },
  },
};
</script>
<style>
/* .demo-spacing{
      right: -21px;
} */
.btn-success {
  border-color: #28c76f !important;
  background-color: #28c76f !important;
  right: -21px;
}
</style>
