<template>
  <b-sidebar
    id="delete-new-sidebar"
    :visible="isDeleteNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-delete-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Credits Value</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(updatecredit)"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="ใส่เครื่องหมาย - หน้าจำนวนเงินที่ต้องการลบ"
            rules="required"
          >
            <b-form-group
              label="ใส่เครื่องหมาย - หน้าจำนวนเงินที่ต้องการลบ"
              label-for="ใส่เครื่องหมาย - หน้าจำนวนเงินที่ต้องการลบ"
            >
              <b-form-input
                id="coin"
                v-model="formData.coin"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
            {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import customerService from "@/services/customerService";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isDeleteNewSidebarActive",
    event: "update:is-delete-new-sidebar-active",
  },
  props: {
    isDeleteNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    cusCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup() {
    const blankformData = {
      coin: "",
    };

    const formData = ref(JSON.parse(JSON.stringify(blankformData)));
    const resetformData = () => {
      formData.value = JSON.parse(JSON.stringify(blankformData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetformData);

    return {
      formData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    updatecredit() {
      try {
        customerService
          .updateCredit(this.cusCode, this.formData)

          .then((response) => {
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "อัพเดทเครดิตไม่สำเร็จ",
                text: "Error! " + response.data.message,
                timer: 3000,
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "อัพเดทเครดิตสำเร็จ!",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit("update:is-delete-new-sidebar-active", false);
              this.$emit("refetch-data");
            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
    async created() {},
  },

  changeQuantity(qty) {
    const val = Math.round(Number(qty));
    let quantity = val;
    if (val <= 0) {
      quantity = 0;
    }
    this.formData.coin = quantity;
  },
};
</script>

<style lang="scss">
#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
