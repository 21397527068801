<template>
  <b-sidebar
    id="add-new-video-sidebar"
    :visible="isAddNewVideoSidebarActive"
    bg-variant="white"
    class="bthum"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-video-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t('Add') }} {{ $t('Videos') }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer ref="refFormObserver">
        <b-media-body>
          <b-form class="p-2">
            <validation-provider
              #default="{ errors }"
              name="Title"
              rules="required"
            >
              <b-form-group :label="$t('Title')" label-for="Title">
                <b-form-input
                  id="Title"
                  v-model="formData.title"
                  autofocus
                  :state="errors.length > 0 ? false : null"
                  trim
                  placeholder=""
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              name="file"
              rules="required"
              ref="provider"
            >
              <b-form-file
                ref="refInputEl"
                type="file"
                accept=".mp4"
                id="input-tag"
                v-model="file1"
                :state="Boolean(file1)"
                @change="onchange"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <p id="size"></p>
            <b-card-text style="font-size: 12px; color: red; font-style: italic"
              >{{ $t('Upload Video Limit') }}</b-card-text
            >

            <video
              width="320"
              height="240"
              v-show="showVideo"
              id="video-tag"
              @loadedmetadata="getVideoDimensions"
              :key="fileInputKey"
              controls
            >
              <source id="video-source" src="splashVideo" type="video/mp4" />
            </video>
            <!-- <canvas></canvas> -->
            <!-- <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-25 mr-75"
                @click="capture()"
              >
                กดเล่น เพื่อแคปภาพวิดีโอ</b-button
              >&nbsp;
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                size="sm"
                class="mb-25 mr-75"
                @click="reset()"
              >
                Reset
              </b-button>
            </div> -->

            <!-- <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-25 mr-75"
              @click="capture()"
            >
              กดเล่น เพื่อแคปภาพวิดีโอ</b-button
            >&nbsp;
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-25 mr-75"
              @click="reset()"
            >
              Reset
            </b-button> -->

            <!-- <div>
              ------------------แสดงภาพขนาดย่อที่บันทึกไว้ด้านล่าง
              -----------------
            </div>
            <div id="video_thumb"></div> -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                @click.prevent="validationForm"
              >
                {{ $t('Save') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                {{ $t('Cancel') }}
              </b-button>
            </div>
          </b-form>

          <!-- </div> -->
          <!-- <div v-else class="text-center mb-3">
                    <br />
                    <span>---- {{ $t('Data not found') }} ----</span>
                  </div> -->
        </b-media-body>
      </validation-observer>

      <!-- Form Actions -->
      <div class="d-flex mt-2"><span id="size"></span> <br /></div>
      <pre id="infos"></pre>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BCard,
  BCardHeader,
  BCardText,
  BLink,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BImg,
  BDropdown,
  BDropdownItem,
  VBModal,
  BFormFile,
  BFormInvalidFeedback,
  BMediaBody,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, min, length } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import customerService from "@/services/customerService";

export default {
  components: {
    BSidebar,
    BCard,
    BCardHeader,
    BCardText,
    BLink,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BImg,
    BDropdown,
    BDropdownItem,
    VBModal,
    BFormFile,
    BFormInvalidFeedback,
    BMediaBody,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  model: {
    prop: "isAddNewVideoSidebarActive",
    event: "update:is-add-new-video-sidebar-active",
  },
  props: {
    isAddNewVideoSidebarActive: {
      type: Boolean,
      required: true,
    },
    addEventListener: {
      type: Boolean,
      required: false,
    },

    cusCode: {},
  },
  watch: {
    isAddNewVideoSidebarActive(old, news) {
      // console.log(news);
      if (news == false) {
        // console.log(document.getElementById("input-tag"));
        this.$refs["refInputEl"].reset();
        this.showVideo = false;
      }
    },
  },
  data() {
    return {
      file1: null,
      showVideo: false,
      fileInputKey: 0,
      required,
      alphaNum,
      scaleFactor: 0.25,
      listThumbnails: [],
    };
  },
  created() {
    console.log(this.cusCode);
  },
  setup() {
    const formThum = new FormData();

    const refInputEl = ref(null);
    // const previewEl = ref(null);
    //const video_thumb = ref(null);
    // const video_path = ref(null);
    const Chkavatar = ref(false);

    const blankformData = {
      title: "",
      level: "",
      video_path: "",
      video_thumb: "",
      Id: "",
    };

    //video_thumb
    //video_path

    const formData = ref(JSON.parse(JSON.stringify(blankformData)));
    const resetformData = () => {
      formData.value = JSON.parse(JSON.stringify(blankformData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetformData);

    return {
      //Sidebar

      refInputEl,
      // previewEl,

      Chkavatar,
      formData,
      refFormObserver,
      getValidationState,
      resetForm,
      formThum,

      //   isEditQuizSidebarActive,
      //   isAnswerQuizSidebarActive,
    };
  },
  methods: {
    close() {
      this.hide;
    },
    async capture2() {
      var video = document.getElementById("video-tag");
      // var canvas = document.getElementById("canvas");

      var w = video.videoWidth * 0.25;
      var h = video.videoHeight * 0.25;

      var canvas = document.createElement("canvas");
      canvas.width = w;
      canvas.height = h;
      var ctx = canvas.getContext("2d");

      ctx.drawImage(video, 0, 0, w, h);

      const fullQuality = canvas.toDataURL();
      await fetch(fullQuality)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "video_thumb.png", {
            type: "image/png",
          });
          console.log(file);
          this.formThum.append("video_thumb", file);
        });

      console.log("CAPTURE");

      // ctx.width = video.videoWidth;
      // ctx.height = video.videoHeight;
      // ctx
      //   .getContext("2d")
      //   .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      // ctx.toBlob() = (blob) => {
      //   const img = new Image();
      //   img.src = window.URL.createObjectUrl(blob);

      //   console.log(img)
      // };
    },

    capture() {
      var video = document.getElementById("video-tag");
      var video_thumb = document.getElementById("video_thumb");
      var canvas = this.createThumbnail(video, this.scaleFactor);
      canvas.onclick = function () {
        window.open(this.toDataURL());
      };
      this.listThumbnails.unshift(canvas);
      video_thumb.innerHTML = "";
      this.listThumbnails.forEach((item, index) => {
        if (item) {
          video_thumb.appendChild(item);
        }
      });
    },

    createThumbnail(video, scaleFactor) {
      if (scaleFactor == null) {
        scaleFactor = 1;
      }
      var w = video.videoWidth * scaleFactor;
      var h = video.videoHeight * scaleFactor;
      var canvas = document.createElement("canvas");
      canvas.style.margin = "5px";
      canvas.width = w;
      canvas.height = h;

      var ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, w, h);
      const fullQuality = canvas.toDataURL();
      //console.log(fullQuality);

      fetch(fullQuality)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "video_thumb.png", {
            type: "image/png",
          });
          console.log(file);
          this.formThum.append("video_thumb", file);
        });
      // this.formThum.append("video_thumb", file);
      return canvas;
      // video_thumb.innerHTML = "";
      // this.listThumbnails = [];
    },

    reset() {
      var video_thumb = document.getElementById("video_thumb");
      video_thumb.innerHTML = "";
      this.listThumbnails = [];
    },
    readVideo(e) {
      const videoSrc = document.querySelector("#video-source");
      const videoTag = document.querySelector("#video-tag");
      const inputTag = document.querySelector("#input-tag");

      if (e.target.files && e.target.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          videoSrc.src = e.target.result;
          videoTag.load();

          const base64String = reader.result
            .replace("data:", "")
            .replace(/^.+,/, "");

          console.log(base64String);
        }.bind(this);

        reader.readAsDataURL(e.target.files[0]);
      }
    },

    getVideoDimensions(e) {
      var vid = document.getElementById("video-tag");
      //console.log(vid);

      if (vid.videoHeight > 500) {
        this.$swal({
          icon: "error",

          text: "ขนาดความสูงของวิดีโอมากเกินไป โปรดเลือกวิดีโอใหม่",
        });

        this.fileInputKey++;

        this.$emit("refetch-data");
      } else if (vid.videoWidth > 1000) {
        // alert("ขนาดความกว้างของวิดีโอมากเกินไป โปรดเลือกวิดีโอใหม่");
        this.$swal({
          icon: "error",

          text: "ขนาดความกว้างของวิดีโอมากเกินไป โปรดเลือกวิดีโอใหม่",
        });

        this.fileInputKey++;
      } else {
        // document.getElementById("video-tag").innerHTML =
        //   "<b>" + videoHeight + videoWidth + "</b> ";
      }
      if (vid.duration > 120.0) {
        // alert("เวลาของวิดีโอสูงเกินไป โปรดเลือกวิดีโอใหม่");
        this.$swal(
          "warning",
          "เวลาของวิดีโอสูงเกินไป โปรดเลือกวิดีโอใหม่",
          "close"
        );
        this.fileInputKey++;
      }
    },

    async onchange(e) {
      // const { valid } = await this.$refs.provider.validate(e);
      // await this.$refs.provider.validate(e);

      // if (valid) {
      //   const file = this.$refs.file.files[0];
      //   this.fileData = file;
      // }
      // console.log(valid);

      const file = document.getElementById("input-tag");

      //const file = this.$refs.file.files[0];

      // if (!file) {
      //   event.preventDefault();
      //   alert("No file chosen");
      //   return;
      // }

      if (file.size > 20480 * 20480) {
        event.preventDefault();
        alert("File too big (> 20MB)");
        this.fileInputKey++;

        return;
      }

      // alert("File OK");

      const videoSrc = document.querySelector("#video-source");
      const videoTag = document.querySelector("#video-tag");
      const inputTag = document.querySelector("#input-tag");

      let _self = this;

      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();

        reader.onload = function (event) {
          videoSrc.src = event.target.result;
          videoTag.load();
          _self.showVideo = true;

          // const base64String = reader.result
          //   .replace("data:", "")
          //   .replace(/^.+,/, "");

          // console.log(base64String);
        }.bind(this);

        reader.readAsDataURL(event.target.files[0]);

        this.formThum.append("video_path", event.target.files[0]);

        console.log(event.target.files[0]);
      }
    },

    validationForm() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          await this.capture2();

          this.formThum.append("title", this.formData.title);
          this.formThum.append("level", this.formData.level);
          this.formThum.append("cusCode", this.cusCode);
          // this.capture();

          document.getElementById("input-tag").value = "";

          console.log("reset");

          try {
            customerService
              .updateThumImg(this.formThum)
              .then((response) => {
                if (response.data.status == "error") {
                  this.$swal({
                    icon: "error",
                    title: "บันทึกข้อมูลไม่สำเร็จ",
                    text: "Error! " + response.data.message,
                    timer: 3000,
                  });
                } else {
                  this.formThum = new FormData();
                  this.$swal({
                    icon: "success",
                    title: "บันทึกข้อมูลสำเร็จ",
                    text: "Save data successfully!",
                    timer: 3000,
                  });
                  this.$emit("update:is-add-new-video-sidebar-active", false);
                  this.$emit("refetch-data");

                  var video_thumb = document.getElementById("video_thumb");
                  video_thumb.innerHTML = "";
                  this.listThumbnails = [];
                  this.$refs.refInputEl.file = null;
                  this.fileInputKey++;
                }

                //this.$emit("changeCusImg", this.customerData.cusImg);
              })
              .catch(() => {});
          } catch (e) {}
        }
      });

      return;
      // await this.capture2();

      // this.formThum.append("title", this.formData.title);
      // this.formThum.append("level", this.formData.level);
      // this.formThum.append("cusCode", this.cusCode);
      // // this.capture();

      // document.getElementById("input-tag").value = "";

      // try {
      //   customerService
      //     .updateThumImg(this.formThum)
      //     .then((response) => {
      //       if (response.data.status == "error") {
      //         this.$swal({
      //           type: "error",
      //           title: "บันทึกข้อมูลไม่สำเร็จ",
      //           text: "Error! " + response.data.message,
      //           timer: 3000,
      //         });
      //       } else {
      //         this.formThum = new FormData();
      //         this.$swal({
      //           type: "success",
      //           title: "บันทึกข้อมูลสำเร็จ",
      //           text: "Save data successfully!",
      //           timer: 3000,
      //         });
      //         this.$emit("update:is-add-new-video-sidebar-active", false);
      //         this.$emit("refetch-data");

      //         var video_thumb = document.getElementById("video_thumb");
      //         video_thumb.innerHTML = "";
      //         this.listThumbnails = [];
      //         this.$refs.refInputEl.file = null;
      //         this.fileInputKey++;
      //       }

      //       //this.$emit("changeCusImg", this.customerData.cusImg);
      //     })
      //     .catch(() => {});
      // } catch (e) {}
    },
  },
};
</script>

<style lang="scss">
#add-new-video-sidebar {
  width: auto !important;
  max-width: 1000px !important;
}
</style>
