import http from "./http";

export default {
    getalldata(filterAll) {
        return http
            .get("department" + filterAll)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    // getdataById(id) {
    //     return http
    //         .get("department/" + id)
    //         .then(function (response) {
    //             return Promise.resolve(response);
    //         })
    //         .catch(function (error) {
    //             return Promise.reject(error);
    //         })
    // },
    getDataBycusCode(id) {
        return http
            .get("/department/customer/"+id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    insertData(form) {
        return http
            .post("/dept-kiosk/update", form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    getdataById(id,chk_type) {
        return http
            .get("/dept-kiosk/dep/"+id+"?checkin_type="+chk_type )
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    resetDefDep(id,chk_type) {
        return http
            .put("/update-def-kiosk/dep/"+id, {"checkin_type" : chk_type} )
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            })
    },
    updateData(id, form) {
        return http
            .put("department/" + id, form)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
    deleteData(id) {
        return http
            .delete("department/" + id)
            .then(function (response) {
                return Promise.resolve(response);
            })
            .catch(function (error) {
                return Promise.reject(error);
            });
    },
}