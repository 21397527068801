<template>
  <div>
    <videos-add-new
      :is-add-new-video-sidebar-active.sync="isAddNewVideoSidebarActive"
      :cusCode="cusCode"
      @refetch-data="refetchData"
    />

    <b-card>
      <b-row>
        <b-col md="3" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Sort") }}</label>
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="6" class="my-1 d-none d-md-block"></b-col>

        <b-col md="6" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Filter") }}</label>
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('Search')"
                @keyup="searchEnter()"
                @keydown="searchEnter()"
                @change="searchEnter()"
              />
              <b-input-group-append>
                <b-button
                  style="border-radius: 0px 5px 5px 0px"
                  :disabled="!filter"
                  @click="searchClear()"
                >
                  {{ $t("Clear") }}
                </b-button>

                <b-button
                  style="margin-left: 5px; border-radius: 0.357rem"
                  variant="primary"
                  @click="isAddNewVideoSidebarActive = true"
                >
                  {{ $t('Add') }} {{ $t('Videos') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            striped
            responsive
            :per-page="perPage"
            :current-page="currentPagetable"
            :items="bigdata"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            @sort-changed="sortingChanged"
          >
            <template #cell(show_details)="row">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
                <span class="vs-label">{{
                  row.detailsShowing ? "Hide" : "Show"
                }}</span>
              </b-form-checkbox>
            </template>

            <template #row-details="row">
              <b-card>
                <b-row class="mb-2">
                  <b-col md="3" class="mb-1">
                    <strong>{{ $t('No') }} : </strong>

                    {{ row.item.id }}
                  </b-col>
                  <b-col md="4" class="mb-1">
                    <div v-if="row.item.video_thumb">
                      <b-img
                        fluid
                        :src="getImgUrl(row.item.video_thumb)"
                        style="width: 80px"
                      />
                    </div>
                  </b-col>
                  <!-- <b-col md="3" class="mb-1">
                    <strong>ตัวเลือกที่1 : </strong>{{ row.item.choice1 }}
                  </b-col> -->
                </b-row>
              </b-card>
            </template>

            <template #cell(id)="row">
              {{ row.item.id }}
            </template>

            <template #cell(video_thumb)="row">
              <div
                v-if="row.item.video_thumb"
                class="log-emp-photo d-inline-block"
              >
                <b-img
                  fluid
                  :src="getImgUrl(row.item.video_thumb)"
                  style="width: 50px"
                />
              </div>
              <div v-else>
                <span>-</span>
              </div>

              <div class="d-inline-block">
                <feather-icon
                  icon="PlayCircleIcon"
                  size="24"
                  class="ml-50"
                  style="cursor: pointer"
                  @click="playVidPre(row.item.video_path)"
                  v-b-modal.modal-lg
                />
              </div>
            </template>

            <template #cell(title)="row">
              <div v-if="row.item.title == ''"></div>
              <div v-else>{{ row.item.title }}</div>
            </template>
            <template #cell(level)="row">
              <div v-if="row.item.level == ''"></div>
              <div v-else>{{ row.item.level }}</div>
            </template>

            <template #cell(actions)="row">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="deleteData(row.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t("Delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>

        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Per page") }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @change="perPageChanged()"
              class="w-50"
            />

            <div class="show-entries" v-if="totalRows == 0">
              <label class="text-muted">{{ $t("Showing") }} 0 {{ $t("To") }}  0 {{ $t("Of") }}  0 {{ $t("Entries") }} </label>
            </div>
            <div class="show-entries" v-else>
              <div v-if="currentPage * perPage > totalRows">
                <label class="text-muted"
                  >{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                  {{ currentPage * perPage }} {{ $t("Of") }} {{ totalRows }} {{ $t("Entries") }}</label
                >
              </div>
              <div v-else>
                <label class="text-muted"
                  >{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                  {{ currentPage * perPage }} {{ $t("Of") }} {{ totalRows }} {{ $t("Entries") }}</label
                >
              </div>
            </div>
          </b-form-group>
        </b-col>

        <b-col md="10" sm="8">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @click.native="handlePageChange()"
            align="right"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal id="modal-lg" hide-footer centered size="lg" title="Video Preview">
      <div class="video-preview">
        <video
          :src="vidPre"
          controls
          preload="metadata"
          style="width: 100%"
        ></video>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BCardHeader,
  BCardText,
  BLink,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BImg,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BFormFile,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import jwt_decode from "jwt-decode";
import VideosAddNew from "./VideosAddNew.vue";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import customerService from "@/services/customerService";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    BForm,
    BFormFile,
    BCard,
    BCardHeader,
    BCardText,
    BLink,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormInvalidFeedback,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BImg,
    BDropdown,
    BDropdownItem,
    BModal,
    VBModal,
    // QuizsEdit,
    VideosAddNew,
    ValidationProvider,
    ValidationObserver,
    // QuizsAnswer,
    //   CustomerAddNew,
    //   CustomerEdit,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  setup() {
    const isAddNewVideoSidebarActive = ref(false);
    // const isEditQuizSidebarActive = ref(false);
    // const isAnswerQuizSidebarActive = ref(false);
    const refInputEl = ref(null);
    const previewEl = ref(null);
    const video_thumb = ref(null);
    const video_path = ref(null);
    const Chkavatar = ref(false);

    //video_thumb
    //video_path

    const blankformData = {
      cusCode: "",
      title: "",
      level: "",
      video_path: "",
      video_thumb: "",
    };
    const formData = ref(JSON.parse(JSON.stringify(blankformData)));
    const resetformData = () => {
      formData.value = JSON.parse(JSON.stringify(blankformData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetformData);
    return {
      //Sidebar

      refInputEl,
      previewEl,

      Chkavatar,
      formData,
      refFormObserver,
      getValidationState,
      resetForm,
      isAddNewVideoSidebarActive,
      //   isEditQuizSidebarActive,
      //   isAnswerQuizSidebarActive,
    };
  },
  data() {
    return {
      scaleFactor: 0.25,
      listThumbnails: [],
      perPage: 30,
      pageOptions: [20, 30, 50, 100, 150],
      totalRows: 1,
      currentPage: 1,
      currentPagetable: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // infoModal: {
      //   id: "info-modal",
      //   title: "",
      //   content: "",
      // },
      fields: [
        // {
        //   key: "show_details",
        //   label: "Details",
        //   class: "d-sm-none",
        // },
        {
          key: "id",
          label: this.$t('No'),
          sortable: true,
          class: "text-left",
        },
        {
          key: "video_thumb",
          label: this.$t('Picture'),
          class: "text-center",
        },

        {
          key: "title",
          label: this.$t('Title'),
          sortable: true,
          class: "text-center",
        },
        {
          key: "level",
          label: this.$t('Level'),
          sortable: true,
          class: "text-center",
        },
        {
          key: "actions",
          label: this.$t('Actions'),
          class: "text-center",
        },
      ],
      filterAll: "",
      filterSearch: "",
      filterDate: "",
      filterperPage: "",
      filtersortBy: "",
      filtersortDesc: "",
      bigdata: [],
      //   form_Data: {},
      form_Data: {},
      cusCode: "",
      vidPre: "",
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {},
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updateDate() {
      var start = dateFormat(this.dateRange.startDate, "yyyy-mm-dd");
      var end = dateFormat(this.dateRange.endDate, "yyyy-mm-dd");
      if (this.dateRange.startDate && this.dateRange.endDate) {
        this.filterDate = "&startDate=" + start + "&endDate=" + end;
        this.getbigdata();
      }
    },
    searchEnter() {
      if (this.filter) {
        this.filterSearch = "&search=" + this.filter;
      } else {
        this.filterSearch = "";
      }
      this.getbigdata();
    },
    searchClear() {
      this.filter = "";
      this.filterSearch = "";
      this.getbigdata();
    },
    perPageChanged() {
      this.filterperPage = "&per_page=" + this.perPage;
      this.getbigdata();
    },
    sortingChanged(e) {
      if (e.sortBy) {
        this.filtersortBy = "&sortBy=" + e.sortBy;
      }

      if (e.sortDesc) {
        this.filtersortDesc = "&sortDesc=DESC";
      } else {
        this.filtersortDesc = "&sortDesc=ASC";
      }
      this.getbigdata();
    },
    handlePageChange() {
      this.getbigdata();
    },
    getbigdata() {
      try {
        customerService
          .getimgBycusCode(this.cusCode)
          .then((response) => {
            this.bigdata = response.data.data;
            this.totalRows = response.data.data.length;
            console.log(this.bigdata);
            console.log(response);
          })
          .catch(() => {});

        // this.data1 = response.data;
        // console.log(this.User_data);
      } catch (e) {
        console.log(e);
      }
    },
    getallcustomer() {
      try {
        customerService
          .getimgBycusCode(this.cusCode)
          .then((response) => {
            this.allcustomer = response.data.data.data;

            //console.log(response);
          })
          .catch(() => {});

        // this.data1 = response.data;
        // console.log(this.User_data);
      } catch (e) {
        // console.log(e);
      }
    },

    getImgUrl(pet) {
      var images = process.env.VUE_APP_UPLOAD_IMAGE + "video/"; //"http://localhost/sci21/uploads/";
      if (!pet) {
        pet = "no-image.png";
      }
      return images + pet;
    },
    playVidPre(vidPath) {
      this.vidPre = process.env.VUE_APP_UPLOAD_IMAGE + "video/" + vidPath;
    },
    showtoken() {
      var token = localStorage.getItem("token");
      var decoded = jwt_decode(token);
      //console.log(decoded);
      // console.log(decoded.role);
      if (decoded.role == "superadmin" || decoded.role == "admin") {
        this.cusCode = this.$route.params.id;
        this.role = true;
      } else {
        this.cusCode = decoded.cusCode;
        this.role = false;
      }
    },

    deleteData(cusCode) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          try {
            customerService
              .deletedataThumb(cusCode)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Deleted!",
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                this.getbigdata();
              })
              .catch(() => {});
          } catch (e) {
            // console.log(e);
          }
        }
      });
    },
    refetchData() {
      this.getbigdata();
    },
  },

  async created() {
    await this.showtoken();
    await this.getbigdata();
  },
};
</script>

<style>
.modal .modal-content {
  overflow: visible;
  width: auto;

  position: relative;
}
#video_thumb {
  display: inline-block;
  top: 4px;
  position: relative;
  border: dotted 1px #ccc;
  padding: 2px;
  width: 100%;
}

.log-emp-photo {
  transition: all 0.5s ease-in-out;
}
.log-emp-photo:hover {
  transform: translateY(-4px) scale(6);
  z-index: 10;
}
</style>
