<template>
  <b-sidebar
    id="add-edit-sidebar"
    :visible="isEditSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('Edit') }} {{ $t('Branch') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(updatedata)"
          @reset.prevent="resetForm"
        >

          <validation-provider
            #default="validationContext"
            :name="$t('Name')"
            rules="required"
          >
            <b-form-group :label="$t('Name')" :label-for="$t('Name')">
              <b-form-input
                id="name"
                v-model="formData.b_name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("Update") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
            {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BFormInvalidFeedback, BButton
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import branchService from "@/services/branchService.js";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditSidebarActive',
    event: 'update:is-edit-sidebar-active',
  },
  props: {
    isEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    formData: {
    },
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,

    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
    }
  },
  computed: {
  },
  methods: {
    updatedata() {
      try {
        branchService
          .updateData(this.formData.b_code, this.formData)
         
          .then((response) => {
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "อัพเดทข้อมูลไม่สำเร็จ",
                text: "Error! "+ response.data.message,
                timer: 3000,
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "อัพเดทข้อมูลสำเร็จ!",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit('refetch-data')
              this.$emit('update:is-edit-sidebar-active', false)
            }
          })
          .catch(() => {});
      } catch (e) {
        //console.log(e);
      }
     
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-edit-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
