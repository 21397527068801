<template>
  <div>
    <quizs-add-new
      :is-add-new-quiz-sidebar-active.sync="isAddNewQuizSidebarActive"
      @refetch-data="refetchData"
    />
    <quizs-edit
      :is-edit-quiz-sidebar-active.sync="isEditQuizSidebarActive"
      :form_Data="form_Data"
      @refetch-data="refetchData"
    />
    <quizs-answer
      :is-answer-quiz-sidebar-active.sync="isAnswerQuizSidebarActive"
      :form_Data="form_Data"
      @refetch-data="refetchData"
    />
    <b-card>
      <b-row>
        <b-col md="3" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Sort") }}</label>
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="6" class="my-1 d-none d-md-block"></b-col>

        <b-col md="6" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Filter") }}</label>
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="$t('Search')"
                @keyup="searchEnter()"
                @keydown="searchEnter()"
                @change="searchEnter()"
              />
              <b-input-group-append>
                <b-button
                  style="border-radius: 0px 5px 5px 0px"
                  :disabled="!filter"
                  @click="searchClear()"
                >
                  {{ $t("Clear") }}
                </b-button>

                <b-button
                  style="margin-left: 5px; border-radius: 0.357rem"
                  variant="primary"
                  @click="isAddNewQuizSidebarActive = true"
                >
                  <span class="text-nowrap">Add question</span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-table
            striped
            responsive
            :per-page="perPage"
            :current-page="currentPagetable"
            :items="bigdata"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            @sort-changed="sortingChanged"
          >
            <template #cell(show_details)="row" style="width: 50px">
              <b-form-checkbox
                v-model="row.detailsShowing"
                plain
                class="vs-checkbox-con"
                @change="row.toggleDetails"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <i class="vs-icon feather icon-check" />
                  </span>
                </span>
                <span class="vs-label">{{
                  row.detailsShowing ? "Hide" : "Show"
                }}</span>
              </b-form-checkbox>
            </template>

            <template #row-details="row">
              <b-card>
                <b-row class="mb-2">
                  <b-col md="3" class="mb-1">
                    <strong>{{ $t('No') }} : </strong>

                    {{ row.item.id }}
                  </b-col>
                  <b-col md="3" class="mb-1">
                    <strong>{{ $t('Question') }} : </strong>{{ row.item.question }}
                  </b-col>
                  <b-col md="3" class="mb-1">
                    <strong>ตัวเลือกที่1 : </strong>{{ row.item.choice1 }}
                  </b-col>
                </b-row>
              </b-card>
            </template>

            <template #cell(id)="row">
              {{ row.item.id }}
            </template>

            <template #cell(choice1)="row">
              <div v-if="row.item.choice1 == ''"></div>
              <div v-if="row.item.choice1">{{ row.item.choice1 }}</div>
            </template>

            <template #cell(choice2)="row">
              <div v-if="row.item.choice2 == ''"></div>
              <div v-else>{{ row.item.choice2 }}</div>
            </template>
            <template #cell(choice3)="row">
              <div v-if="row.item.choice3 == ''"></div>
              <div v-else>{{ row.item.choice3 }}</div>
            </template>
            <template #cell(choice4)="row">
              <div v-if="row.item.choice4 == ''"></div>
              <div v-else>{{ row.item.choice4 }}</div>
            </template>

            <template #cell(actions)="row">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="editOpen2(row.item.id)">
                  <feather-icon icon="CheckIcon" />
                  <span class="align-middle ml-50">Answer</span>
                </b-dropdown-item>

                <b-dropdown-item @click="editOpen(row.item.id)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">{{ $t("Edit") }}</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteData(row.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">{{ $t("Delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>

        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ $t("Per page") }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              @change="perPageChanged()"
              class="w-50"
            />

            <div class="show-entries" v-if="totalRows == 0">
              <label class="text-muted">{{ $t("Showing") }} 0 {{ $t("To") }}  0 {{ $t("Of") }}  0 {{ $t("Entries") }} </label>
            </div>
            <div class="show-entries" v-else>
              <div v-if="currentPage * perPage > totalRows">
                <label class="text-muted"
                  >{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                  {{ currentPage * perPage }} {{ $t("Of") }} {{ totalRows }} {{ $t("Entries") }}</label
                >
              </div>
              <div v-else>
                <label class="text-muted"
                  >{{ $t("Showing") }} {{ currentPage * perPage + 1 - perPage }} {{ $t("To") }}
                  {{ currentPage * perPage }} {{ $t("Of") }} {{ totalRows }} {{ $t("Entries") }}</label
                >
              </div>
            </div>
          </b-form-group>
        </b-col>

        <b-col md="10" sm="8">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            @click.native="handlePageChange()"
            align="right"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BCardHeader,
  BCardText,
  BLink,
  BTable,
  BFormCheckbox,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BImg,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import jwt_decode from "jwt-decode";

import QuizsEdit from "./QuizsEdit.vue";
import QuizsAddNew from "./QuizsAddNew.vue";
import QuizsAnswer from "./QuizsAnswer.vue";
import { ref } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import quizService from "@/services/quizService";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardText,
    BLink,
    BTable,
    BFormCheckbox,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BImg,
    BDropdown,
    BDropdownItem,
    QuizsEdit,
    QuizsAddNew,
    QuizsAnswer,
    //   CustomerAddNew,
    //   CustomerEdit,
  },
  directives: {
    Ripple,
  },
  setup() {
    const isAddNewQuizSidebarActive = ref(false);
    const isEditQuizSidebarActive = ref(false);
    const isAnswerQuizSidebarActive = ref(false);

    return {
      // Sidebar
      isAddNewQuizSidebarActive,
      isEditQuizSidebarActive,
      isAnswerQuizSidebarActive,
    };
  },
  data() {
    return {
      perPage: 30,
      pageOptions: [20, 30, 50, 100, 150],
      totalRows: 1,
      currentPage: 1,
      currentPagetable: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // infoModal: {
      //   id: "info-modal",
      //   title: "",
      //   content: "",
      // },
      fields: [
        // {
        //   key: "show_details",
        //   label: "Details",
        //   class: "d-sm-none",
        // },
        {
          key: "id",
          label:  this.$t('No'),
          sortable: true,
          class: "text-left",
        },
        {
          key: "question",
          label: this.$t('Question'),
          class: "text-left",
        },
        {
          key: "choice1",
          label: this.$t('Choice No') +" 1",
          class: "text-left",
        },
        {
          key: "choice2",
          label: this.$t('Choice No') +" 2",
          class: "text-left",
        },
        {
          key: "choice3",
          label: this.$t('Choice No') +" 3",
          class: "text-left",
        },
        {
          key: "choice4",
          label: this.$t('Choice No') +" 4",
          class: "text-center",
        },
        {
          key: "score",
          label: this.$t('Score'),
          sortable: true,
          class: "text-center",
        },
        {
          key: "type",
          label: this.$t('Question Type'),
          sortable: true,
          class: "text-center",
        },
        {
          key: "actions",
          label: this.$t('Actions'),
          class: "text-center",
        },
      ],
      filterAll: "",
      filterSearch: "",
      filterDate: "",
      filterperPage: "",
      filtersortBy: "",
      filtersortDesc: "",
      bigdata: [],
      //   form_Data: {},
      form_Data: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {},
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    updateDate() {
      var start = dateFormat(this.dateRange.startDate, "yyyy-mm-dd");
      var end = dateFormat(this.dateRange.endDate, "yyyy-mm-dd");
      if (this.dateRange.startDate && this.dateRange.endDate) {
        this.filterDate = "&startDate=" + start + "&endDate=" + end;
        this.getbigdata();
      }
    },
    searchEnter() {
      if (this.filter) {
        this.filterSearch = "&search=" + this.filter;
      } else {
        this.filterSearch = "";
      }
      this.getbigdata();
    },
    searchClear() {
      this.filter = "";
      this.filterSearch = "";
      this.getbigdata();
    },
    perPageChanged() {
      this.filterperPage = "&per_page=" + this.perPage;
      this.getbigdata();
    },
    sortingChanged(e) {
      if (e.sortBy) {
        this.filtersortBy = "&sortBy=" + e.sortBy;
      }

      if (e.sortDesc) {
        this.filtersortDesc = "&sortDesc=DESC";
      } else {
        this.filtersortDesc = "&sortDesc=ASC";
      }
      this.getbigdata();
    },
    handlePageChange() {
      this.getbigdata();
    },
    getbigdata() {
      var page = "page=" + this.currentPage;
      this.filterAll =
        "?" +
        page +
        this.filterSearch +
        this.filterperPage +
        this.filtersortBy +
        this.filtersortDesc;

      try {
        quizService
          .getalldata(this.filterAll)
          .then((response) => {
            this.bigdata = response.data.data.data;
            this.totalRows = response.data.data.total;
            // console.log(response);
          })
          .catch(() => {});

        // this.data1 = response.data;
        // console.log(this.User_data);
      } catch (e) {
        // console.log(e);
      }
    },
    editOpen(id) {
      try {
        quizService
          .getdataBycusCode(id)
          .then((response) => {
            this.form_Data = response.data.data;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
      this.isEditQuizSidebarActive = true;
    },

    editOpen2(id) {
      try {
        quizService
          .getdataBycusCode(id)
          .then((response) => {
            this.form_Data = response.data.data;
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
      this.isAnswerQuizSidebarActive = true;
    },
    deleteData(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          try {
            quizService
              .deletedataQuiz(id)
              .then((response) => {
                console.log(response);
                this.getbigdata();
              })
              .catch(() => {});
          } catch (e) {
            // console.log(e);
          }

          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    refetchData() {
      console.log("555555");
      this.getbigdata();
    },
  },
  async created() {
    await this.getbigdata();
  },
};
</script>

<style></style>
