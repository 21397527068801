<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("Add") }} {{ $t("Employeer") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(insertdata)"
          @reset.prevent="resetForm"
        >
          <!-- <validation-provider
            #default="validationContext"
            name="Code UUID"
            rules="required"
          >
            <b-form-group label="Code UUID" label-for="Code UUID">
              <b-form-input
                id="empCode"
                v-model="formData.empCode"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <validation-provider #default="validationContext" 
            :name="$t('Tel')"
            :rules="require_tel"
          >
            <b-form-group :label="$t('Tel')" 
            :label-for="$t('Tel')">
              <b-form-input
                id="tel"
                v-model="formData.tel"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                maxlength="10"
                @change="telChange"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="$t('Firstname')"
            rules="required"
          >
            <b-form-group :label="$t('Firstname')" :label-for="$t('Firstname')">
              <b-form-input
                id="firstname"
                v-model="formData.firstname"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="$t('Lastname')"
            rules="required"
          >
            <b-form-group :label="$t('Lastname')" :label-for="$t('Lastname')">
              <b-form-input
                id="lastname"
                v-model="formData.lastname"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="$t('Firstname') +'Eng.'"
          >
            <b-form-group :label="$t('Firstname') +' Eng.'" :label-for="$t('Firstname') +' Eng.'">
              <b-form-input
                id="firstname_eng"
                v-model="formData.firstname_eng"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="$t('Lastname') + ' Eng.'"
          >
            <b-form-group :label="$t('Lastname')  + ' Eng.'" :label-for="$t('Lastname')  + ' Eng.'">
              <b-form-input
                id="lastname_eng"
                v-model="formData.lastname_eng"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-radio-group
            v-model="formData.gender"
            :options="genders"
            class="mb-1"
            name="gender"
          >
          </b-form-radio-group>

          <validation-provider
            #default="validationContext"
            :name="$t('Birthday')"
          >
            <b-form-group
              :label="$t('Birthday')"
              :label-for="$t('Birthday')"
            >
              <b-form-datepicker
                id="birthday"
                v-model="formData.birthday"
                class="form-control mb-1"
                :state="getValidationState(validationContext)"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="$t('License ID')"
            rules="min:8"
          >
            <b-form-group :label="$t('License ID')" :label-for="$t('License ID')">
              <b-form-input
                id="license_id"
                v-model="formData.license_id"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                maxlength="15"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
            :label="$t('License Expire')"
            :label-for="$t('License Expire')"
          >
            <b-form-datepicker
              id="license_expire"
              v-model="formData.license_expire"
              class="form-control mb-1"
            />
          </b-form-group>

          <b-form-group
            v-if="user_role != 'manager'"
            :label="$t('Branch')"
            :label-for="$t('Branch')"
          >
            <v-select
              v-model="formData.b_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="branchSel"
              :reduce="(option) => option.id"
              label="b_name"
              input-id="id"
            />
          </b-form-group>

          <validation-provider #default="validationContext" 
            :name="$t('ID CODE')"
            :rules="require_empId"
          >
            <b-form-group :label="$t('ID CODE')" :label-for="$t('ID CODE')">
              <b-form-input
                id="empId"
                v-model="formData.emp_id"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                maxlength="8"
                @change="empIdChange"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- <validation-provider #default="validationContext" name="RFID">
            <b-form-group label="RFID" label-for="RFID">
              <b-form-input
                id="empRfid"
                v-model="formData.empRfid"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <validation-provider
            #default="validationContext"
            :name="$t('Department')"
          >
            <b-form-group
              :label="$t('Department')"
              :label-for="$t('Department')"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="formData.dep_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="DepOptions"
                :reduce="(option) => option.id"
                label="name"
                input-id="id"
                @input="depChange"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            :name="$t('Position')"
          >
            <b-form-group :label="$t('Position')" :label-for="$t('Position')">
              <b-form-input
                id="position"
                v-model="formData.position"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{$t('Save')}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{$t('Cancel')}}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormDatepicker,
  BFormInvalidFeedback,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import memberService from "@/services/memberService.js";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormDatepicker,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewSidebarActive",
    event: "update:is-add-new-sidebar-active",
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    cusCode: {},
    branchSel: {
      type: Array,
      required: true,
    },
    DepOptions: {
      type: Array,
      required: true,
    },
    user_role: {
      type: String,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      genders: [
        { text: this.$t('Male'), value: 'male' },
        { text: this.$t('Female'), value: 'female' },
      ],
      require_tel: 'integer|min:10',
      require_empId: 'min:5'
    };
  },
  setup() {
    const blankformData = {
      empCode: "",
      empTitle: "",
      firstname: "",
      lastname: "",
      firstname_eng: "",
      lastname_eng: "",
      emp_id: '',
      gender: 'male',
      birthday: '',
      license_id: "",
      license_expire: "",
      tel: "",
      empRfid: "",
      userStatus: "",
      customers_cusId: "",
      dep_id: "",
      dep_name: "",
      position: "",
    };

    const formData = ref(JSON.parse(JSON.stringify(blankformData)));
    const resetformData = () => {
      formData.value = JSON.parse(JSON.stringify(blankformData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetformData);

    return {
      formData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  methods: {
    telChange() {
      if (this.formData.tel != '') {
        this.require_empId = 'min:5'
      }
    },
    empIdChange() {
      if (this.formData.emp_id != '') {
        this.require_tel = 'integer|min:10'
      }
    },
    depChange(value) {
      let dep_data = this.DepOptions.filter((x) => x.id === value);
      dep_data = dep_data[0];
      this.formData.dep_name = dep_data.name;
    },
    insertdata() {
      if (this.formData.tel == '' && this.formData.emp_id == '') {
        this.require_tel = 'required|integer|min:10'
        this.require_empId = 'required|min:5'
        this.$swal({
          type: "error",
          title: "ข้อผิดพลาด!!!",
          text: "กรุณากรอกเบอร์โทร หรือรหัสพนักงาน อย่างใดอย่างหนึ่ง หรือทั้งหมด"
        });
        return
      }

      try {
        memberService
          .insertWithCusId(this.cusCode, this.formData)
          .then((response) => {
            if (response.data.status == "error") {
              this.$swal({
                type: "error",
                title: "บันทึกข้อมูลไม่สำเร็จ",
                text: "Error! " + response.data.message,
                timer: 3000,
              });
            } else {
              this.$swal({
                type: "success",
                title: "บันทึกข้อมูลสำเร็จ",
                text: "Save data successfully!",
                timer: 3000,
              });
              this.$emit("update:is-add-new-sidebar-active", false);
              this.$emit("refetch-data");
            }
          })
          .catch(() => {});
      } catch (e) {
        // console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
